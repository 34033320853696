import React, {Component} from 'react';
import Scanner from './Scanner';

export default class scanProduct extends Component {


    constructor(props) {
        super(props);
        this.state = {
            scanning: true,
            results: []

        };
    }

    render() {
        return (
            <div>
                {this.state.scanning ? <Scanner onDetected={this._onDetected}/> : null}
            </div>
        );
    }

    _scan() {
        this.setState({scanning: !this.state.scanning});
    }

    _onDetected(result) {

        this.setState({results: this.state.results.concat([result])});
    }
}

