import React, {useEffect, useState} from "react";
// import {createStyles, makeStyles} from '@material-ui/core/styles';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import AuthService from "../services/auth.service";
import {Redirect} from "react-router-dom";
// import {Table} from "react-bootstrap";
import ArntzDatabase from "../services/database";
import UserService from "../services/user.service";
// import Typography from "@material-ui/core/Typography";
import {CircularProgress} from '@material-ui/core';
// import stockIcon from "../assets/images/stock-icon.svg";
import {useTranslation} from "react-i18next";

// const useStyles = makeStyles((theme) =>
//     createStyles({
//         root: {
//             minWidth: 275,
//             display: 'flex',
//             flexDirection: 'column',
//         },
//         title: {
//             fontSize: 14,
//         },
//         pos: {
//             marginBottom: 12,
//         },
//     }),
// );

export default function Product() {
    // const classes = useStyles();
    const { t, i18n } = useTranslation();

    const currentUser = AuthService.getCurrentUser();
    const obj = (currentUser) ? {currentUser: currentUser, userReady: true} : {redirect: "/login"};
    const [state] = useState(obj);
    const [data, setData] = useState([])
    const [mode, setMode] = useState('online');
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState('');

    useEffect(() => {
        (async () => {
            if (window.navigator.onLine) {
                await ArntzDatabase.db.sync('bookings');
                ArntzDatabase.isSync = true;
                let filters = {};
                // UserService.getAll('bookings', filters).then((res) => {
                //     // setLoading(false);
                //     // setData(res.data);
                //     // if (res.data.length <= 0)
                //     //     setMsg('Keine Resultate gefunden');
                //     // else {
                //     //     setMsg('');
                //         ArntzDatabase.db.resources['bookings'].clear().then(() =>
                //             ArntzDatabase.db.resources['bookings'].bulkAdd(res.data).then().catch()
                //         );
                //     // }
                //
                // }).catch((err) => {
                //     ArntzDatabase.isSync = false;
                //     getOffline();
                // });
                await ArntzDatabase.db.sync('stocks');
                await UserService.getAll('stocks', {storage: currentUser.storage}).then((res) => {
                    setLoading(false);
                    setData(res.data);
                    if (res.data.length <= 0)
                        setMsg('Keine Resultate gefunden');
                    else {
                        setMsg('');
                        ArntzDatabase.db.resources['products'].clear().then(() => ArntzDatabase.db.resources['products'].bulkAdd(res.data).then().catch()).catch();
                    }
                }).catch((e) => {
                    setMode('offline');
                    ArntzDatabase.isSync = false;
                });
            } else {
                ArntzDatabase.isSync = false;
                getOffline();
            }
        })();

    }, []);

    const getOffline = () => {
        (async () => {
            setMode('offline');
            let allProds = await ArntzDatabase.db.resources['bookings'].toArray();
            if (allProds.length <= 0)
                setMsg('Keine Resultate gefunden');
            else {
                setData(allProds);
            }
            setLoading(false);
        })();
    }

    return (
        (state.redirect) ?
            <Redirect to={state.redirect}/>
            :
            <div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {/*<div className="card card-container p-1">*/}
                                <h2>{t('misc.product')}</h2>
                                {loading ?
                                    <CircularProgress
                                        size={50}
                                        thickness={3}
                                        color="primary"
                                    />
                                    :
                                    <span>
                                        {mode === 'offline' &&
                                        <div className="alert alert-warning" role="alert">
                                            {mode}
                                        </div>
                                        }

                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th scope="col">{t('product.get.ean')}</th>
                                                <th scope="col">{t('product.get.number')}</th>
                                                <th scope="col">{t('product.get.description')}</th>
                                                <th scope="col">{t('misc.stock')}</th>
                                                <th scope="col">{t('misc.min')}</th>
                                                <th scope="col">{t('misc.order')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                data.map((item) => (
                                                    <tr key={item.id}>
                                                        <td>{item.ean}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.description}</td>
                                                        <td>{item.stock}</td>
                                                        <td>{item.minStock}</td>
                                                        <td>{item.minOrder}</td>
                                                    </tr>
                                                ))
                                            }


                                            </tbody>
                                        </table>
                                    </span>
                                }
                            {/*</div>*/}
                        </div>
                    </div>
                </div>



            </div>
    )
}