import React, { useState} from "react";
import UserService from "../services/user.service";
import {Redirect} from "react-router-dom";

export default function ResetPassword(){
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const handlePasswordChange = (event) =>{
    setPassword(event.target.value);
  }
  const handleConfirmPasswordChange = (event) =>{
    setConfirmPassword(event.target.value);
  }
  const handleResetPassword = (event) =>{
    event.preventDefault();
    if(password !== confirmPassword){
      setMessage('Ihr Passwort und Ihr Bestätigungspasswort stimmen nicht überein')
    }else{
      setLoading(true);
      let t =new URLSearchParams(window.location.search).get("token");
      UserService.resetPassword(t,{'plainPassword':password}).then((res) => {
        setData(res.data);
        setLoading(false);
      }).catch((err) => {
        setMessage(err);
        setLoading(false);
      });
    }

  };

  return (
        <div className="col-md-12">
          <div className="card card-container">

            {data.status === 'login' && (
                <Redirect to='login?pw=1' />
            )}
            {data.status === 'forgot_password' && (
                <div className="alert alert-danger" role="alert">
                  etwas schief gelaufen, versuch Sie nochmal
                </div>
            )}

            <form onSubmit={handleResetPassword} id='userForm' name='userForm'>
              <div className="form-group">
                <label htmlFor="password">Passwort</label>
                <input type="password"
                       className="form-control wlb"
                       name='password'
                       id="password"
                       aria-describedby="password"
                       placeholder="Passwort"
                       value={password}
                       onChange={handlePasswordChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirm_password">Passwort bestätigen</label>
                <input type="password"
                       className="form-control wlb"
                       name='confirm_password'
                       id="confirm_password"
                       aria-describedby="confirm_password"
                       placeholder="Passwort bestätigen"
                       value={confirmPassword}
                       onChange={handleConfirmPasswordChange}
                />
              </div>
              <div className="form-group">
                <button
                    className="btn btn-primary btn-block"
                    disabled={loading}
                >
                  {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Passwort vergessen</span>
                </button>
              </div>
              {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
              )}
            </form>
          </div>
        </div>
    );
}
