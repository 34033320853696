import React, {useState} from "react";
import AuthService from "../services/auth.service";
import {Link, Redirect} from "react-router-dom";
import stockIcon from '../assets/images/stock-icon.svg';
import masterCalcIcon from '../assets/images/mastercalc-icon.svg';
import {useTranslation} from "react-i18next";


export default function Home() {
    const currentUser = AuthService.getCurrentUser();
    const obj = (currentUser) ? {currentUser: currentUser, userReady: true} : {redirect: "/login"};
    const [state] = useState(obj);
    const { t, i18n } = useTranslation();
    if (state.redirect)
        return <Redirect to={state.redirect}/>
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    {currentUser.konsignationsLager &&
                    <div className="card card-container bg-grey p-0">
                        <img src={stockIcon} className='img-fluid dashboard-icon spacer-bt-1'
                             alt='Arntz Konsignationslager'/>
                        <h2>{t('home.stock')}</h2>
                        <p>
                            {t('home.stockLabel')}
                        </p>
                        <Link to={"/stock_management"} className="btn btn-block btn-primary">
                            {t('home.stockButton')}
                        </Link>
                    </div>
                    }
                    {currentUser.masterCalc &&
                    <div className="card card-container bg-grey p-0">
                        <img src={masterCalcIcon} className='img-fluid dashboard-icon spacer-bt-1'
                             alt='Arntz MasterCalc'/>
                        <h2>{t('home.master')}</h2>
                        <p>
                            {t('home.masterLabel')}
                        </p>
                        <Link to={"/master_calc"} className="btn btn-block btn-primary">
                            {t('home.masterButton')}
                        </Link>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}