import React, { useState } from "react";
export default function useShareableState() {
    const [currency, setCurrency] = useState("€");
    const [unit, setUnit] = useState('meter-cm');

    return {
        currency,
        setCurrency,
        unit,
        setUnit
    };
}