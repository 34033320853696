import React, {useEffect, useState} from "react";
import AuthService from "../services/auth.service";
import {Link, Redirect, useHistory} from "react-router-dom";
import UserService from "../services/user.service";
import {CircularProgress} from '@material-ui/core';
import arrowBack from "../assets/images/arrow.svg";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {useTranslation} from "react-i18next";
import logo from "../assets/images/Logo.svg";
// import { pdfjs } from "react-pdf";
// import { Document, Page } from 'react-pdf';
import {useBetween} from "use-between";
import useShareableState from "../shareableState";
import ArntzDatabase from "../services/database";
import Modal from 'react-modal';
// import { Document, Page } from "react-pdf";
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

// if(window.navigator.onLine) {
//     pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//     // pdfjs.GlobalWorkerOptions.workerSrc = "../services/pdf.worker.js";
// }

export default function Result(props) {
    const {t, i18n} = useTranslation();
    let history = useHistory();
    const currentUser = AuthService.getCurrentUser();
    const obj = (currentUser) ? {currentUser: currentUser, userReady: true} : {redirect: "/login"};
    const [state] = useState(obj);
    const [speed, setSpeed] = useState();
    const [msg, setMsg] = useState('message.noResult');
    const res = (props.location.aboutProps != null) ? props.location.aboutProps : {redirect: "/master_calc"};
    console.log(res)
    // const [item, setItem] = useState(getItem.item);
    const [getItem, setGetItem] = useState({});
    const [item, setItem] = useState({});
    const [loading, setLoading] = useState(true);
    const [inputs, setInputs] = useState({});
    const [result, setResult] = useState();
    const [time, setTime] = useState();
    const [data, setData] = useState();
    const [activeTab, setActiveTab] = useState('ergebnis');
    const [filtersAtt, setFiltersAtt] = useState({});
    const [dimensions, setDimensions] = useState({});
    const [dimensionUnit, setDimensionUnit] = useState({});
    const [values, setValues] = useState({});
    const [localUnit, setLocalUnit] = useState('');
    const [costs, setCosts] = useState([]);
    // const [titleTab, setTitleTab] = useState('misc.resulttab');
    const [titleTab, setTitleTab] = useState('misc.edata');
    const { unit, setUnit, currency, setCurrency } = useBetween(useShareableState);
    const [ showModal, setShowModal ] = useState(false);
    const [ pageNumber, setPageNumber ] = useState(1);
    const [ pdfLink, setPdfLink ] = useState('');


    const renderToolbar = (toolbarSlot) => {
        return (
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                    }}
                >
                    <div style={{ padding: '0 5px' }}>
                        {toolbarSlot.searchPopover}
                    </div>
                    <div style={{ padding: '0 5px' }}>
                        {toolbarSlot.previousPageButton}
                    </div>
                    <div style={{ padding: '0 5px' }}>
                        {toolbarSlot.currentPageInput} / {toolbarSlot.numPages}
                    </div>
                    <div style={{ padding: '0 5px' }}>
                        {toolbarSlot.nextPageButton}
                    </div>
                </div>
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexGrow: 1,
                        flexShrink: 1,
                        justifyContent: 'center',
                    }}
                >
                    <div style={{ padding: '0 5px' }}>
                        {toolbarSlot.zoomOutButton}
                    </div>
                    <div style={{ padding: '0 5px' }}>
                        {toolbarSlot.zoomPopover}
                    </div>
                    <div style={{ padding: '0 5px' }}>
                        {toolbarSlot.zoomInButton}
                    </div>
                </div>
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        marginLeft: 'auto',
                    }}
                >
                    <div style={{ padding: '0 5px' }}>
                        {toolbarSlot.fullScreenButton}
                    </div>
                    <div style={{ padding: '0 5px' }}>
                        {toolbarSlot.openFileButton}
                    </div>
                    <div style={{ padding: '0 5px' }}>
                        {toolbarSlot.downloadButton}
                    </div>
                    <div style={{ padding: '0 5px' }}>
                        {toolbarSlot.moreActionsPopover}
                    </div>
                </div>
            </div>
        );
    };
    const layout = (
        isSidebarOpened,
        main,
        toolbar,
        sidebar
    ) => {
        return (
            <div
                style={{
                    border: '1px solid rgba(0, 0, 0, .3)',
                    display: 'grid',
                    gridTemplateAreas: "'toolbar toolbar' 'sidebar main'",
                    gridTemplateColumns: '30% 1fr',
                    gridTemplateRows: '40px calc(100% - 40px)',
                    height: '100%',
                    overflow: 'hidden',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        alignItems: 'center',
                        backgroundColor: '#EEE',
                        borderBottom: '1px solid rgba(0, 0, 0, .1)',
                        display: 'flex',
                        gridArea: 'toolbar',
                        justifyContent: 'center',
                        padding: '4px',
                    }}
                >
                    {/*{toolbar(renderToolbar)}*/}
                </div>
                <div
                    style={{
                        borderRight: '1px solid rgba(0, 0, 0, 0.2)',
                        display: 'flex',
                        gridArea: 'sidebar',
                        justifyContent: 'center',
                    }}
                >
                    {sidebar.children}
                </div>
                <div
                    {...main.attrs}
                    style={Object.assign({}, {
                        gridArea: 'main',
                        overflow: 'scroll',
                    }, main.attrs.style)}
                >
                    {main.children}
                </div>
            </div>
        );
    };


    // const pdfFile = "https://www.arntz.de/media/186/download/ARNTZ%20FactBook%20Edition%202021%20DE.pdf?v=6&inline=1";
    // const pdfFile = "http://localhost:8081/"+t('misc.url')+'.pdf';
    // const pdfFile = "https://artnz-customer.kaupa-hosting2.de/"+t('misc.url')+'.pdf';
    const pdfFile = window.location.protocol + "//" + window.location.host+"/"+t('misc.url')+'.pdf';

    const unitConverter = ()=>{
        console.log(dimensions)
        let dimmm = dimensions+"";
        const arr = dimmm.split('*');
        console.log(arr);

        const dim = (arr.length > 1) ?
            // Number(parseFloat(arr[0] * 0.039370078740157)).toFixed(5)+' * '+Number(parseFloat(arr[1] * 0.039370078740157)).toFixed(5)
            Math.round((parseFloat(arr[0].toString().replace(",",".")) * 0.039370078740157 + Number.EPSILON) * 100000) / 100000+' * '+Math.round((parseFloat(arr[1].toString().replace(",",".")) * 0.039370078740157 + Number.EPSILON) * 100000) / 100000
            // : Number(parseFloat(arr[0] * 0.039370078740157)).toFixed(5)
            : Math.round((parseFloat(arr[0].toString().replace(",",".")) * 0.039370078740157 + Number.EPSILON) * 100000) / 100000
        return (unit ==='meter-cm')? dimensions+" mm"  : dim+" inch";
    }
    const unitStr = ()=>{
        return (unit ==='meter-cm')? "mm" : "inch"
    }

    useEffect(() => {
        let items = [];
        if ('data' in res) {
            const length = res['data'].length;
            console.log(res['data']);
            let P = function (val, ele) {
                return new Promise((resolve,reject) => {
                    console.log(val);
                    let getItem = {
                        // item: res.data[val],
                        item: val,
                        durchmesser: res.durchmesser,
                        measure: res.measure,
                        height: res.height,
                        width: res.width,
                        surface: res.surface,
                        materialType: res.materialType,
                        filters: res.filters,
                        wallThickness: res.wallThickness,
                        typ :{
                            'ru':'master.round2',
                            'q':'master.square',
                            're':'master.tangle',
                            'hem':'HE-M',
                            'heb':'HE-B',
                            'hea':'HE-A',
                            'ip':'I-PE',
                            'vi': 'master.four',
                            'ro': 'master.round',
                            'ls': 'master.lsteel',
                            'us': 'master.usteel',
                        }
                    };

                    setGetItem(getItem);
                    setItem(getItem.item);
                    setFiltersAtt(res.filters);

                    console.log(getItem.item)

                    if ('item' in getItem) {
                        if (window.navigator.onLine) {
                            (async () => {
                                if(getItem.item.dimension == null){
                                    let filters={
                                        height :getItem.filters['dimension.height'],
                                        strength :getItem.filters['dimension.strength']
                                    }
                                    await UserService.getAll('dimensions', filters).then((res) => {
                                        console.log(res.data[0])
                                        getItem.item.dimension = res.data[0]
                                    }).catch((err) => {
                                        console.log('errr');
                                    });
                                }
                                if(getItem.item.materialGroup == null){
                                    let filters ={
                                        din :getItem.filters.din,
                                        number:getItem.filters.wnumber
                                    }
                                    await UserService.getAll('materials', filters).then((res) => {
                                        console.log(res.data[0])
                                        console.log(res.data[0].MetrialGroup);
                                        // getItem.item.materialGroup = {'id':parseInt(res.data[0].MetrialGroup.split("/")[3])};
                                        getItem.item.materialGroup = res.data[0].MetrialGroup;
                                    }).catch((err) => {
                                        console.log('errr');
                                    });
                                }
                                const surface = getItem.item.surface;
                                console.log(getItem.item)
                                if (getItem.item.dimension != null && getItem.item.gearing != null && getItem.item.materialGroup != null /*&& getItem.item.specification != null*/) {
                                    let filter = {
                                        'dimension': getItem.item.dimension.id,
                                        'gearing': (getItem.item.gearing.id == 6)? 7 :getItem.item.gearing.id ,
                                        'materialGroup': getItem.item.materialGroup.id,
                                        // 'specification': getItem.item.specification.id,
                                        'category': getItem.item.category == null || getItem.item.category.id != 3 ? 5 : getItem.item.category.id,
                                    }
                                    if(filter.category == 3 ){
                                        filter['product'] = getItem.item.product.id;
                                    }
                                    UserService.getAll('speeds', filter).then((ress) => {

                                        if (ress.data.length <= 0) {
                                            // setSpeed();
                                            // setData();
                                            setMsg('message.noResult');
                                            setLoading(false);
                                            reject();
                                            return false;
                                        }
                                        else{
                                            let d = ress.data[0];
                                            filter = {
                                                'gearing': getItem.item.gearing.id,
                                                'category': filter.category,
                                            }
                                            if(filter.category != 3 ){
                                                filter['product'] = getItem.item.product.id;
                                            }
                                            UserService.getAll('tpms', filter).then((tpms) => {
                                                let tpm = tpms.data[0];
                                                console.log("product id "+getItem.item.product.id);
                                                console.log(d);
                                                console.log("vor " + d.speed);
                                                d.speed = (["437", "537"].includes(getItem.item.product.name)) ? d.speed * 1.15 : d.speed;
                                                console.log("getItem.surface------------------------------- " + getItem.surface);
                                                 // d.speed = (getItem.item.gearing.id == 6 || ["ls", "us","ip","hea","heb","hem"].includes(getItem.surface) ) ? d.speed * 1.05 : d.speed;
                                                d.speed = (getItem.item.gearing.id == 6 || getItem.materialType === "profile" ) ? d.speed * 1.05 : d.speed;

                                                // d.speed = Math.round(d.speed);
                                                console.log("nach " + d.speed);
                                                d.tpm = 1;
                                                if (tpms.data.length > 0) {
                                                    d.tpm = tpm.tpm;
                                                    d['vorschubgeschwindigkeit'] = d.speed * d.tpm * d.feed;
                                                }else
                                                    d['vorschubgeschwindigkeit'] = (d.speed) ? d.speed * d.feed : d.feed;

                                                console.log("***************speed"+d['speed']);
                                                console.log("******************tpm"+d['tpm']);
                                                console.log("*************feed"+d['feed']);
                                                console.log("***********vorschubgeschwindigkeit"+d['vorschubgeschwindigkeit']);

                                                // d['vorschubgeschwindigkeit'] = parseFloat(d['vorschubgeschwindigkeit']).toFixed(1)
                                                d['durchmesser'] = (getItem.durchmesser) ? parseFloat(getItem.durchmesser.toString().replace(',', '.').split(" x ")[0]) : getItem.height.toString().replace(',', '.');
                                                d['measure'] = getItem.measure.length + ' mm x ' + getItem.measure.height + ' mm x ' + getItem.measure.strength + ' mm';

                                                let bundlingHeight = parseInt(getItem.filters['bundlingHeight']);
                                                let bundlingWidth = parseInt(getItem.filters['bundlingWidth']);
                                                let time = (d['durchmesser'] / d['vorschubgeschwindigkeit']) //.toFixed(2);
                                                setTime(time);

                                                d['schnittzeit'] = secondsToHms(Math.round(time * bundlingHeight * 60)) ;
                                                console.log("Math.round(time * bundlingHeight * 60) : "+Math.round(time * bundlingHeight * 60))
                                                console.log("vorschubgeschwindigkeit ******* "+d['vorschubgeschwindigkeit'])
                                                console.log("durchmesser ******* "+d['durchmesser'])
                                                console.log("time*********"+time)

                                                // d['durchmesser'] = d['durchmesser'] / 10;
                                                if (getItem.materialType === 'fullMaterial') {
                                                    switch (getItem.surface) {
                                                        case 're': {
                                                            d['surface'] = (getItem.height.toString().replace(',', '.') / 10) * (getItem.width.toString().replace(',', '.') / 10);
                                                            console.log(d['surface'])
                                                            // setDimensions(unitConverter(getItem.width)+'*'+unitConverter(getItem.height)+" "+unitStr())
                                                            setDimensions(getItem.width+'*'+getItem.height)
                                                            setDimensionUnit('master.width2'+'*'+'master.height')
                                                            break;
                                                        }
                                                        case 'q': {
                                                            d['surface'] = Math.pow(d['durchmesser'] /10, 2);//d['durchmesser'] * d['durchmesser'];
                                                            // setDimensions(unitConverter(getItem.durchmesser)+" "+unitStr())
                                                            setDimensions(getItem.durchmesser)
                                                            // setDimensionUnit('master.side')
                                                            setDimensionUnit('misc.dimension')
                                                            break;
                                                        }
                                                        default: {
                                                            d['surface'] = Math.pow((d['durchmesser'] / 10) / 2, 2) * Math.PI; //(d['durchmesser']/2)*(d['durchmesser']/2)
                                                            // setDimensions(unitConverter(getItem.durchmesser)+" "+unitStr())
                                                            setDimensions(getItem.durchmesser);
                                                            setDimensionUnit('master.dia')
                                                            // setDimensionUnit('misc.dimension')
                                                            break;
                                                        }
                                                    }
                                                } else {
                                                    let diatr = "";
                                                    console.log(getItem.filters);
                                                    // if (["ro","vi"].includes(getItem.surface)) {
                                                    //     d['durchmesser'] = parseFloat(getItem.durchmesser) / 10;
                                                    //     d['wallThickness'] = parseFloat(getItem.wallThickness) / 10;
                                                    //     // let totalSurface = Math.pow(d['durchmesser'] / 2, 2) * 3.14;
                                                    //     // let outerSurface = Math.pow((d['durchmesser'] - (d['wallThickness'] * 2)) / 2, 2) * 3.14;
                                                    //     let totalSurface = Math.pow(d['durchmesser'] , 2) ;
                                                    //     let outerSurface = Math.pow((d['durchmesser'] - (d['wallThickness'] * 2)), 2) ;
                                                    //     d['surface'] = totalSurface - outerSurface;
                                                    // }
                                                    if(getItem.surface == "ro") {
                                                        diatr = 'master.dia';
                                                        // d['durchmesser'] = parseFloat(getItem.durchmesser.replace(',', '.'));
                                                        d['wallThickness'] = parseFloat(getItem.wallThickness.toString().replace(',', '.'));
                                                        let totalSurface = Math.pow( (d['durchmesser'] / 10) / 2, 2) * Math.PI;
                                                        let outerSurface = Math.pow(( (d['durchmesser'] / 10) - ((d['wallThickness']/10) * 2)) / 2, 2) * Math.PI;
                                                        console.log("totalSurface :"+totalSurface)
                                                        console.log("outerSurface :"+outerSurface)
                                                        d['surface'] = (totalSurface - outerSurface);
                                                        console.log("surfaceeee :"+d['surface'])
                                                        // bundlingWidth = bundlingHeight = 1
                                                    }else if(getItem.surface == "vi"){
                                                        diatr = 'misc.dimension';
                                                        // d['durchmesser'] = parseFloat(getItem.durchmesser.replace(',', '.'));
                                                        d['wallThickness'] = parseFloat(getItem.wallThickness.toString().replace(',', '.'));
                                                        let totalSurface = Math.pow((d['durchmesser'] / 10) , 2) ;
                                                        let outerSurface = Math.pow(((d['durchmesser'] / 10)  - ((d['wallThickness']/10) * 2)), 2) ;
                                                        d['surface'] = totalSurface - outerSurface;
                                                    }
                                                    else {
                                                        diatr = 'misc.dimension';
                                                        if(bundlingWidth > 1){
                                                            d['surface'] = parseFloat(surface) / 2;
                                                        }else
                                                            d['surface'] = surface;
                                                    }
                                                    // let dim = unitConverter(getItem.durchmesser);
                                                    let dim = getItem.durchmesser;
                                                    // dim +=  (getItem.wallThickness == undefined )?"":" * "+unitConverter(getItem.wallThickness);
                                                    // dim +=  (getItem.wallThickness == undefined )?"":" * "+getItem.wallThickness;
                                                    dim +=  (getItem.wallThickness)?" * "+getItem.wallThickness:"";
                                                    // dim +=" "+unitStr()
                                                    setDimensions(dim)

                                                    // let diatr = 'master.dia';
                                                    // let diatr = 'misc.dimension';
                                                    // diatr += (getItem.wallThickness == undefined ) ? '': "*"+'master.walla';
                                                    diatr += (getItem.wallThickness) ? "*"+'master.walla' :'';
                                                    setDimensionUnit(diatr)
                                                    // d['wallThickness']  = d['wallThickness'] * (getItem.filters['bundlingWidth'] <1 ) ? parseInt(getItem.filters['bundlingWidth']) : 2;
                                                }
                                                //
                                                // d['surface'] = d['surface'] * bundlingHeight * bundlingWidth;
                                                console.log(d['surface'])
                                                console.log("time "+time);
                                                // d['Zerspanungsrate'] = Math.round(d['surface'] / (time * bundlingHeight));
                                                if(bundlingHeight > bundlingWidth && bundlingWidth <=1 ){
                                                    bundlingWidth = bundlingHeight;
                                                }else{
                                                    bundlingHeight = 1;
                                                }
                                                // bundlingWidth = (bundlingHeight > bundlingWidth && bundlingWidth<=1) ? bundlingHeight:bundlingWidth;
                                                d['Zerspanungsrate'] = (bundlingWidth * d['surface']) / (time * bundlingHeight);
                                                // d['Zerspanungsrate'] = (d['surface'] / (time * bundlingHeight)).toFixed(2);

                                                console.log(d);
                                                // setSpeed(d);
                                                getItem.item['surface'] = d['surface'];
                                                // items.push({'item': getItem.item, 'speed': d})
                                                getItem.speedData = d
                                                getItem.timeData = time
                                                console.log("***********************feed :-------------- "+d['feed'].toFixed(3))
                                                console.log(" Number(d['feed'].toFixed(2)) "+ Number(d['feed'].toFixed(3)))
                                                // d['feed'] = Number(d['feed'].toFixed(3));
                                                resolve({'item': getItem.item, 'speed': d,'time':time});
                                                /*console.log("val :" + parseInt(val))
                                                console.log("length : " + (length - 1))
                                                if (parseInt(val) === length - 1) {
                                                    setLoading(false)
                                                    setData(items);
                                                    console.log(items);
                                                }*/
                                            })
                                                .catch((err) => {
                                                    // setLoading(false)
                                                    // console.log(err)
                                                    getOfflineSubmit(getItem,resolve, reject)
                                                });
                                        }
                                    }).catch((err) => {
                                        // setLoading(false)
                                        getOfflineSubmit(getItem,resolve, reject)
                                    });
                                } else {
                                    // setLoading(false);
                                    getOfflineSubmit(getItem,resolve, reject)
                                }
                            })();
                        }
                        else {
                            // setLoading(false)
                            getOfflineSubmit(getItem,resolve, reject)
                        }
                    }
                    else {
                        // setLoading(false)
                        getOfflineSubmit(getItem,resolve, reject)
                    }
                })
            };
            let results = Promise.all(res['data'].map(P)).then(items => {
                console.log("dataaaaaaaaaaaaaaaaaaaaaaaaa");
                console.log(items);
                setLoading(false)
                setData(items);
            }).catch((e)=>{console.log(e)})
            console.log(results);
        } else {
            setLoading(false);
            setData();
            setMsg('message.noResult');
        }

    }, []);

    const getOfflineSubmit = (getItem,resolve, reject)=>{
        (async () => {
            if(getItem.item.dimension == null){
                await ArntzDatabase.db.resources['dimensions'].filter((decision) => {
                    return decision.height == getItem.filters['dimension.height'] &&
                        decision.strength == getItem.filters['dimension.strength']
                })
                    .toArray().then((res) => {
                        console.log(res[0])
                        getItem.item.dimension = res[0]
                    })
                    .catch((e) => {
                        console.log(e)
                        setLoading(false);
                        setData();
                        setMsg(t('message.noResult'));
                    });
            }
            if(getItem.item.materialGroup == null){
                await ArntzDatabase.db.resources['materials'].filter((material) => {
                    return material.din == getItem.filters.din &&
                        material.number == getItem.filters.wnumber
                })
                    .toArray().then((res) => {
                        console.log(res[0])
                        console.log(res[0].MetrialGroup);
                        getItem.item.materialGroup = res[0].MetrialGroup;
                    })
                    .catch((e) => {
                        console.log(e)
                        setLoading(false);
                        setData();
                        setMsg(t('message.noResult'));
                    });
            }
            const surface = getItem.item.surface;
            console.log(getItem.item)
            if (getItem.item.dimension  && getItem.item.gearing  && getItem.item.materialGroup  /*&& getItem.item.specification != null*/) {
                console.log("okayyyyy")
                // let filter = {
                //     'dimension': getItem.item.dimension.id,
                //     'gearing': (getItem.item.gearing.id == 6)? 7 :getItem.item.gearing.id ,
                //     'materialGroup': getItem.item.materialGroup.id,
                //     // 'specification': getItem.item.specification.id,
                //     'category': getItem.item.category == null || getItem.item.category.id != 3 ? 5 : getItem.item.category.id,
                // }
                // if(filter.category == 3 ){
                //     filter['product'] = getItem.item.product.id;
                // }
                let category = (getItem.item.category == null || getItem.item.category.id != 3) ? 5 : getItem.item.category.id;
                ArntzDatabase.db.resources['speeds'].filter((speed) => {
                    return ((speed.dimension) ? speed.dimension.id == getItem.item.dimension.id : true) &&
                        ((speed.gearing) ? speed.gearing.id == ((getItem.item.gearing.id == 6)? 7 :getItem.item.gearing.id) : true) &&
                        ((speed.materialGroup) ? speed.materialGroup.id == getItem.item.materialGroup.id : true) &&
                        ((speed.category) ? speed.category.id == category :true) &&
                        ((category == 3 && speed.product ) ? speed.product.id == getItem.item.product.id : true)
                })
                    .toArray().then((ress) => {
                        console.log(ress);
                    if (ress.length <= 0) {
                        // setSpeed();
                        // setData();
                        setMsg('message.noResult');
                        setLoading(false);
                        reject();
                        return false;
                    }
                    else{
                        let d = ress[0];
                        ArntzDatabase.db.resources['tpms'].filter((speed) => {
                            return ((speed.gearing) ? speed.gearing.id == getItem.item.gearing.id : true) &&
                                ((speed.category) ? speed.category.id == category :true) &&
                                ((category != 3 && speed.product ) ? speed.product.id == getItem.item.product.id : true)
                        }).toArray()
                            .then((tpms) => {
                                let tpm = tpms[0];
                                console.log("product id "+getItem.item.product.id);
                                console.log(d);
                                console.log("vor " + d.speed);
                                d.speed = (["437", "537"].includes(getItem.item.product.name)) ? d.speed * 1.15 : d.speed;
                                console.log("getItem.surface------------------------------- " + getItem.surface);
                                // d.speed = (getItem.item.gearing.id == 6 || ["ls", "us","ip","hea","heb","hem"].includes(getItem.surface) ) ? d.speed * 1.05 : d.speed;
                                d.speed = (getItem.item.gearing.id == 6 || getItem.materialType === "profile" ) ? d.speed * 1.05 : d.speed;

                                // d.speed = Math.round(d.speed);
                                console.log("nach " + d.speed);
                                d.tpm = 1;
                                if (tpms.length > 0) {
                                    d.tpm = tpm.tpm;
                                    d['vorschubgeschwindigkeit'] = d.speed * d.tpm * d.feed;
                                }else
                                    d['vorschubgeschwindigkeit'] = (d.speed) ? d.speed * d.feed : d.feed;

                                console.log("***************speed"+d['speed']);
                                console.log("******************tpm"+d['tpm']);
                                console.log("*************feed"+d['feed']);
                                console.log("***********vorschubgeschwindigkeit"+d['vorschubgeschwindigkeit']);

                                // d['vorschubgeschwindigkeit'] = parseFloat(d['vorschubgeschwindigkeit']).toFixed(1)
                                d['durchmesser'] = (getItem.durchmesser) ? parseFloat(getItem.durchmesser.toString().replace(',', '.').split(" x ")[0]) : getItem.height.toString().replace(',', '.');
                                d['measure'] = getItem.measure.length + ' mm x ' + getItem.measure.height + ' mm x ' + getItem.measure.strength + ' mm';

                                let bundlingHeight = parseInt(getItem.filters['bundlingHeight']);
                                let bundlingWidth = parseInt(getItem.filters['bundlingWidth']);
                                let time = (d['durchmesser'] / d['vorschubgeschwindigkeit']) //.toFixed(2);
                                setTime(time);

                                d['schnittzeit'] = secondsToHms(Math.round(time * bundlingHeight * 60)) ;
                                console.log("Math.round(time * bundlingHeight * 60) : "+Math.round(time * bundlingHeight * 60))
                                console.log("vorschubgeschwindigkeit ******* "+d['vorschubgeschwindigkeit'])
                                console.log("durchmesser ******* "+d['durchmesser'])
                                console.log("time*********"+time)

                                // d['durchmesser'] = d['durchmesser'] / 10;
                                if (getItem.materialType === 'fullMaterial') {
                                    switch (getItem.surface) {
                                        case 're': {
                                            d['surface'] = (getItem.height.toString().replace(',', '.') / 10) * (getItem.width.toString().replace(',', '.') / 10);
                                            console.log(d['surface'])
                                            // setDimensions(unitConverter(getItem.width)+'*'+unitConverter(getItem.height)+" "+unitStr())
                                            setDimensions(getItem.width+'*'+getItem.height)
                                            setDimensionUnit('master.width2'+'*'+'master.height')
                                            break;
                                        }
                                        case 'q': {
                                            d['surface'] = Math.pow(d['durchmesser'] /10, 2);//d['durchmesser'] * d['durchmesser'];
                                            // setDimensions(unitConverter(getItem.durchmesser)+" "+unitStr())
                                            setDimensions(getItem.durchmesser)
                                            // setDimensionUnit('master.side')
                                            setDimensionUnit('misc.dimension')
                                            break;
                                        }
                                        default: {
                                            d['surface'] = Math.pow((d['durchmesser'] / 10) / 2, 2) * Math.PI; //(d['durchmesser']/2)*(d['durchmesser']/2)
                                            // setDimensions(unitConverter(getItem.durchmesser)+" "+unitStr())
                                            setDimensions(getItem.durchmesser);
                                            setDimensionUnit('master.dia')
                                            // setDimensionUnit('misc.dimension')
                                            break;
                                        }
                                    }
                                } else {
                                    let diatr = "";
                                    console.log(getItem.filters);
                                    // if (["ro","vi"].includes(getItem.surface)) {
                                    //     d['durchmesser'] = parseFloat(getItem.durchmesser) / 10;
                                    //     d['wallThickness'] = parseFloat(getItem.wallThickness) / 10;
                                    //     // let totalSurface = Math.pow(d['durchmesser'] / 2, 2) * 3.14;
                                    //     // let outerSurface = Math.pow((d['durchmesser'] - (d['wallThickness'] * 2)) / 2, 2) * 3.14;
                                    //     let totalSurface = Math.pow(d['durchmesser'] , 2) ;
                                    //     let outerSurface = Math.pow((d['durchmesser'] - (d['wallThickness'] * 2)), 2) ;
                                    //     d['surface'] = totalSurface - outerSurface;
                                    // }
                                    if(getItem.surface == "ro") {
                                        diatr = 'master.dia';
                                        // d['durchmesser'] = parseFloat(getItem.durchmesser.replace(',', '.'));
                                        d['wallThickness'] = parseFloat(getItem.wallThickness.toString().replace(',', '.'));
                                        let totalSurface = Math.pow( (d['durchmesser'] / 10) / 2, 2) * Math.PI;
                                        let outerSurface = Math.pow(( (d['durchmesser'] / 10) - ((d['wallThickness']/10) * 2)) / 2, 2) * Math.PI;
                                        console.log("totalSurface :"+totalSurface)
                                        console.log("outerSurface :"+outerSurface)
                                        d['surface'] = (totalSurface - outerSurface);
                                        console.log("surfaceeee :"+d['surface'])
                                        // bundlingWidth = bundlingHeight = 1
                                    }else if(getItem.surface == "vi"){
                                        diatr = 'misc.dimension';
                                        // d['durchmesser'] = parseFloat(getItem.durchmesser.replace(',', '.'));
                                        d['wallThickness'] = parseFloat(getItem.wallThickness.toString().replace(',', '.'));
                                        let totalSurface = Math.pow((d['durchmesser'] / 10) , 2) ;
                                        let outerSurface = Math.pow(((d['durchmesser'] / 10)  - ((d['wallThickness']/10) * 2)), 2) ;
                                        d['surface'] = totalSurface - outerSurface;
                                    }
                                    else {
                                        diatr = 'misc.dimension';
                                        if(bundlingWidth > 1){
                                            d['surface'] = parseFloat(surface) / 2;
                                        }else
                                            d['surface'] = surface;
                                    }
                                    // let dim = unitConverter(getItem.durchmesser);
                                    let dim = getItem.durchmesser;
                                    // dim +=  (getItem.wallThickness == undefined )?"":" * "+unitConverter(getItem.wallThickness);
                                    // dim +=  (getItem.wallThickness == undefined )?"":" * "+getItem.wallThickness;
                                    dim +=  (getItem.wallThickness)?" * "+getItem.wallThickness:"";
                                    // dim +=" "+unitStr()
                                    setDimensions(dim)

                                    // let diatr = 'master.dia';
                                    // let diatr = 'misc.dimension';
                                    // diatr += (getItem.wallThickness == undefined ) ? '': "*"+'master.walla';
                                    diatr += (getItem.wallThickness) ? "*"+'master.walla' :'';
                                    setDimensionUnit(diatr)
                                    // d['wallThickness']  = d['wallThickness'] * (getItem.filters['bundlingWidth'] <1 ) ? parseInt(getItem.filters['bundlingWidth']) : 2;
                                }
                                //
                                // d['surface'] = d['surface'] * bundlingHeight * bundlingWidth;
                                console.log(d['surface'])
                                console.log("time "+time);
                                // d['Zerspanungsrate'] = Math.round(d['surface'] / (time * bundlingHeight));
                                if(bundlingHeight > bundlingWidth && bundlingWidth <=1 ){
                                    bundlingWidth = bundlingHeight;
                                }else{
                                    bundlingHeight = 1;
                                }
                                // bundlingWidth = (bundlingHeight > bundlingWidth && bundlingWidth<=1) ? bundlingHeight:bundlingWidth;
                                d['Zerspanungsrate'] = (bundlingWidth * d['surface']) / (time * bundlingHeight);
                                // d['Zerspanungsrate'] = (d['surface'] / (time * bundlingHeight)).toFixed(2);

                                console.log(d);
                                // setSpeed(d);
                                getItem.item['surface'] = d['surface'];
                                // items.push({'item': getItem.item, 'speed': d})
                                getItem.speedData = d
                                getItem.timeData = time
                                console.log("***********************feed :-------------- "+d['feed'].toFixed(3))
                                console.log(" Number(d['feed'].toFixed(2)) "+ Number(d['feed'].toFixed(3)))
                                // d['feed'] = Number(d['feed'].toFixed(3));
                                resolve({'item': getItem.item, 'speed': d,'time':time});
                                /*console.log("val :" + parseInt(val))
                                console.log("length : " + (length - 1))
                                if (parseInt(val) === length - 1) {
                                    setLoading(false)
                                    setData(items);
                                    console.log(items);
                                }*/
                            })
                            .catch((err) => {
                                setLoading(false)
                                console.log(err)
                                setData();
                                setMsg('message.noResult');
                            });
                    }
                })
                    .catch((e) => {
                        console.log(e)
                        setLoading(false);
                        setData();
                        setMsg('message.noResult');
                    });
            } else {
                setLoading(false);
                setData();
                setMsg('message.noResult');
            }
        })();
    }



    const pdfOnClickHandle = (e)=>{
        e.preventDefault();
        let pn =e.target.id;
        let url = `${pdfFile}#page=${pn}`;
        // let url = `${pdfFile}`;
        console.log(url);

        setPageNumber(pn-1);
        setPdfLink(url);
        setShowModal(true);

        // let open = window.open(url, '_blank', 'fullscreen=yes');
        // if (open == null || typeof(open)=='undefined')
        //     alert(t('misc.popup'));

        // if(window.navigator.onLine){
        //     let url = `${pdfFile}#page=${pageNumber}`;
        //     let open = window.open(url, '_blank', 'fullscreen=yes');
        //     if (open == null || typeof(open)=='undefined')
        //         alert(t('misc.popup'));
        //     // pdfjs.getDocument(pdfFile).promise.then((docData) => {
        //     //     const pageCount = docData._pdfInfo.numPages;
        //     //
        //     //     const pagePromises = Array.from(
        //     //         { length: pageCount },
        //     //         (_, pageNumber) => {
        //     //             return docData.getPage(pageNumber + 1).then((pageData) => {
        //     //                 return pageData.getTextContent().then((textContent) => {
        //     //                     return textContent.items.map(({ str }) => str).join(" ");
        //     //                 });
        //     //             });
        //     //         }
        //     //     );
        //     //
        //     //     return Promise.all(pagePromises).then((pages) => {
        //     //
        //     //         console.log('searchString----------'+searchString);
        //     //         const regex = new RegExp(`${searchString}`, "i");
        //     //         // const updatedResults = [];
        //     //         // console.log("pages-------------"+pages);
        //     //         // pages.forEach((text, index) => {
        //     //         for (let i = 0;i< pages.length; i++){
        //     //             let index = i;
        //     //             let text = pages[i];
        //     //             // console.log("index :"+index)
        //     //             // console.log("text :"+text)
        //     //             if (regex.test(text)) {
        //     //                 // updatedResults.push(index + 1);
        //     //                 // console.log(index + 1);
        //     //                 let url = `${pdfFile}#page=${index + 1}`;
        //     //                 let open = window.open(url, '_blank', 'fullscreen=yes');
        //     //                 if (open == null || typeof(open)=='undefined')
        //     //                     alert(t('misc.popup'));
        //     //                 break
        //     //             }
        //     //         }
        //     //
        //     //     });
        //     // });
        // }else{
        //     let url = `${pdfFile}#page=${pageNumber}`;
        //     let open = window.open(url, '_blank', 'fullscreen=yes');
        //     if (open == null || typeof(open)=='undefined')
        //         alert(t('misc.popup'));
        // }

    }
    const handleCloseModal = (e) =>{
        setShowModal(false);
    }

    const secondsToHms = (d) => {
        d = Number(d);
        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);

        let hDisplay = h > 0 ? pad(h) : "00";
        let mDisplay = m > 0 ? pad(m) : "00";
        let sDisplay = s > 0 ? pad(s) : "00";
        return hDisplay + ":" + mDisplay + ":" + sDisplay;
    }
    const pad = (n) => {
        return (n < 10) ? ("0" + n) : n;
    }
    const calcMc = (mc, pieces, nt) => {
        let bundlingHeight = (getItem.filters != null) ? parseInt(getItem.filters['bundlingHeight']) : 1;
        let nebenzeit = (parseFloat(nt) * parseFloat(pieces) / 3600);
        let ttime = parseFloat(pieces) * (parseFloat(getItem.time) * bundlingHeight);
        let total = ((parseFloat(nebenzeit)) + parseFloat(ttime) / 60);
        return ((parseFloat(mc)) * (parseFloat(total))) / parseFloat(pieces);
    }
    const calcMc_new = (mc, timeNbr, nt) => {
        return parseFloat(mc) * (parseFloat(timeNbr) + (parseFloat(nt)/3600));
    }

    const calcBc = (bc, pieces) => {
        return (parseFloat(bc)) / (parseFloat(pieces));
    }
    const calcTotal = (mc, bc) => {
        return parseFloat(mc) + parseFloat(bc);
    }
    const calcTotal_new = (mc, bc, st, ps) => {
        return (parseFloat(bc) + (parseFloat(mc) * parseFloat(st))) / parseFloat(ps);
    }

    const calcCostSquare = (item, tc, standzeit) => {
        let square = 0;
        let maxPieces = 0;
        let ret = 0;
        if (item.materialType == "fullMaterial") {
            switch (item.surface) {
                case 'ru':
                    square = ((Math.PI * (Math.pow(parseFloat(item.durchmesser.toString().replace(',', '.')), 2))) / 4) / 1000000;
                    // square = (Math.pow(item.durchmesser/ 2, 2) * Math.PI)/10000;;
                    maxPieces = 1 / parseFloat(square);
                    ret = parseFloat(maxPieces) * parseFloat(tc);
                    break;
                case 'q':
                    square = Math.pow(parseFloat(item.durchmesser.toString().replace(',', '.')), 2) / 1000000;
                    // square = Math.pow(item.durchmesser, 2);
                    maxPieces = 1 / parseFloat(square);
                    ret = parseFloat(maxPieces) * parseFloat(tc);
                    break;
                case 're':
                    square = (parseFloat(item.height.toString().replace(',', '.')) * (item.width.toString().replace(',', '.'))) / 1000000;
                    // square = (item.height / 10) * (item.width / 10);
                    maxPieces = 1 / parseFloat(square);
                    ret = parseFloat(maxPieces) * parseFloat(tc);
                    break;
            }
        }
        if (item.materialType == "profile") {
            square = parseFloat(item.item.surface) / 10000;
            maxPieces = 1 / parseFloat(square);
            ret = parseFloat(maxPieces) * parseFloat(tc);
        }
        return ret;
    }

    const calcCostSquare_new = (mc,bc, st, ps, item) => {
        let square = 0;
        let maxPieces = 0;
        let ret = 0;
        item.durchmesser = item.durchmesser.toString().replace(',', '.');
        item.wallThickness = (item.wallThickness) ? item.wallThickness.toString().replace(',', '.'):item.wallThickness;
        if (item.materialType == "fullMaterial") {
            switch (item.surface) {
                case 'ru':
                    square = (Math.PI * (Math.pow(parseFloat(item.durchmesser), 2))) / 4 ;
                    // ret = parseFloat(square) * parseFloat(st);
                    break;
                case 'q':
                    square = Math.pow(parseFloat(item.durchmesser), 2);
                    // ret = parseFloat(square) * parseFloat(st);
                    break;
                case 're':
                    square = parseFloat(item.height.toString().replace(',', '.'))  * (item.width.toString().replace(',', '.'))  ;
                    // ret = parseFloat(square) * parseFloat(ps);
                    break;
            }
            ret = ((parseFloat(square) * parseFloat(filtersAtt['bundlingHeight']) * parseFloat(filtersAtt['bundlingWidth'])) / 1000000) * parseFloat(ps);
        }
        else if (item.materialType == "profile") {
            if(getItem.surface == "ro") {

                let totalSurface = Math.pow(item.durchmesser/ 2, 2) * Math.PI;
                let outerSurface = Math.pow((item.durchmesser - (item.wallThickness * 2)) / 2, 2) * Math.PI;
                console.log(item)
                console.log(totalSurface)
                console.log(outerSurface)
                square = totalSurface - outerSurface ;
                console.log(square);
                ret = ((parseFloat(square) * parseFloat(filtersAtt['bundlingHeight']) * parseFloat(filtersAtt['bundlingWidth'])) / 100000) * parseFloat(ps);
            }else if(getItem.surface == "vi"){
                let totalSurface = Math.pow(item.durchmesser , 2) ;
                let outerSurface = Math.pow((item.durchmesser  - (item.wallThickness * 2)), 2) ;
                square = totalSurface - outerSurface ;
                ret = ((parseFloat(square) * parseFloat(filtersAtt['bundlingHeight']) * parseFloat(filtersAtt['bundlingWidth'])) / 100000) * parseFloat(ps);
            } else {
                square = parseFloat(item.item.surface);
                ret = (parseFloat(square) * parseFloat(filtersAtt['bundlingHeight']) * parseFloat(filtersAtt['bundlingWidth']) / 10000) * parseFloat(ps);
            }
            // maxPieces = 1 / parseFloat(square);

        }
        // ret = ((parseFloat(square) * parseFloat(filtersAtt['bundlingHeight']) * parseFloat(filtersAtt['bundlingWidth'])) / 1000000) * parseFloat(ps);
        console.log("ret :::"+ret);

        return (parseFloat(bc) + (parseFloat(mc) * parseFloat(st))) / ret;
    }
    const hToPieces = (h, item, n) => {
        let t = (parseFloat(n) / 60) + parseFloat(getItem.time);
        return parseFloat(h) / parseFloat(parseFloat(t) / 60);
    }
    const hToPieces_new = (st, h, nt) => {
        return (parseFloat(st) / parseFloat(h + (parseFloat(nt)/3600)));
    }


    const m2ToPieces = (sm, item) => {
        let square = 0;
        let ret = 0;
        console.log(item);
        console.log(item.materialType);
        console.log(item.surface);

        if (item.materialType == "fullMaterial") {
            switch (item.surface) {
                case 'ru':
                    console.log('ru');
                    square = ((Math.PI * (Math.pow(parseFloat(item.durchmesser.toString().replace(',', '.')), 2))) / 4) / 1000000;
                    ret = parseFloat(sm) / parseFloat(square);
                    break;
                case 'q':
                    square = Math.pow(parseFloat(item.durchmesser.toString().replace(',', '.')), 2)* parseFloat(filtersAtt['bundlingHeight'])*parseFloat(filtersAtt['bundlingWidth']) / 1000000;
                    ret = parseFloat(sm) / parseFloat(square);

                    break;
                case 're':
                    square = (parseFloat(item.height.toString().replace(',', '.')) * parseFloat(filtersAtt['bundlingHeight']) * (item.width.toString().replace(',', '.')) * parseFloat(filtersAtt['bundlingWidth']))  / 1000000;
                    ret = parseFloat(sm) / parseFloat(square);
                    break;

            }
        }
        if (item.materialType == "profile") {
            square = parseFloat(item.item.surface) * parseFloat(filtersAtt['bundlingHeight'])  * parseFloat(filtersAtt['bundlingWidth']) / 10000;
            ret = parseFloat(sm) / parseFloat(square);
        }
        return ret;
    }
    const m2ToPieces_new = (sm, item,u="mm") => {
        let square = 0;
        let ret = 0;
        console.log(item);
        console.log(item.materialType);
        console.log(item.surface);
        let durchmesser = (item.durchmesser)?item.durchmesser.toString().replace(',', '.'):item.durchmesser;
        let height = (item.height)?item.height.toString().replace(',', '.'):item.height;
        let width = (item.width) ? item.width.toString().replace(',', '.'):item.width;
        let wallThickness = (item.wallThickness) ? item.wallThickness.toString().replace(',', '.'):item.wallThickness;
        if(u === "inch"){
            durchmesser = durchmesser * 0.039370078740157
            height = height * 0.039370078740157
            width = width * 0.039370078740157
            wallThickness = wallThickness * 0.039370078740157
        }
        if (item.materialType == "fullMaterial") {
            switch (item.surface) {
                case 'ru':
                    console.log('ru');
                    square = ((Math.PI * (Math.pow(parseFloat(durchmesser), 2))) / 4);
                    // ret = parseFloat(sm) / parseFloat(square);
                    break;
                case 'q':
                    square = Math.pow(parseFloat(durchmesser), 2);
                    // ret = parseFloat(sm) / parseFloat(square);

                    break;
                case 're':
                    square = parseFloat(height)  * parseFloat(width);
                    // ret = parseFloat(sm) / parseFloat(square);
                    break;

            }
            ret = (parseFloat(square) * parseFloat(filtersAtt['bundlingHeight']) * parseFloat(filtersAtt['bundlingWidth']));
            ret = (u === "inch") ? ret : ret/1000000
        }
        else if (item.materialType == "profile") {
            if(getItem.surface == "ro") {
                let totalSurface = Math.pow(durchmesser/ 2, 2) * Math.PI;
                let outerSurface = Math.pow((durchmesser - (wallThickness * 2)) / 2, 2) * Math.PI;
                square = (totalSurface - outerSurface) ;
                ret = (parseFloat(square) * parseFloat(filtersAtt['bundlingHeight']) * parseFloat(filtersAtt['bundlingWidth']));
                ret = (u === "inch") ? ret : ret/100000
            }else if(getItem.surface == "vi"){
                console.log("----- durchmesse -------- "+durchmesser)
                console.log("----- wallThickness -------- "+wallThickness)
                let totalSurface = Math.pow(durchmesser , 2) ;
                let outerSurface = Math.pow((durchmesser  - (wallThickness * 2)), 2) ;
                square = (totalSurface - outerSurface) ;
                ret = (parseFloat(square) * parseFloat(filtersAtt['bundlingHeight']) * parseFloat(filtersAtt['bundlingWidth']));
                ret = (u === "inch") ? ret : ret/100000
            } else {
                square = parseFloat(item.item.surface);
                ret = (parseFloat(square) * parseFloat(filtersAtt['bundlingHeight']) * parseFloat(filtersAtt['bundlingWidth']));
                ret = (u === "inch") ? ret : ret/10000
            }
            // ret = parseFloat(sm) / parseFloat(square);
        }
        // ret = (parseFloat(square) * parseFloat(filtersAtt['bundlingHeight']) * parseFloat(filtersAtt['bundlingWidth']));
        // ret = (u === "inch") ? ret : ret/1000000
        ret = parseFloat(sm) / ret;
        return ret;
    }

    function timeStringToFloat(time) {
        let hoursMinutes = time.split(/[.:]/);
        let hours = parseFloat(hoursMinutes[0]);
        let minutes = hoursMinutes[1] ? parseFloat(hoursMinutes[1])/60 : 0;
        let seconds = hoursMinutes[2] ? parseFloat(hoursMinutes[2])/3600 : 0;
        return hours + minutes + seconds;
    }
    const submitForm_Old = (event) => {
        event.preventDefault();
        setMsg('');
        let target = event.target;
        console.log(target.elements)
        let maschinenkosten = 0;
        let blattkosten = 0;
        let gesamtkosten = 0;
        let kosten = 0;
        let pieces = 0;
        switch (target.elements['einheit'].value) {
            case 'Stk.':
                maschinenkosten = calcMc(target.elements['maschinenkosten'].value, target.elements['standzeit'].value, target.elements['nebenzeit'].value);
                blattkosten = calcBc(target.elements['blattkosten'].value, target.elements['standzeit'].value);
                gesamtkosten = calcTotal(
                    maschinenkosten,
                    blattkosten);
                kosten = calcCostSquare(getItem, gesamtkosten, target.elements['standzeit'].value);
                break;
            case 'm²':
                pieces = m2ToPieces(target.elements['standzeit'].value, getItem);
                console.log("pieces :"+pieces)
                maschinenkosten = calcMc(target.elements['maschinenkosten'].value, pieces, target.elements['nebenzeit'].value);
                console.log("maschinenkosten :"+maschinenkosten)
                blattkosten = calcBc(target.elements['blattkosten'].value, pieces);
                console.log("blattkosten :"+blattkosten)
                gesamtkosten = calcTotal(
                    maschinenkosten,
                    blattkosten);
                console.log("gesamtkosten :"+gesamtkosten)
                kosten = calcCostSquare(getItem, gesamtkosten, target.elements['standzeit'].value);
                console.log("kosten :"+kosten)
                break;
            case 'Inch²':
                let standzeit = parseFloat(target.elements['standzeit'].value/1550);
                pieces = m2ToPieces(standzeit, getItem);
                console.log("pieces :"+pieces)
                maschinenkosten = calcMc(target.elements['maschinenkosten'].value, pieces, target.elements['nebenzeit'].value);
                console.log("maschinenkosten :"+maschinenkosten)
                blattkosten = calcBc(target.elements['blattkosten'].value, pieces);
                console.log("blattkosten :"+blattkosten)
                gesamtkosten = calcTotal(
                    maschinenkosten,
                    blattkosten);
                console.log("gesamtkosten :"+gesamtkosten)
                kosten = calcCostSquare(getItem, gesamtkosten, standzeit);
                console.log("kosten :"+kosten)
                break;
                // Anweisungen werden ausgeführt,
                // falls expression mit valueN übereinstimmt
            case 'h':
                // pieces = hToPieces(target.elements['standzeit'].value, getItem, target.elements['nebenzeit'].value);
                // maschinenkosten = calcMc(target.elements['maschinenkosten'].value, pieces, target.elements['nebenzeit'].value);
                // blattkosten = calcBc(target.elements['blattkosten'].value, pieces);
                // gesamtkosten = calcTotal(maschinenkosten,blattkosten);
                // kosten = calcCostSquare(getItem, gesamtkosten, target.elements['standzeit'].value);
                let schnittzeitNbr =  timeStringToFloat(getItem.speed.schnittzeit);
                console.log("schnittzeitNbr :"+schnittzeitNbr);
                maschinenkosten = calcMc(target.elements['maschinenkosten'].value, schnittzeitNbr, target.elements['nebenzeit'].value);
                console.log("maschinenkosten :" +maschinenkosten);
                pieces = hToPieces_new(target.elements['standzeit'].value, schnittzeitNbr, target.elements['nebenzeit'].value);
                console.log("pieces : "+ pieces);
                blattkosten = calcBc(target.elements['blattkosten'].value, pieces);
                console.log("blattkosten : "+ pieces);
                gesamtkosten = calcTotal_new(target.elements['maschinenkosten'].value, target.elements['blattkosten'].value, target.elements['standzeit'].value, pieces);
                console.log("gesamtkosten : "+ gesamtkosten);
                kosten = calcCostSquare_new(target.elements['maschinenkosten'].value, target.elements['blattkosten'].value, target.elements['standzeit'].value, pieces,getItem);
                console.log("kosten : "+ kosten);
                break;
            case 'Psc.':
                // maschinenkosten = calcMc(target.elements['maschinenkosten'].value, target.elements['standzeit'].value);
                maschinenkosten = calcMc(target.elements['maschinenkosten'].value, target.elements['standzeit'].value, target.elements['nebenzeit'].value);
                blattkosten = calcBc(target.elements['blattkosten'].value, target.elements['standzeit'].value);
                gesamtkosten = calcTotal(
                    target.elements['maschinenkosten'].value,
                    target.elements['blattkosten'].value,
                    target.elements['standzeit'].value,
                    target.elements['nebenzeit'].value);
                kosten = calcCostSquare(getItem, gesamtkosten, target.elements['standzeit'].value);
                break;
            default:
                // Anweisungen werden ausgeführt,
                // falls keine der case-Klauseln mit expression übereinstimmt
                break;
        }
        //  let maschinenkosten = (parseFloat(target.elements['maschinenkosten'].value) * time) / 60;
        //  let blattkosten = (parseFloat(target.elements['blattkosten'].value) / parseFloat(target.elements['standzeit'].value));
        //  let gesamtkosten = maschinenkosten + blattkosten;
        //  let kosten = 0;

        setResult({
            maschinenkosten: maschinenkosten.toFixed(2),
            blattkosten: blattkosten.toFixed(2),
            gesamtkosten: gesamtkosten.toFixed(2),
            kosten: kosten.toFixed(2),
        })
    };
    const submitForm = (event) => {
        event.preventDefault();
        setMsg('');
        let target = event.target;
        console.log(target.elements)
        let maschinenkosten = 0;
        let blattkosten = 0;
        let gesamtkosten = 0;
        let kosten = 0;
        let pieces = 0;
        let schnittzeitNbr = 0;
        let standzeith = 0;
        switch (target.elements['einheit'].value) {
            case 'Stk.':
                schnittzeitNbr =  timeStringToFloat(getItem.speed.schnittzeit);
                console.log("schnittzeitNbr :"+schnittzeitNbr);
                maschinenkosten = calcMc_new(target.elements['maschinenkosten'].value, schnittzeitNbr, target.elements['nebenzeit'].value);
                console.log("maschinenkosten :" +maschinenkosten);
                pieces = target.elements['standzeit'].value;
                console.log("pieces : "+ pieces);
                blattkosten = calcBc(target.elements['blattkosten'].value, pieces);
                console.log("blattkosten : "+ pieces);
                standzeith = parseFloat(pieces) * (schnittzeitNbr + (parseFloat(target.elements['nebenzeit'].value)/3600));
                gesamtkosten = calcTotal_new(target.elements['maschinenkosten'].value, target.elements['blattkosten'].value, standzeith, pieces);
                console.log("gesamtkosten : "+ gesamtkosten);
                kosten = calcCostSquare_new(target.elements['maschinenkosten'].value, target.elements['blattkosten'].value, standzeith, pieces,getItem);
                console.log("kosten : "+ kosten);
                break;
            case 'm²':
                schnittzeitNbr =  timeStringToFloat(getItem.speed.schnittzeit);
                console.log("schnittzeitNbr :"+schnittzeitNbr);
                maschinenkosten = calcMc_new(target.elements['maschinenkosten'].value, schnittzeitNbr, target.elements['nebenzeit'].value);
                console.log("maschinenkosten :"+maschinenkosten)
                pieces = m2ToPieces_new(target.elements['standzeit'].value, getItem);
                console.log("pieces :"+pieces)
                blattkosten = calcBc(target.elements['blattkosten'].value, pieces);
                console.log("blattkosten :"+blattkosten)
                standzeith = parseFloat(pieces) * (schnittzeitNbr + (parseFloat(target.elements['nebenzeit'].value)/3600));
                gesamtkosten = calcTotal_new(target.elements['maschinenkosten'].value, target.elements['blattkosten'].value, standzeith, pieces);
                console.log("gesamtkosten : "+ gesamtkosten);
                kosten = (parseFloat(target.elements['blattkosten'].value) + (parseFloat(target.elements['maschinenkosten'].value) * parseFloat(standzeith))) / parseFloat(target.elements['standzeit'].value);
                // kosten = calcCostSquare_new(target.elements['maschinenkosten'].value, target.elements['blattkosten'].value, standzeith, pieces,getItem);
                console.log("kosten : "+ kosten);
                break;
            case 'Inch²':
                schnittzeitNbr =  timeStringToFloat(getItem.speed.schnittzeit);
                console.log("schnittzeitNbr :"+schnittzeitNbr);
                maschinenkosten = calcMc_new(target.elements['maschinenkosten'].value, schnittzeitNbr, target.elements['nebenzeit'].value);
                console.log("maschinenkosten :"+maschinenkosten)
                let standzeitt = parseFloat(target.elements['standzeit'].value);
                pieces = m2ToPieces_new(standzeitt, getItem, "inch");
                console.log("pieces :"+pieces)
                blattkosten = calcBc(target.elements['blattkosten'].value, pieces);
                console.log("blattkosten :"+blattkosten)
                standzeith = parseFloat(pieces) * (schnittzeitNbr + (parseFloat(target.elements['nebenzeit'].value)/3600));
                gesamtkosten = calcTotal_new(target.elements['maschinenkosten'].value, target.elements['blattkosten'].value, standzeith, pieces);
                console.log("gesamtkosten : "+ gesamtkosten);
                // kosten = calcCostSquare_new(target.elements['maschinenkosten'].value, target.elements['blattkosten'].value, standzeith, pieces,getItem);
                kosten = (parseFloat(target.elements['blattkosten'].value) + (parseFloat(target.elements['maschinenkosten'].value) * parseFloat(standzeith))) / parseFloat(target.elements['standzeit'].value);
                console.log("kosten : "+ kosten);
                break;
            // Anweisungen werden ausgeführt,
            // falls expression mit valueN übereinstimmt
            case 'h':
                schnittzeitNbr =  timeStringToFloat(getItem.speed.schnittzeit);
                console.log("schnittzeitNbr :"+schnittzeitNbr);
                maschinenkosten = calcMc_new(target.elements['maschinenkosten'].value, schnittzeitNbr, target.elements['nebenzeit'].value);
                console.log("maschinenkosten :" +maschinenkosten);
                pieces = hToPieces_new(target.elements['standzeit'].value, schnittzeitNbr, target.elements['nebenzeit'].value);
                console.log("pieces : "+ pieces);
                blattkosten = calcBc(target.elements['blattkosten'].value, pieces);
                console.log("blattkosten : "+ pieces);
                gesamtkosten = calcTotal_new(target.elements['maschinenkosten'].value, target.elements['blattkosten'].value, target.elements['standzeit'].value, pieces);
                console.log("gesamtkosten : "+ gesamtkosten);
                kosten = calcCostSquare_new(target.elements['maschinenkosten'].value, target.elements['blattkosten'].value, target.elements['standzeit'].value, pieces,getItem);
                console.log("kosten : "+ kosten);
                break;
            case 'Psc.':
                // maschinenkosten = calcMc(target.elements['maschinenkosten'].value, target.elements['standzeit'].value);
                maschinenkosten = calcMc(target.elements['maschinenkosten'].value, target.elements['standzeit'].value, target.elements['nebenzeit'].value);
                blattkosten = calcBc(target.elements['blattkosten'].value, target.elements['standzeit'].value);
                gesamtkosten = calcTotal(
                    target.elements['maschinenkosten'].value,
                    target.elements['blattkosten'].value,
                    target.elements['standzeit'].value,
                    target.elements['nebenzeit'].value);
                kosten = calcCostSquare(getItem, gesamtkosten, target.elements['standzeit'].value);
                break;
            default:
                // Anweisungen werden ausgeführt,
                // falls keine der case-Klauseln mit expression übereinstimmt
                break;
        }
        //  let maschinenkosten = (parseFloat(target.elements['maschinenkosten'].value) * time) / 60;
        //  let blattkosten = (parseFloat(target.elements['blattkosten'].value) / parseFloat(target.elements['standzeit'].value));
        //  let gesamtkosten = maschinenkosten + blattkosten;
        //  let kosten = 0;

        setResult({
            maschinenkosten: maschinenkosten.toFixed(2),
            blattkosten: blattkosten.toFixed(2),
            gesamtkosten: gesamtkosten.toFixed(2),
            kosten: kosten.toFixed(2),
        })
        console.log(getItem);
        // setCosts(arr => [...arr[getItem.key],{
        //     maschinenkostenInput: target.elements['maschinenkosten'].value,
        //     bladecost: target.elements['blattkosten'].value,
        //     standzeit: target.elements['standzeit'].value,
        //     nebenzeit: target.elements['nebenzeit'].value,
        //     einheit : target.elements['einheit'].value,
        //     maschinenkosten: maschinenkosten.toFixed(2),
        //     blattkosten: blattkosten.toFixed(2),
        //     gesamtkosten: gesamtkosten.toFixed(2),
        //     kosten: kosten.toFixed(2),
        // }])
        let cs = [...costs];
        let item = {...cs[getItem.key]};
        item = {
            maschinenkostenInput: target.elements['maschinenkosten'].value,
            bladecost: target.elements['blattkosten'].value,
            standzeit: target.elements['standzeit'].value,
            nebenzeit: target.elements['nebenzeit'].value,
            einheit : target.elements['einheit'].value,
            maschinenkosten: maschinenkosten.toFixed(2),
            blattkosten: blattkosten.toFixed(2),
            gesamtkosten: gesamtkosten.toFixed(2),
            kosten: kosten.toFixed(2),
        }
        cs[getItem.key] = item;
        setCosts(cs);
    };
    const detectMob = () => {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }
    const resultPrint = (e) => {
        e.preventDefault();
        setActiveTab('ergebnis');
        // setTitleTab('misc.resulttab');
        setTitleTab('misc.edata');
        document.getElementById('cost').setAttribute("tabClassName","hidden")
        document.getElementById('cost').classList.add("hidden");
        setTimeout(()=>{
            document.getElementById('print-clicked').click();
            if(detectMob){
                setTimeout(()=>{
                    setActiveTab('Kosten');
                    setTitleTab('misc.costtab');
                },200)
            }

            // document.getElementById('print-clicked').addEventListener("mouseup", function(e) {
            //     setActiveTab('Kosten');
            //     setTitleTab('misc.costtab');
            //     document.getElementById('print-clicked').removeEventListener("mouseup",()=>console.log("removeEventListener"));
            // });


            // setTimeout(()=>{
            //     document.getElementById('cost').classList.remove("hidden")
            //     document.getElementById('cost').removeAttribute("tabClassName")
            // },500)


        },(detectMob()?500:200))

        //window.print();
        return false;
    }
    const changeValueClickHandel = (event) => {
        event.preventDefault();
        let target = event.target;
        console.log(target.elements);
        let obj = {};
        for (let i = 0; i < target.elements.length; i++) {
            if( target.elements[i].getAttribute("name") != null) {
                obj[target.elements[i].getAttribute("name")] = target.elements[i].value;
                // speed[target.elements[i].getAttribute("name")] = target.elements[i].value;
            }
        }
        console.log(obj);
        console.log(getItem);
    }


    return (
        (state.redirect) ?
            <Redirect to={state.redirect}/>
            :
            <div>
                <img src={logo} alt="Arntz" className="img-fluid nav-height  a-logo w-25 m-auto"/>
                {/*<h3>{t('master.result2')}</h3>*/}
                {loading &&
                <CircularProgress
                    size={50}
                    thickness={3}
                    color="primary"
                />
                }
                {/*{data && speed && item &&*/}
                <div>
                    {
                        data !== undefined &&
                        <div>
                            <Tabs activeKey={activeTab}>
                                <Tab eventKey="ergebnis" title={t(titleTab)}   className="mc" tabClassName="tab-background-color">
                                    <div className="row w-100">
                                        <div className="col">
                                            <Link
                                                className="pl-2 pr-2 mb-2 btn btn-primary float-left"
                                                color="primary" to={{
                                                pathname: '/master_calc',
                                                classes: 'btn btn-primary',
                                                aboutProps: {
                                                    res,
                                                    tab:'v-pills-settings'
                                                },

                                            }}>
                                                {t('misc.back')}
                                            </Link>
                                        </div>
                                    </div>
                                    {data.map((d, k) => {
                                        let speed = d.speed;
                                        let item = d.item;
                                        console.log(d);
                                        console.log(k + 1);
                                        return <div key={k + 1} className="item-div">
                                            <div className="row mb-4">
                                                <div className="col-md-6">
                                                    <fieldset className="border p-2 h-100">
                                                        {/*<legend className="w-auto">{t('misc.product')}</legend>*/}
                                                        <legend className="w-auto">{(item.category== null ?  t("misc.biMetal")+" " :item.category.id == "3" ? t("misc.hardMetal")+" " : item.category.id == "1" ?t("misc.professional") +" "+t("misc.biMetal")+" " : t("misc.highPerformance")+" "+t("misc.biMetal")+" " ) + t('misc.SawBand') }</legend>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('master.agroup')}:</strong>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        {item.product.name}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('misc.description')}:</strong>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        {item.product.description}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('misc.mes')}:</strong>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        {speed.measure}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('misc.tooth')}:</strong>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        {item.gearing.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*{item.materialGroup && item.materialGroup.name !== '' &&*/}
                                                            {/*    <div className="col-12">*/}
                                                            {/*        <div className="row mb-2">*/}
                                                            {/*            <div className="col-5">*/}
                                                            {/*                <strong>{t('misc.mgroup')}:</strong>*/}
                                                            {/*            </div>*/}
                                                            {/*            <div className="col-4">*/}
                                                            {/*                {item.materialGroup.description}*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </div>*/}
                                                            {/*}*/}
                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('master.manu')}:</strong>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        {filtersAtt['manufacturer']}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('master.sawtype')}:</strong>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        {filtersAtt['manufacturerTyp']}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('misc.wname')}:</strong>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        {i18n.language.includes("de") ? filtersAtt['wname']:filtersAtt['wname_en']}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('misc.din')}</strong>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        {filtersAtt['din']}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('master.wnumber')}:</strong>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        {filtersAtt['wnumber']}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('master.wtype')}:</strong>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        {getItem.materialType == "fullMaterial" ? t('master.full'):t('master.profile')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('master.form')}:</strong>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        {t(getItem.typ[getItem.surface])}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('misc.dimensions')}:</strong>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        {
                                                                            unitConverter()+" ("+ (dimensionUnit.includes('*') ? t(dimensionUnit.split('*')[0]) +"*"+ t(dimensionUnit.split('*')[1]) : t(dimensionUnit)) +")"
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('misc.bundling')}:</strong>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        {filtersAtt['bundlingWidth']+'*'+filtersAtt['bundlingHeight']}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            { item.category != null && item.category.id == "3" &&
                                                                <div className="col-12">
                                                                    <div className="row mb-2">
                                                                        <div className="col-10 hm-info">
                                                                            {t('misc.hmInfo')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </fieldset>
                                                </div>
                                                <div className="col-md-6">
                                                    <fieldset className="border p-2  h-100">
                                                        <legend className="w-auto">{t('misc.edata')}</legend>
                                                        <div className="row">

                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('misc.cutspeed')}:</strong>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        { (unit ==='meter-cm') ?
                                                                            Math.round(parseFloat(speed.speed)) +'  m/min' :
                                                                            Math.round(parseFloat(speed.speed) * 3.2808398950131) +'  FPM'}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('misc.vspeed')}:</strong>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        { (unit ==='meter-cm') ?
                                                                            parseFloat(speed.vorschubgeschwindigkeit).toFixed(1) + '  mm/min' :
                                                                            Math.round((parseFloat(speed.vorschubgeschwindigkeit) * 0.039370078740157 + Number.EPSILON) * 1000) / 1000+'  inch/min'
                                                                            // Number(parseFloat(speed.vorschubgeschwindigkeit) * 0.039370078740157).toFixed(3) +'  inch/min'
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('misc.speedtooth')}:</strong>{" "}
                                                                    </div>
                                                                    <div className="col-4">
                                                                        { (unit ==='meter-cm') ?
                                                                            Math.round((parseFloat(speed['feed']) + Number.EPSILON) * 1000) / 1000 + '  mm' :
                                                                            // Number(parseFloat(speed['feed']).toFixed(3)) + '  mm' :
                                                                            Math.round((parseFloat(speed['feed']) * 0.039370078740157 + Number.EPSILON) * 100000) / 100000+'  in'
                                                                            // Number(parseFloat(speed['feed']) * 0.039370078740157).toFixed(5) +'  in'
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('misc.cuttime')}:</strong>{" "}
                                                                    </div>
                                                                    <div className="col-4">
                                                                        {speed.schnittzeit}{'  hh:mm:ss'}
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="col-12">
                                                                <div className="row mb-2">
                                                                    <div className="col-5">
                                                                        <strong>{t('misc.rate')}:</strong>{" "}
                                                                    </div>
                                                                    <div className="col-4">
                                                                        { (unit ==='meter-cm') ?
                                                                            Math.round(speed.Zerspanungsrate) + '  cm²/min' :
                                                                            // Number(parseFloat(speed.Zerspanungsrate) * 0.15500031000062).toFixed(2) +'  in²'
                                                                            Math.round((parseFloat(speed.Zerspanungsrate) * 0.15500031000062 + Number.EPSILON) * 100) / 100+'  sqin'
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {costs.length > 0 && costs[k] !== undefined &&
                                                                <div className="col-12 mt-2">
                                                                    <h2>{t('misc.costtab')}</h2>
                                                                    <div className="row">
                                                                        <div className="col-5"><strong>{t('misc.mcosts')} ({currency} /{t('misc.hour')}):</strong>{" "}</div>
                                                                        <div className="col-4">{costs[k].maschinenkostenInput}</div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-5"><strong>{t('misc.stime')}:</strong>{" "}</div>
                                                                        <div className="col-4">{costs[k].standzeit}</div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-5"><strong>{t('misc.unit')}:</strong>{" "}</div>
                                                                        {/*<div className="col-4">{costs[k].einheit}</div>*/}
                                                                        <div className="col-4">{ costs[k].einheit == "h"? t('misc.hours'): costs[k].einheit == "Stk." ? t('misc.psc') : costs[k].einheit }</div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-5"><strong>{t('misc.ntime')}:</strong>{" "}</div>
                                                                        <div className="col-4">{costs[k].nebenzeit}</div>
                                                                    </div>
                                                                    <div className="row mb-2">
                                                                        <div className="col-5"><strong>{t('misc.bladecost')} ({currency} /{t('misc.blade')}):</strong>{" "}</div>
                                                                        <div className="col-4">{costs[k].bladecost}</div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-5"><strong>{t('misc.mcosts')}/{t('misc.cut')}:</strong>{" "}</div>
                                                                        <div className="col-4">{costs[k].maschinenkosten}{' '}{currency}</div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-5"><strong>{t('misc.bladecost')}/{t('misc.cut')}:</strong>{" "}</div>
                                                                        <div className="col-4">{costs[k].blattkosten}{' '}{currency}</div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-5"><strong>{t('misc.total')}/{t('misc.cut')}:</strong>{" "}</div>
                                                                        <div className="col-4">{costs[k].gesamtkosten}{' '}{currency}</div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-5"><strong>{t('misc.cost')}/m²:</strong>{" "}</div>
                                                                        <div className="col-4">{costs[k].kosten}{' '}{currency}</div>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>
                                                    </fieldset>
                                                </div>
                                                <div className="col-md-12 dd d-flex flex-row-reverse">
                                                    {/*<a  href={"" + ((item.category.id=="3") ? "20":"10")} target={'_blank'}*/}
                                                    {/*<input type="text" name="articleNum" value=""*/}
                                                    {/*       onChange={(e) => {*/}
                                                    {/*           console.log("value ::::::::::::::"+"Artikelgruppe "+item.product.name);*/}
                                                    {/*           console.log('searchString----------'+searchString);*/}
                                                    {/*           console.log(searchResults);*/}
                                                    {/*           let url = `${pdfFile}#page=${searchResults[0]}`;*/}
                                                    {/*           console.log("url :"+url);*/}
                                                    {/*           window.open(url, '_blank', 'fullscreen=yes');*/}
                                                    {/*       }}*/}
                                                    {/*       onFocus={(e) => {*/}
                                                    {/*           console.log("value ::::::::::::::"+"Artikelgruppe "+item.product.name);*/}
                                                    {/*           console.log('searchString----------'+searchString);*/}
                                                    {/*           console.log(searchResults);*/}
                                                    {/*           let url = `${pdfFile}#page=${searchResults[0]}`;*/}
                                                    {/*           console.log("url :"+url);*/}
                                                    {/*           window.open(url, '_blank', 'fullscreen=yes');*/}
                                                    {/*       }}*/}
                                                    {/*/>*/}
                                                    {/*<a  href="#" target={'_blank'} id={item.product.t("misc.articleGroup")+" "+item.product.name}*/}
                                                    <a  href="#" target={'_blank'} id={ i18n.language === "de" ? item.product.pageNumberDe:item.product.pageNumberEn}
                                                        className="pl-1 pr-1  btn btn-primary"
                                                        onClick={pdfOnClickHandle}
                                                        // onClick={(e) => {
                                                        //     setSearchString("Artikelgruppe "+item.product.name);
                                                        //     e.target.closest(".dd").querySelector("input").value ="Artikelgruppe "+item.product.name;
                                                        //     e.target.closest(".dd").querySelector("input").focus();
                                                        // }}
                                                    >
                                                        {t('misc.catalog')}
                                                    </a>
                                                    <button type="button" className="pl-1 pr-1 mr-2 btn btn-primary"
                                                            data-toggle="modal" data-target={"#changeValuesModal"+k + 1}
                                                            data-whatever="@mdo" onClick={(e)=>{
                                                                setValues({
                                                                    'speed':d.speed.speed,
                                                                    'feed':d.speed.feed,
                                                                    'vorschubgeschwindigkeit':d.speed.vorschubgeschwindigkeit
                                                                });
                                                            }}>{t('misc.changeValues')}
                                                    </button>
                                                    {/*<Link*/}
                                                    {/*    className="pl-1 pr-1 mr-2 btn btn-primary float-right"*/}
                                                    {/*    color="primary" to={{*/}
                                                    {/*    pathname: '/master_calc',*/}
                                                    {/*    classes: 'btn btn-primary',*/}
                                                    {/*    aboutProps: {*/}
                                                    {/*        res,*/}
                                                    {/*        tab:'v-pills-home'*/}
                                                    {/*    }*/}

                                                    {/*}}>*/}
                                                    {/*    {t('misc.changeValues')}*/}
                                                    {/*</Link>*/}
                                                    <button onClick={(e) => {
                                                        e.preventDefault();
                                                        let elements=document.getElementsByClassName("print-result")
                                                        for(let ele of elements){
                                                            ele.removeAttribute("id")
                                                        }
                                                        // e.currentTarget.nextSibling.removeAttribute('print-clicked');
                                                        console.log(e.currentTarget)
                                                        console.log(e.currentTarget.nextSibling)
                                                        e.currentTarget.nextSibling.id = "print-clicked";

                                                        let getItem = {
                                                            item: d.item,
                                                            speed: d.speed,
                                                            key:k,
                                                            durchmesser: (res.durchmesser) ?res.durchmesser.toString().replace(',', '.'):res.durchmesser,
                                                            measure: res.measure,
                                                            height: (res.height)? res.height.toString().replace(',', '.'):res.height,
                                                            width: (res.width) ? res.width.toString().replace(',', '.'):res.width,
                                                            surface: res.surface,
                                                            materialType: res.materialType,
                                                            filters: res.filters,
                                                            wallThickness: (res.wallThickness) ? res.wallThickness.toString().replace(',', '.'):res.wallThickness,
                                                            time:d.time,
                                                            typ :{
                                                                'ru':t('master.round2'),
                                                                'q':t('master.square'),
                                                                're':t('master.tangle'),
                                                                'hem':'HE-M',
                                                                'heb':'HE-B',
                                                                'hea':'HE-A',
                                                                'ip':'I-PE',
                                                                'vi': t('master.four'),
                                                                'ro': t('master.round'),
                                                                'ls': t('master.lsteel'),
                                                                'us': t('master.usteel'),
                                                            }
                                                        };
                                                        console.log(getItem)
                                                        setGetItem(getItem);
                                                        setInputs({});
                                                        setResult(null);
                                                        setActiveTab('Kosten');
                                                        document.getElementById('cost').classList.remove("hidden")
                                                        document.getElementById('cost').removeAttribute("tabClassName")

                                                        setTitleTab('misc.costtab');
                                                        setInputs({
                                                            maschinenkosten: (costs.length>0 && costs[k]!==undefined )?costs[k].maschinenkostenInput:"",
                                                            standzeit: (costs.length>0 && costs[k]!==undefined )?costs[k].standzeit:"",
                                                            nebenzeit: (costs.length>0 && costs[k]!==undefined )?costs[k].nebenzeit:"",
                                                            blattkosten: (costs.length>0 && costs[k]!==undefined )?costs[k].bladecost:"",
                                                        })
                                                        setLocalUnit((costs.length>0 && costs[k]!==undefined )? costs[k].einheit:"")


                                                    }
                                                    }
                                                            className="pl-1 pr-1 mr-2 btn btn-primary">
                                                        {t('misc.costs')}
                                                    </button>
                                                    <Link  onClick={(e)=>{
                                                                e.preventDefault();
                                                                e.target.closest(".item-div").classList.add("active")
                                                                e.target.closest(".tab-pane").querySelectorAll("div.item-div:not(.active)")
                                                                .forEach((it,i,array)=>{
                                                                    it.style.display="none";
                                                                })
                                                                window.print();
                                                                e.target.closest(".tab-pane").querySelectorAll("div.item-div:not(.active)")
                                                                    .forEach((it)=>{
                                                                        it.style.display="block";
                                                                    })

                                                                e.target.closest(".item-div").classList.remove("active");
                                                                return false;
                                                            }}
                                                          to={"#"}
                                                          className="pl-1 pr-1 mr-2 btn btn-primary print-result"
                                                    >
                                                        {t('misc.print')}
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="modal fade" id={"changeValuesModal"+k + 1} tabIndex="-1" role="dialog" aria-labelledby={"changeValuesModalLabel"+k + 1} aria-hidden="true">
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">
                                                        <form onSubmit={changeValueClickHandel}>
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">{t('misc.edata')}</h5>
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="form-group">
                                                                    <label htmlFor="cutspeed" className="col-form-label">{t('misc.cutspeed') +" ("+((unit ==='meter-cm')? "m/mm" :"FRM")+")"}:</label>
                                                                    <input type="number" step={'0.01'} className="form-control wlb" name="speed"
                                                                           // value={values.speed}
                                                                           // value={(unit === "meter-cm") ? parseFloat(values.speed) : parseFloat(values.speed) * 3.2808398950131 }
                                                                           value={(unit === "meter-cm") ? Math.round((parseFloat(values.speed) + Number.EPSILON) * 100)/ 100
                                                                               :  Math.round((parseFloat(values.speed) * 3.2808398950131 + Number.EPSILON) * 100)/ 100 }
                                                                           onChange={(e)=>{
                                                                               console.log("********************")
                                                                               console.log("speed :"+speed.speed)
                                                                               console.log("feed :"+speed.feed)
                                                                               console.log("vorschubgeschwindigkeit :"+speed.vorschubgeschwindigkeit)
                                                                               console.log("tpm :"+speed.tpm)

                                                                               // speed.speed = e.target.value
                                                                               speed.speed = (unit === "meter-cm") ? parseFloat(e.target.value) : parseFloat(e.target.value) / 3.2808398950131

                                                                               speed.vorschubgeschwindigkeit = (Math.round(speed.speed) * speed.tpm * speed.feed).toFixed(1);
                                                                               console.log("vorschubgeschwindigkeit :"+speed.vorschubgeschwindigkeit)
                                                                               let time = (parseInt(speed.durchmesser) / speed.vorschubgeschwindigkeit).toFixed(2);
                                                                               let bundlingHeight = parseInt(filtersAtt['bundlingHeight']);
                                                                               speed.schnittzeit = secondsToHms(Math.round(time * bundlingHeight * 60))
                                                                               speed.Zerspanungsrate = Math.round(speed.surface / (time * bundlingHeight));
                                                                               setTime(time);
                                                                               d.time= time;
                                                                               setValues({
                                                                                   ...values,
                                                                                   'speed':speed.speed,
                                                                                   'vorschubgeschwindigkeit':speed.vorschubgeschwindigkeit
                                                                               });
                                                                           }}
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="vspeed" className="col-form-label">{t('misc.vspeed') +" ("+((unit ==='meter-cm')? "mm/min" :"inch/min")+")"}:</label>
                                                                    <input type="number" step={'0.01'} className="form-control wlb" name="vorschubgeschwindigkeit"
                                                                           // value={parseFloat(values.vorschubgeschwindigkeit).toFixed(4)}
                                                                           //  value={(unit === "meter-cm") ? parseFloat(values.vorschubgeschwindigkeit) : parseFloat(values.vorschubgeschwindigkeit) * 0.039370078740157 }
                                                                           value={(unit === "meter-cm") ? Math.round((parseFloat(values.vorschubgeschwindigkeit) + Number.EPSILON) * 100)/ 100
                                                                               :  Math.round((parseFloat(values.vorschubgeschwindigkeit) * 0.39370078740157 + Number.EPSILON) * 100)/ 100 }
                                                                            onChange={(e)=>{
                                                                                // speed.vorschubgeschwindigkeit = parseFloat(e.target.value);
                                                                                speed.vorschubgeschwindigkeit = (unit === "meter-cm") ? parseFloat(e.target.value) : parseFloat(e.target.value) / 0.039370078740157
                                                                                let time = (parseInt(speed.durchmesser) / speed.vorschubgeschwindigkeit).toFixed(2);
                                                                                let bundlingHeight = parseInt(filtersAtt['bundlingHeight']);
                                                                                speed.schnittzeit = secondsToHms(Math.round(time * bundlingHeight * 60))
                                                                                speed.Zerspanungsrate = Math.round(speed.surface / (time * bundlingHeight));
                                                                                speed.feed = speed.vorschubgeschwindigkeit / (Math.round(speed.speed) * speed.tpm)
                                                                                setTime(time);
                                                                                d.time= time;
                                                                                setValues({
                                                                                    ...values,
                                                                                    // 'vorschubgeschwindigkeit':e.target.value,
                                                                                    'vorschubgeschwindigkeit':speed.vorschubgeschwindigkeit,
                                                                                    'feed':(speed.feed).toFixed(4)
                                                                                });
                                                                        }}/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="speedtooth" className="col-form-label">{t('misc.speedtooth') +" ("+((unit ==='meter-cm')? "mm" :"in")+")"}:</label>
                                                                    <input type="number" step={'0.001'}  className="form-control wlb" name="feed"
                                                                           // value={parseFloat(values.feed).toFixed(4)}
                                                                           // value={(unit === "meter-cm") ? parseFloat(values.feed) : parseFloat(values.feed) * 0.039370078740157 }
                                                                           // value={(unit === "meter-cm") ? Math.round((parseFloat(values.feed) + Number.EPSILON) * 1000)/ 1000
                                                                           //     :  Math.round((parseFloat(values.feed) * 0.39370078740157 + Number.EPSILON) * 1000)/ 1000 }
                                                                           value={(unit === "meter-cm") ? parseFloat(values.feed) : parseFloat(values.feed) * 0.039370078740157}
                                                                           onChange={(e)=>{
                                                                               speed.feed = (unit === "meter-cm") ? parseFloat(e.target.value) : parseFloat(e.target.value) / 0.039370078740157
                                                                               // speed.vorschubgeschwindigkeit = parseFloat(speed.speed) * parseFloat(speed.feed);
                                                                               speed.vorschubgeschwindigkeit = parseFloat(speed.speed) * speed.tpm * parseFloat(speed.feed);
                                                                               speed.vorschubgeschwindigkeit = speed.vorschubgeschwindigkeit.toFixed(1);
                                                                               let time = (parseInt(speed.durchmesser) / speed.vorschubgeschwindigkeit).toFixed(2);
                                                                               let bundlingHeight = parseInt(filtersAtt['bundlingHeight']);
                                                                               speed.schnittzeit = secondsToHms(Math.round(time * bundlingHeight * 60))
                                                                               speed.Zerspanungsrate = Math.round(speed.surface / (time * bundlingHeight));
                                                                               setTime(time);
                                                                               d.time= time;
                                                                               setValues({
                                                                                   ...values,
                                                                                   'feed': speed.feed,
                                                                                   'vorschubgeschwindigkeit':speed.vorschubgeschwindigkeit
                                                                               });
                                                                           }}/>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="submit" className="pl-2 pr-2 mr-2 ml-2 btn btn-primary float-right"  data-dismiss="modal"
                                                                        /*onSubmit={(e)=>{
                                                                            speed.speed = values.speed
                                                                            speed.feed = values.feed
                                                                            speed.vorschubgeschwindigkeit = values.vorschubgeschwindigkeit;
                                                                            return false;
                                                                        }}*/
                                                                >{t('misc.accept')}</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                    <div className="item-div mb-4 text-danger active">
                                        <strong>{t('misc.info')}</strong>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-md-12">
                                            <Link onClick={(e)=>{
                                                e.preventDefault();
                                                window.print();
                                                return false;
                                            }} to={"#"}
                                                  className="pl-2 pr-2 mb-2 btn btn-primary float-right">
                                                {t('misc.printAll')}
                                            </Link>
                                            <div className="w-100">
                                                <Link
                                                    className="pl-2 pr-2 mb-2 btn btn-primary float-left"
                                                    color="primary" to={{
                                                    pathname: '/master_calc',
                                                    classes: 'btn btn-primary',
                                                    aboutProps: {
                                                        res,
                                                        tab:'v-pills-settings'
                                                    },

                                                }}>
                                                    {t('misc.back')}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Kosten" className="mc" title={t(titleTab)}  tabClassName={'hidden'} id={"cost"} >
                                    <div>
                                        <div className="row mb-4">
                                            <div className="col-sm">
                                                <div className="col-md-12">
                                                    <button onClick={(e) => {
                                                        e.preventDefault()
                                                        setActiveTab('ergebnis');
                                                        // setTitleTab('misc.resulttab');
                                                        setTitleTab('misc.edata');
                                                        document.getElementById('cost').setAttribute("tabClassName","hidden")
                                                        document.getElementById('cost').classList.add("hidden")
                                                    }}
                                                            className="pl-2 pr-2 mb-2 btn btn-primary">
                                                        {/*{t('misc.resultBack')}*/}
                                                        {t('misc.resultToData')}
                                                    </button>
                                                </div>
                                                <fieldset className="border p-2 mt-3">
                                                    <legend className="w-auto">{t('misc.input')}</legend>
                                                    <form onSubmit={submitForm} id='productForm' name='productForm'
                                                          className="mb-3">
                                                        <div className="form-check">
                                                            <label htmlFor="maschinenkosten">{t('misc.mcosts')} ({currency} /{t('misc.hour')})</label>
                                                            <input type="number"
                                                                   className="form-control wlb"
                                                                   id="maschinenkosten"
                                                                   name='maschinenkosten'
                                                                   placeholder={t('misc.mcosts')}
                                                                   value={inputs.maschinenkosten}
                                                                   step="0.01"
                                                                   onChange={(e) => {
                                                                       setInputs({
                                                                           maschinenkosten: e.target.value,
                                                                           standzeit: inputs.standzeit,
                                                                           nebenzeit: inputs.nebenzeit,
                                                                           blattkosten: inputs.blattkosten,
                                                                       })
                                                                   }}
                                                            />

                                                        </div>
                                                        <div className="form-check">
                                                            <label htmlFor="standzeit">{t('misc.stime')}</label>
                                                            <div className="row">
                                                                <div className='col-sm-6'>
                                                                    <input type="number"
                                                                           className="form-control wlb"
                                                                           id="standzeit"
                                                                           name='standzeit'
                                                                           placeholder={t('misc.stime')}
                                                                           value={inputs.standzeit}
                                                                           step={localUnit == "Stk." ?"0":"0.0001"}
                                                                           onChange={(e) => {
                                                                               setInputs({
                                                                                   maschinenkosten: inputs.maschinenkosten,
                                                                                   standzeit: e.target.value,
                                                                                   nebenzeit: inputs.nebenzeit,
                                                                                   blattkosten: inputs.blattkosten,
                                                                               })
                                                                           }}
                                                                    />
                                                                </div>
                                                                <div className='col-sm-6'>
                                                                    <select className="form-control wlb"
                                                                            id="einheit"
                                                                            name="einheit"
                                                                            placeholder={t('misc.unit')}
                                                                            onChange={function (e){
                                                                                setLocalUnit(e.target.value);
                                                                                setInputs({
                                                                                    ...inputs,
                                                                                    standzeit :(inputs.standzeit != undefined) ? parseInt(inputs.standzeit):0
                                                                                })
                                                                            }}
                                                                    >
                                                                        <option key='1' value='' selected={localUnit == ""}>{t('misc.selectUnit')}</option>
                                                                        <option key='3' value='m²' selected={localUnit == "m²"}>m²</option>
                                                                        <option key='4' value='h' selected={localUnit == "h" }>{t('misc.hours')}</option>
                                                                        <option key='5' value='Stk.' selected={localUnit == "Stk." }>{t('misc.psc')}</option>
                                                                        <option key='6' value='Inch²' selected={localUnit == "Inch²" }>Inch²</option>
                                                                        {/*<option key='7' value='Psc.'>Psc.</option>*/}
                                                                    </select>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className="form-check">
                                                            <label htmlFor="nebenzeit">{t('misc.ntime')}</label>
                                                            <input type="number"
                                                                   className="form-control wlb"
                                                                   id="nebenzeit"
                                                                   name='nebenzeit'
                                                                   placeholder={t('misc.ntime')}
                                                                   value={inputs.nebenzeit}
                                                                   step="0.01"
                                                                   onChange={(e) => {
                                                                       setInputs({
                                                                           maschinenkosten: inputs.maschinenkosten,
                                                                           standzeit: inputs.standzeit,
                                                                           nebenzeit: e.target.value,
                                                                           blattkosten: inputs.blattkosten,
                                                                       })
                                                                   }}
                                                            />
                                                        </div>
                                                        <div className="form-check">
                                                            <label htmlFor="blattkosten">{t('misc.bladecost')} ({currency}/{t('misc.blade')})</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <label
                                                                htmlFor="blattkosten">{getItem.item.product.description}</label>
                                                            <input type="number"
                                                                   className="form-control wlb"
                                                                   id="blattkosten"
                                                                   name='blattkosten'
                                                                   placeholder={t('misc.bladecost')}
                                                                   value={inputs.blattkosten}
                                                                   // value={inputs.machineCost}
                                                                   step="0.01"
                                                                   onChange={(e) => {
                                                                       setInputs({
                                                                           maschinenkosten: inputs.maschinenkosten,
                                                                           standzeit: inputs.standzeit,
                                                                           nebenzeit: inputs.nebenzeit,
                                                                           blattkosten: e.target.value,
                                                                       })
                                                                   }}
                                                            />
                                                        </div>
                                                        <div className="form-check">
                                                            <button type="submit" disabled={loading}
                                                                    className="btn btn-primary btn-block">
                                                                {/*{!loading ?*/}
                                                                <div>{t('misc.calculate')}</div>
                                                                {/*:*/}
                                                                {/*    <div>*/}
                                                                {/*        <span className="spinner-border spinner-border-sm" role="status"*/}
                                                                {/*        aria-hidden="true"></span>*/}
                                                                {/*        Loading...*/}
                                                                {/*    </div>*/}
                                                                {/*}*/}
                                                            </button>
                                                        </div>
                                                    </form>

                                                </fieldset>
                                                {result &&
                                                <fieldset className="border p-2 mt-3">
                                                    <legend className="w-auto">{getItem.item.product.description}</legend>
                                                    <div>
                                                        <p>
                                                            <strong>{t('misc.mcosts')}/{t('misc.cut')}:</strong>{" "}
                                                            {result.maschinenkosten}{' '}{currency}
                                                        </p>
                                                        <p>
                                                            <strong>{t('misc.bladecost')}/{t('misc.cut')}:</strong>{" "}
                                                            {result.blattkosten}{' '}{currency}

                                                        </p>
                                                        <p>
                                                            <strong>{t('misc.total')}/{t('misc.cut')}:</strong>{" "}
                                                            {result.gesamtkosten}{' '}{currency}
                                                        </p>
                                                        <p>
                                                            <strong>{t('misc.cost')}/m²:</strong>{" "}
                                                            {result.kosten}{' '}{currency}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <Link onClick={resultPrint} to={"#"}
                                                              className="pl-2 pr-2 mb-2 btn btn-primary float-right">
                                                            {t('misc.print')}
                                                        </Link>
                                                    </div>
                                                </fieldset>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>

                        </div>
                    }
                </div>

                {!data && !loading && msg &&
                <div>
                    <div className="alert alert-warning" role="alert">
                        {t(msg)}
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div className="w-100">
                                <Link
                                    className="pl-2 pr-2 mb-2 btn btn-primary float-left"
                                    color="primary" to={{
                                    pathname: '/master_calc',
                                    classes: 'btn btn-primary',
                                    aboutProps: {
                                        res,
                                        tab:'v-pills-settings'
                                    },

                                }}>
                                    {t('misc.back')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                }
                <Modal
                    isOpen={showModal}
                    contentLabel="Modal #1 Global Style Override Example"
                    onRequestClose={handleCloseModal}
                >
                    <a className="pl-2 pr-2 mb-2 btn btn-danger float-right" onClick={handleCloseModal}>{t('misc.close')}</a>
                    {/*<Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">*/}
                    <Worker workerUrl="/pdf.worker.min.js">
                        <div style={{ height: '95%' }} >
                            <Viewer fileUrl={pdfLink} initialPage={pageNumber}  toolbar={renderToolbar}/>
                        </div>
                    </Worker>
                </Modal>
            </div>
    )
}