import React, {useState, Suspenses} from "react";
import AuthService from "../services/auth.service";
import {Link, Redirect} from "react-router-dom";
import stockIcon from "../assets/images/stock-icon.svg";
import shelfIcon from "../assets/images/shelf.svg";
import packetIcon from "../assets/images/packet.svg";
import arrowBack from "../assets/images/arrow.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';



export default function StockManagement() {
    const currentUser = AuthService.getCurrentUser();
    const obj = (currentUser) ? {currentUser: currentUser, userReady: true} : {redirect: "/login"};
    const [state] = useState(obj);
    const { t, i18n } = useTranslation();

    return (
        (state.redirect) ?
            <Redirect to={state.redirect}/>
            :
            (!state.currentUser.konsignationsLager) ?
                <Redirect to="/home"/>
                :
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <Link to={"/stock"} className="card card-container bg-grey">
                                    <img src={shelfIcon} className='img-fluid dashboard-icon spacer-bt-1'
                                         alt='Arntz Konsignationslager'/>
                                    <h2>{t('stock.show')}</h2>
                                    <p>
                                        {t('stock.showLabel')}
                                    </p>
                                </Link>
                                <Link to={"/get_product"} className="card card-container  bg-grey">
                                    <img src={stockIcon} className='img-fluid dashboard-icon spacer-bt-1'
                                         alt='Arntz Konsignationslager'/>
                                    <h2>{t('stock.get')}</h2>
                                    <p>
                                        {t('stock.getLabel')}
                                    </p>
                                </Link>
                            </div>
                        </div>


                    </div>


    )
}