import "./assets/scss/app.scss"
//import 'bootstrap/js/src/'
import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import AuthService from "./services/auth.service";
import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home";
import Profile from "./components/profile.component";
import StockManagement from "./components/stock-management";
import Product from "./components/product";
import GetProduct from "./components/get.product";
import ForgotPassword from "./components/forgot.password";
import ResetPassword from "./components/reset.password";
import SendOrder from "./components/send.order";
import Mastercalc from "./components/mastercalc";
import Navigation from "./components/navigation";
import LastSearch from "./components/last_search";
import Result from "./components/result";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import MetaTags from 'react-meta-tags';


i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
});

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount(prevProps) {
    const user = AuthService.getCurrentUser();

    if (user) {
      /*const messaging = firebase.messaging();
      messaging.requestPermission().then((token)=>{
        return messaging.getToken();
      }).then((data)=>{
        console.warn("token",data)
      }).catch(()=>{
        console.log('error');
      })*/

      this.setState({
        currentUser: user,
      });
    }
  }

  logOut() {
    AuthService.logout();
  }

  render() {
    // const { currentUser } = this.state;

    return (
      <div >
        <Navigation/>
        <div className="container mt-3">
          <Switch>
            <Route exact path= "/login" component={Login} />
            <Route exact path={["/","/home"]} component={Home} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/stock_management" component={StockManagement} />
            <Route exact path="/stock" component={Product} />
            <Route exact path="/get_product" component={GetProduct} />
            <Route exact path="/send_order" component={SendOrder} />
            <Route exact path="/forgot_password" component={ForgotPassword} />
            <Route exact path="/reset_password" component={ResetPassword} />
            <Route exact path="/master_calc" component={Mastercalc} />
            <Route exact path="/last_search" component={LastSearch} />
            <Route exact path="/result" component={Result} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
