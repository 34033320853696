import Dexie from "dexie";
import UserService from "../services/user.service";

export default class ArntzDatabase extends Dexie{
    static db = ArntzDatabase.db || new ArntzDatabase("Arntz");
    resources
    static isSync = false;
    constructor(db) {
        super(db);
        this.version(1).stores({
            products: "++id",
            bookings: "++id",
            saws: "++id",
            models: "++id",
            materials: "++id",
            decisions: "++id",
            dimensions: "++id",
            inventories: "++id",
            speeds: "++id",
            tpms: "++id",
            track_db_changes: "++id",
        });
        this.resources = {
            'products': this.table("products"),
            'bookings': this.table("bookings"),
            'saws': this.table("saws"),
            'models': this.table("models"),
            'materials': this.table("materials"),
            'decisions': this.table("decisions"),
            'dimensions': this.table("dimensions"),
            'inventories': this.table("inventories"),
            'speeds': this.table("speeds"),
            'tpms': this.table("tpms"),
            'track_db_changes': this.table("track_db_changes"),
        }
    }

    sync(resource){
        // console.log(ArntzDatabase.isSync);
        let _this = this;
        if(!ArntzDatabase.isSync){
            _this.resources[resource].each(data => {
                // console.log("Remote Databases "+resource+" [ADD]")
                if(data.status === 'ADD'){
                    delete data['id'];
                    UserService.post(resource,data).then().catch((e)=>console.log(e));
                }
            }).then(()=>{
                 _this.resources[resource].each(data => {
                     // console.log("Remote Databases "+resource+" [UPDATE]")
                    if(data.status === 'UPDATE'){
                        UserService.put(resource,data.bookingId,{'amount':data.amount,'addedAmount':data.addedAmount}).then(()=>{
                        }).catch((e)=>console.log(e));
                    }
                })
            })
        }
    }
}