import React, {useEffect, useState, useCallback} from "react";
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CheckIcon from '@material-ui/icons/Check';
import CardContent from '@material-ui/core/CardContent';
import {Link} from "react-router-dom";
import AuthService from "../services/auth.service";
import {Redirect} from "react-router-dom";
import ArntzDatabase from "../services/database";
import UserService from "../services/user.service";
import {CircularProgress} from '@material-ui/core';
import $ from "jquery";
import Select from 'react-select'
import dt from "datatables.net";
import dtbs4 from "datatables.net-bs4";
import dtrbs4 from "datatables.net-responsive-bs4";
import {useTranslation} from "react-i18next";
import {forEach} from "react-bootstrap/ElementChildren";
import { useBetween } from "use-between";
import useShareableState from "../shareableState";


const options = [
    {value: 0, label: 'id'},
    {value: 1, label: 'Name'},
    {value: 2, label: 'number'},
    {value: 3, label: 'din'},
    {value: 4, label: 'skill'},
    {value: 5, label: 'gost'},
    {value: 6, label: 'jis'},
    {value: 7, label: 'bs'},
    {value: 8, label: 'ss'},
    {value: 9, label: 'euroNorm'},
    {value: 10, label: 'afnor'},
    {value: 11, label: 'une'},
    {value: 12, label: 'uni'},
    {value: 13, label: 'uns'},
    {value: 14, label: 'boehler'},
    {value: 15, label: 'dillingerHuette'},
    {value: 16, label: 'hkm'},
    {value: 17, label: 'outokumpo'},
    {value: 18, label: 'ovako'},
    {value: 19, label: 'sandvik'},
    {value: 20, label: 'salzgitter'},
    {value: 21, label: 'specialMetals'},
    {value: 22, label: 'ssab'},
    {value: 23, label: 'thyssenKrupp'},
    {value: 24, label: 'uddeholm'},
    {value: 25, label: 'astmSaeAisi'},
]

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            minWidth: 275,
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '2px',
            borderBottom: '1px solid #80808038'
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    }),
);

export default function GetProduct(props) {
    const {t, i18n} = useTranslation();
    console.log("languge"+i18n.language);
    const { unit, setUnit, currency, setCurrency } = useBetween(useShareableState);

    const classes = useStyles();
    const currentUser = AuthService.getCurrentUser();
    const obj = (currentUser) ? {
        currentUser: currentUser,
        userReady: true,
        'manufacturer': [],
        'models': []
    } : {redirect: "/login"};
    const resback = (props && props.location.aboutProps != null) ? props.location.aboutProps :null;

    const [state] = useState(obj);
    const [manufacturer, setManufacturer] = useState([]);
    const [models, setModels] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [opdin, setOpdin] = useState([]);
    const [materialTyp, setMaterialTyp] = useState('fullMaterial');
    const [data, setData] = useState();
    const [holderTyp, setHolderTyp] = useState('');
    const [specification, setSpecification] = useState('Rund');
    const [inputs, setInputs] = useState({});
    const [mode, setMode] = useState('online');
    const [loading, setLoading] = useState(false);
    const [loadingModel, setLoadingModel] = useState(false);
    const [msg, setMsg] = useState('message.noResult');
    const [masse, setMasse] = useState(false);
    const [selectedMat, setSelectedMat] = useState('');
    const [val, setVal] = useState('');
    const [spe, setSpe] = useState('ru');
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [filter3, setFilter3] = useState();
    const [wallThickness, setWallThickness] = useState();
    const [searchInput, setSearchInput] = useState("");
    const [field, setField] = useState("");
    const [fieldOptions, setFieldOptions] = useState([]);
    const [fieldRes, setFieldRes] = useState("");
    const [dimensionHeightMsg, setDimensionHeightMsg] = useState();
    const [dimensionStrengthMsg, setDimensionStrengthMsg] = useState();
    const [activeTab, setActiveTab] = useState('v-pills-home');
    const [strengthSelect, setStrengthSelect] = useState([]);
    const [inputVals, setInputVals] = useState({matTyp:"fullMaterial",bundlingWidth:1,bundlingHeight:1,steel:true,});

    const submitForm = (event) => {
        event.preventDefault();

        let el = document.getElementById('v-pills-settings-tab');
        el.click();

        setMsg('');
        console.log(spe);
        console.log(inputVals);

        if((spe == "re" && (inputVals.height ==undefined || inputVals.height =="" || inputVals.width==undefined || inputVals.width=="")) ||
            (spe == "q" && (inputVals.side==undefined || inputVals.side=="")) ||
            (spe == "ru" && (inputVals.dia==undefined|| inputVals.dia=="")) ||
            (["ro", "vi"].includes(spe) && (inputVals.outerDiameter==undefined|| inputVals.outerDiameter=="" || inputVals.wallThickness==undefined|| inputVals.wallThickness=="" )) ||
            (inputVals.bundlingHeight ==undefined || inputVals.bundlingHeight ==""|| inputVals.bundlingWidth ==undefined || inputVals.bundlingWidth =="")

        ){
            // setMsg('Kein Ergebnis - Bitte überprüfen Sie die Eingabe. ');
            setMsg('message.noResult');
            setData();
            return true;
        }

        if((inputVals.setting && (inputs.height==undefined || inputs.height =="0"|| inputs.strength==undefined|| inputs.strength =="0"|| inputs.length==undefined|| inputs.length =="")) ||
            (!inputVals.setting && (inputVals.selectedManu ==undefined || inputVals.selectedManu =="0"|| inputVals.selectedModel==undefined || inputVals.selectedModel==0))){
            setMsg('message.noResult');
            setData();
            return true;
        }
        // else{



        let target = event.target;
        let filters = {};
        let filters2 = [];
        // if (target['decision.materialart'].value === 'fullMaterial') {
        filters['fullMaterial'] = 1;
        if (target['decision.materialart'].value === 'fullMaterial') {
            filters2.push({'key': 'materialart', 'value': 'VollMaterial', 'transKey': 'master.wtype'});
        }

        if(selectedMat.name !== '')
            filters2.push({'transKey': 'misc.name', 'value': i18n.language == "de" ? selectedMat.name : selectedMat.name_en});
        if(selectedMat.number !== '')
            filters2.push({'transKey': 'misc.number', 'value': selectedMat.number});
        if(selectedMat.din !== '')
            filters2.push({'transKey': 'misc.din', 'value': selectedMat.din});

        for (let i = 0; i < target.elements.length; i++) {
            if (target.elements[i].hidden) continue;
            let val = target.elements[i].value;
            let name = target.elements[i].getAttribute("name");
            let id = target.elements[i].getAttribute("id");
            let placeholder = target.elements[i].getAttribute("placeholder");
            let type = target.elements[i].getAttribute("type");
            let transKey = target.elements[i].getAttribute("data-trans");
            let bundlingWidth = parseInt(target.bundlingWidth.value);
            if (id && id === 'decision.materialart' || name === null) continue;
            if (name && name === 'specification.type') {
                if (target.elements[i].checked) {
                    filters[name] = val;
                    if (placeholder !== null && type === "radio")
                        // filters2.push({'key': placeholder, 'value': val,'transKey':transKey});
                        filters2.push({'key': placeholder, 'value': t(transKey),'transKey':'master.form'});
                }
            } else if (id && id.includes('decision') && val !== '') {
                // val = target.elements[i].type === 'number' ? parseFloat(val) : val;
                val = parseFloat(val.replace(',', '.'));
                if (['specification.outerDiameter', 'specification.wallThickness', 'specification.thickness'].includes(name)) {
                    val = (unit ==='meter-cm') ? val: Math.round(parseFloat(val) / 0.039370078740157)
                    filters[name + 'From[lte]'] = val * bundlingWidth;
                    name += 'To[gte]';
                    filters[name] = val * bundlingWidth;
                } else
                    filters[name] = val;
                if (placeholder !== null)
                    filters2.push({'key': placeholder, 'value': val, 'transKey':transKey});
            }

        }
        if (target.check.checked) {
            delete filters['dimension.models.saw.manufacturer'];
            delete filters['dimension.models.name'];
        }
        filters['specification.type'] = 'full-material';

        // }
        /********************************************************/
        let filters3 = filters;

        delete filters3['bundlingWidth'];
        delete filters3['bundlingHeight'];
        /**********************************************************/
        if (target['decision.materialart'].value === 'profile') {
            delete filters3['specification.outerDiameterFrom[lte]'];
            delete filters3['specification.outerDiameterTo[gte]'];
            delete filters3['specification.wallThicknessFrom[lte]'];
            delete filters3['specification.wallThicknessTo[gte]'];
            delete filters3['specification.thicknessFrom[lte]'];
            delete filters3['specification.thicknessTo[gte]'];
            delete filters3['specification.hGType'];
            delete filters3['specification.lGType'];
            delete filters3['specification.uGType'];


            filters = {};
            // filters2 = [];
            filters['profile'] = 1;
            const maxVal = 550;
            filters2.push({'key': 'materialart', 'value': 'profile','transKey':'master.wtype'});
            for (let i = 0; i < target.elements.length; i++) {
                if (target.elements[i].hidden) continue;
                let val = target.elements[i].value;
                let name = target.elements[i].getAttribute("name");
                let placeholder = target.elements[i].getAttribute("placeholder");
                let transKey = target.elements[i].getAttribute("data-trans");
                let type = target.elements[i].getAttribute("type");
                let bundlingWidth = parseInt(target.bundlingWidth.value);
                if (name && name === 'specification.type') {
                    if (target.elements[i].checked) {
                        filters[name] = val;
                        if (placeholder !== null && val !== '' && !filters2.filter(e=>e.transKey === 'master.form') && type === "radio")
                            // filters2.push({'key': placeholder, 'value': val, 'transKey':transKey});
                            filters2.push({'key': placeholder, 'value': t(transKey),'transKey':'master.form'});
                    }
                } else if (['specification.uGType', 'specification.lGType', 'specification.hGType'].includes(name)) {
                    let vall = val.split(' x ');
                    vall[0] = parseFloat(vall[0].replace(',', '.'));
                    filters['specification.height'] = parseFloat(vall[0]);
                    /***********************************/
                    filters3['specification.thicknessFrom[lte]'] = parseFloat(vall[0]) > maxVal ? maxVal : parseFloat(vall[0]);
                    filters3['specification.thicknessTo[gte]'] = parseFloat(vall[0]) > maxVal ? maxVal : parseFloat(vall[0]);
                    /***************************************/
                    if (vall[1]) {
                        // filters['specification.width'] = parseFloat(vall[1]) * bundlingWidth;
                        vall[1] = parseFloat(vall[1].replace(',', '.'));
                        filters['specification.width'] = parseFloat(vall[1]);
                    }

                    if (placeholder !== null && !filters2.filter(e=>e.transKey === transKey))
                        filters2.push({'key': placeholder, 'value': val,'transKey':transKey});
                } else if (name && name.includes('specification') && val !== '') {
                    // val = type === 'number' ? parseFloat(val) : val;
                    val = parseFloat(val.replace(',', '.'));
                    // val = (unit ==='meter-cm') ? val: Math.round(parseFloat(val) / 0.00004)4,72441
                    val = (unit ==='meter-cm') ? val: Math.round(parseFloat(val) / 0.039370078740157)

                    if (['specification.thickness'].includes(name)) {

                        filters[name + 'From[lte]'] = val * bundlingWidth;
                        name += 'To[gte]';
                        /***********************************/
                        filters3['specification.thicknessFrom[lte]'] = val > maxVal ? maxVal : val;
                        filters3['specification.thicknessTo[gte]'] = val > maxVal ? maxVal : val;
                        /***************************************/
                    }
                    filters[name] = val * bundlingWidth;

                    if (['specification.wallThickness'].includes(name)) {
                        if (bundlingWidth > 1) {
                            filters[name + 'From[lte]'] = val * 2;
                            filters[name + 'To[gte]'] = val * 2;
                        } else {
                            filters[name + 'From[lte]'] = val;
                            filters[name + 'To[gte]'] = val;
                        }
                    }
                    if (['specification.outerDiameter'].includes(name)) {
                        if(spe == "vi"){
                            filters[name + 'From[lte]'] = val * bundlingWidth;
                            filters[name + 'To[gte]'] = val * bundlingWidth ;
                        }else{
                            filters[name + 'From[lte]'] = val ;
                            filters[name + 'To[gte]'] = val  ;
                        }

                    }
                    if (placeholder !== null && !filters2.filter(e=>e.transKey === transKey))
                        filters2.push({'key': placeholder, 'value': val, 'transKey':transKey});
                }
            }

        }
        else {
            if(selectedMat.number !== undefined)
                filters['materialGroup.materials.number'] = selectedMat.number;

        }
        console.log("--------------------------------")
        console.log(selectedMat)
        console.log("--------------------------------")
        filters3['din'] = selectedMat.din
        filters3['wnumber'] = selectedMat.number
        filters3['wname'] = selectedMat.name
        filters3['wname_en'] = selectedMat.name_en
        filters3['bundlingWidth'] = target.bundlingWidth.value
        filters3['bundlingHeight'] = target.bundlingHeight.value
        filters3['inputVals'] =inputVals;

        if(masse == false){
            filters3['manufacturer'] = target['dimension.models.saw.manufacturer'].value
            filters3['manufacturerTyp'] = target['dimension.models.name'].value
        }


        setFilter3(filters3);
        console.log(filters3);

        filters2.push({'key': 'Bündelung', 'value': target.bundlingWidth.value + ' x ' + target.bundlingHeight.value,'transKey':'misc.bund'});
        //   delete filters['bundlingHeight'];
        //   delete filters['bundlingWidth'];
        setLoading(true);
        const key = (target.bundlingWidth.value <= 1) ? 0 : 1;
        delete filters['dimension.models.saw.manufacturer'];
        delete filters['dimension.models.name'];
        delete filters['dimension.models.length'];

        filters3['materialGroup.materials.din'] = selectedMat.din;

        if(selectedMat.din && selectedMat.number === undefined)
            filters['materialGroup.materials.number'] = ""
        if(selectedMat.number && selectedMat.din === undefined)
            filters['materialGroup.materials.din'] = ""
        console.log(filters);
        console.log(filters3);
        if (window.navigator.onLine) {
            UserService.getAll('decisions', filters).then((res) => {
                if (res.data.length > 0) {
                    if(filters['profile'] == 1){
                        let params={
                            "height" : filters3['dimension.height'],
                            "strength" : filters3['dimension.strength'],
                        }
                        UserService.getAll('dimensions', params).then((dimensions) => {
                            console.log(dimensions.data);
                            if (dimensions.data.length > 0) {
                                let material_params ={
                                    din :filters3['din'],
                                    number:filters3['wnumber']
                                }
                                UserService.getAll('materials', material_params).then((materials) => {
                                    console.log(materials.data);
                                    if (materials.data.length > 0) {
                                        let speed_params={
                                            'dimension': dimensions.data[0].id,
                                            'gearing': (res.data[0].gearing.id == 6)? 7 :res.data[0].gearing.id,
                                            // 'specification': res.data[0].specification.id,
                                            'materialGroup': materials.data[0].MetrialGroup.id,
                                            'category': res.data[0].category == null || res.data[0].category.id != 3 ? 5 : res.data[0].category.id,
                                        }
                                        UserService.getAll('speeds', speed_params).then((speeds) => {
                                            console.log(speeds)
                                            if (speeds.data.length > 0) {
                                                let P = function (val, ele) {
                                                    return new Promise(resolve => {
                                                        console.log(ele);
                                                        console.log(val);
                                                        let params = {
                                                            'gearing.id': val.gearing.id,
                                                            // 'dimension.id': val.dimension.id,
                                                            'product.id': val.product.id,
                                                        }
                                                        if (val.dimension) {
                                                            params['dimension.id'] = val.dimension.id;
                                                        }

                                                        UserService.getAll('inventories', params)
                                                            .then((inventories) => {
                                                                // if (inventories.data.length > 0) {
                                                                resolve(val);
                                                                // }

                                                            })
                                                            .catch((err) => {
                                                                getOfflineSubmit(filters, filters2, key, filters3, target);
                                                            });
                                                    });
                                                };
                                                let results = Promise.all(res.data.map(P));
                                                results.then(arr => {
                                                        setLoading(false);
                                                        console.log(arr);
                                                        if (arr.length > 0) {
                                                            if (target['decision.materialart'].value === 'profile') {
                                                                // arr = (target.bundlingWidth.value <= 1 && target.bundlingHeight.value <= 1) ? arr[0] : arr[1];
                                                                if(target.bundlingWidth.value <= 1 && (target.bundlingHeight.value <= 1 ||  target.bundlingHeight.value > 1))
                                                                    arr = arr[0];
                                                                else if(arr.length === 1)
                                                                    arr = arr[0];
                                                                else
                                                                    arr = arr[1];

                                                                arr = [arr];
                                                            } else {
                                                                let first = '';
                                                                let second = '';
                                                                let third = '';
                                                                arr.forEach(function (item) {
                                                                    // console.log(item);
                                                                    if (item.category.id === 1) {
                                                                        first = item;
                                                                    } else if (item.category.id === 3) {
                                                                        third = item;
                                                                    } else if (item.category.id === 4) {
                                                                        second = item;
                                                                    }
                                                                })
                                                                arr = [];
                                                                if (first !== '') {
                                                                    arr.push(first);
                                                                }
                                                                if (second !== '') {
                                                                    arr.push(second);
                                                                }
                                                                if (third !== '') {
                                                                    arr.push(third);
                                                                }
                                                            }

                                                            console.log(arr);
                                                            setData(arr);
                                                            let last_search = {
                                                                filters: filters2,
                                                                data: arr
                                                            }
                                                            localStorage.removeItem('last_search');
                                                            localStorage.setItem('last_search', JSON.stringify(last_search));

                                                            // let result_back = {
                                                            //     res : {
                                                            //         all: arr,
                                                            //         durchmesser:  (unit ==='meter-cm') ? val : Math.round(parseFloat(val) / 0.039370078740157),
                                                            //         measure: inputs ,
                                                            //         height:   (unit ==='meter-cm') ? height : Math.round(parseFloat(height) / 0.039370078740157),
                                                            //         width:   (unit ==='meter-cm') ? width : Math.round(parseFloat(width) / 0.039370078740157),
                                                            //         surface: spe,
                                                            //         materialType: materialTyp,
                                                            //         filters: filter3,
                                                            //         wallThickness:   (unit ==='meter-cm') ? wallThickness : Math.round(parseFloat(wallThickness) / 0.039370078740157)
                                                            //     },
                                                            //     tab:'v-pills-settings'
                                                            // }
                                                            //
                                                            // localStorage.removeItem('result_back');
                                                            // localStorage.setItem('result_back', JSON.stringify(result_back));

                                                        } else {
                                                            setData();
                                                            // setMsg('Kein Ergebnis - Bitte überprüfen Sie die Eingabe. ');
                                                            setMsg('message.noResult');
                                                        }
                                                    }
                                                );
                                            }else {
                                                setLoading(false);
                                                setData();
                                                // setMsg('Kein Ergebnis - Bitte überprüfen Sie die Eingabe. ');
                                                setMsg('message.noResult');
                                            }
                                        })
                                    }
                                    else {
                                        setLoading(false);
                                        setData();
                                        // setMsg('Kein Ergebnis - Bitte überprüfen Sie die Eingabe. ');
                                        setMsg('message.noResult');
                                    }
                                })
                            }
                            else {
                                setLoading(false);
                                setData();
                                // setMsg('Kein Ergebnis - Bitte überprüfen Sie die Eingabe. ');
                                setMsg('message.noResult');
                            }
                        })
                    }
                    else{
                        let P = function (val, ele) {
                            return new Promise(resolve => {
                                console.log(ele);
                                console.log(val);
                                let params = {
                                    'gearing.id': val.gearing.id,
                                    // 'dimension.id': val.dimension.id,
                                    'product.id': val.product.id,
                                }
                                if (val.dimension) {
                                    params['dimension.id'] = val.dimension.id;
                                }

                                UserService.getAll('inventories', params)
                                    .then((inventories) => {
                                        // if (inventories.data.length > 0) {
                                        resolve(val);
                                        // }

                                    })
                                    .catch((err) => {
                                        getOfflineSubmit(filters, filters2, key, filters3, target);
                                    });
                            });
                        };

                        let results = Promise.all(res.data.map(P));
                        results.then(arr => {
                                setLoading(false);
                                console.log(arr);
                                if (arr.length > 0) {
                                    if (target['decision.materialart'].value === 'profile') {
                                        // arr = (target.bundlingWidth.value <= 1 && target.bundlingHeight.value <= 1) ? arr[0] : arr[1];
                                        if(target.bundlingWidth.value <= 1 && (target.bundlingHeight.value <= 1 ||  target.bundlingHeight.value > 1))
                                            arr = arr[0];
                                        else if(arr.length === 1)
                                            arr = arr[0];
                                        else
                                            arr = arr[1];

                                        arr = [arr];
                                    } else {
                                        let first = '';
                                        let second = '';
                                        let third = '';
                                        arr.forEach(function (item) {
                                            // console.log(item);
                                            if (item.category.id === 1) {
                                                first = item;
                                            } else if (item.category.id === 3) {
                                                third = item;
                                            } else if (item.category.id === 4) {
                                                second = item;
                                            }
                                        })
                                        arr = [];
                                        if (first !== '') {
                                            arr.push(first);
                                        }
                                        if (second !== '') {
                                            arr.push(second);
                                        }
                                        if (third !== '') {
                                            arr.push(third);
                                        }
                                    }

                                    console.log(arr);
                                    setData(arr);
                                    let last_search = {
                                        filters: filters2,
                                        data: arr
                                    }
                                    localStorage.removeItem('last_search');
                                    localStorage.setItem('last_search', JSON.stringify(last_search));

                                    // let result_back = {
                                    //     res : {
                                    //         all: arr,
                                    //         durchmesser:  (unit ==='meter-cm') ? val : Math.round(parseFloat(val) / 0.039370078740157),
                                    //         measure: inputs ,
                                    //         height:   (unit ==='meter-cm') ? height : Math.round(parseFloat(height) / 0.039370078740157),
                                    //         width:   (unit ==='meter-cm') ? width : Math.round(parseFloat(width) / 0.039370078740157),
                                    //         surface: spe,
                                    //         materialType: materialTyp,
                                    //         filters: filter3,
                                    //         wallThickness:   (unit ==='meter-cm') ? wallThickness : Math.round(parseFloat(wallThickness) / 0.039370078740157)
                                    //     },
                                    //     tab:'v-pills-settings'
                                    // }
                                    //
                                    // localStorage.removeItem('result_back');
                                    // localStorage.setItem('result_back', JSON.stringify(result_back));

                                } else {
                                    setData();
                                    // setMsg('Kein Ergebnis - Bitte überprüfen Sie die Eingabe. ');
                                    setMsg('message.noResult');
                                }
                            }
                        );
                    }


                    // for (let ele in res.data){
                    //     console.log(ele);
                    //     let params = {
                    //         'gearing.id': res.data[ele].gearing.id,
                    //         'dimension.id': res.data[ele].dimension.id,
                    //         'product.id': res.data[ele].product.id,
                    //     }
                    //
                    //     UserService.getAll('inventories', params)
                    //     .then((inventories) => {
                    //     // console.log(inventories.data);
                    //     if (inventories.data.length > 0) {
                    //         arr.push(res.data[ele]);
                    //     }
                    //     console.log(arr);
                    //     console.log(parseInt(ele));
                    //     if(parseInt(ele) === res.data.length -1){
                    //         console.log("finish")
                    //         if(arr.length > 0){
                    //             console.log(arr);
                    //             setData(arr);
                    //
                    //             let last_search = {
                    //                 filters: filters2,
                    //                 data: arr
                    //             }
                    //             localStorage.removeItem('last_search');
                    //             localStorage.setItem('last_search', JSON.stringify(last_search));
                    //         }else {
                    //             setData();
                    //             setMsg('Kein Ergebnis - Bitte überprüfen Sie die Eingabe. ');
                    //         }
                    //     }
                    //
                    // })
                    //     .catch((err) => {
                    //         getOfflineSubmit(filters, filters2, key, filters3, target);
                    //     });
                    // }
                } else {
                    setLoading(false);
                    setData();
                    // setMsg('Kein Ergebnis - Bitte überprüfen Sie die Eingabe. ');
                    setMsg('message.noResult');
                }

            }).catch((err) => {
                getOfflineSubmit(filters, filters2, key, filters3, target);
            });
        } else {
            getOfflineSubmit(filters, filters2, key, filters3, target);
        }
        // }
    };
    const getOfflineSubmit = (filters, filters2, key, filters3, target) => {
        setMode('offline');
        ArntzDatabase.db.resources['materials'].filter((material) => {
            return material.din == selectedMat.din && material.number == selectedMat.number
        }).toArray().then((materials) => {
            console.log(materials);
            ArntzDatabase.db.resources['decisions'].filter((decision) => {
                let filter = ((filters.hasOwnProperty('specification.wallThicknessFrom[lte]') && decision.specification.wallThicknessFrom ) ? decision.specification.wallThicknessFrom <= filters['specification.wallThicknessFrom[lte]'] : true)
                && ((filters.hasOwnProperty('specification.wallThicknessTo[gte]') && decision.specification.wallThicknessTo ) ? decision.specification.wallThicknessTo >= filters['specification.wallThicknessTo[gte]'] : true)
                && ((filters.hasOwnProperty('specification.outerDiameterFrom[lte]') && decision.specification.outerDiameterFrom ) ? decision.specification.outerDiameterFrom <= filters['specification.outerDiameterFrom[lte]'] : true)
                && ((filters.hasOwnProperty('specification.outerDiameterTo[gte]') && decision.specification.outerDiameterTo ) ? decision.specification.outerDiameterTo >= filters['specification.outerDiameterTo[gte]'] : true)
                && ((filters.hasOwnProperty('specification.thicknessFrom[lte]') && decision.specification.thicknessFrom ) ? decision.specification.thicknessFrom <= filters['specification.thicknessFrom[lte]'] : true)
                && ((filters.hasOwnProperty('specification.thicknessTo[gte]') && decision.specification.thicknessTo ) ? decision.specification.thicknessTo >= filters['specification.thicknessTo[gte]'] : true)
                && ((filters.hasOwnProperty('specification.height') && decision.specification.height ) ? decision.specification.height == filters['specification.height'] : true)
                && ((filters.hasOwnProperty('specification.width') && decision.specification.width ) ? decision.specification.width == filters['specification.width'] : true)
                && ((filters.hasOwnProperty('specification.sideLength') && decision.specification.sideLength ) ? decision.specification.sideLength == filters['specification.sideLength'] : true)
                && ((filters.hasOwnProperty('specification.diameter') && decision.specification.diameter ) ? decision.specification.diameter == filters['specification.diameter'] : true)
                && ((filters.hasOwnProperty('dimension.models.saw.manufacturer') && decision['dimension.models'] ) ? decision['dimension.models'].filter(m => m.saw.manufacturer == filters['dimension.models.saw.manufacturer']).length > 0 : true)
                && ((filters.hasOwnProperty('dimension.models.name') && decision['dimension.models'] ) ? decision['dimension.models'].filter(m => m.name == filters['dimension.models.name']).length > 0 : true)
                && ((filters.hasOwnProperty('dimension.height') && decision.dimension && decision.dimension.height ) ? decision.dimension.height == filters['dimension.height'] : true)
                && ((filters.hasOwnProperty('dimension.strength') && decision.dimension && decision.dimension.strength ) ? decision.dimension.strength == filters['dimension.strength'] : true)
                && ((decision.materialGroup ) ? decision.materialGroup.id == materials[0].MetrialGroup.id : true)
                && ((filters.hasOwnProperty('fullMaterial') && decision.fullMaterial ) ? decision.fullMaterial == 1 : true)
                && ((filters.hasOwnProperty('profile') && decision.profile ) ? decision.profile == 1 : true)
                && ((filters.hasOwnProperty('specification.type') && decision.specification.type ) ? decision.specification.type == filters['specification.type'] : true);
                console.log(filter);
                return filter;
                // && decision.dimension.strength === 0.9
                // && decision['materialGroup.materials.din'] === "GG-10"
                // && decision['materialGroup.materials.number'] === "0.6010"
                //&& ((filters.hasOwnProperty('materialGroup.materials.din') && decision['materialGroup.materials'] ) ? decision['materialGroup.materials'].filter(m => m.din == filters['materialGroup.materials.din']).length > 0 : true)
                //&& ((filters.hasOwnProperty('materialGroup.materials.number') && decision['materialGroup.materials'] ) ? decision['materialGroup.materials'].filter(m => m.number == filters['materialGroup.materials.number']).length > 0 : true)
                //
                // console.log(decision)
                // return decision.specification.thicknessFrom <= 120
                //     && decision.specification.thicknessTo >= 120
                //     && decision.specification.type == "full-material"
                //     && decision.dimension.height == 27
                //     && decision.dimension.strength == 0.9
                //     && decision.materialGroup.id == materials[0].MetrialGroup.id
            }).toArray()
                .then((ress) => {
                    if(ress.length > 0){
                        if(filters['profile'] == 1){
                            ArntzDatabase.db.resources['dimensions'].filter((dimension) =>(dimension.height == filters3['dimension.height'] && dimension.strength == filters3['dimension.strength'] )).toArray().then((dimensions) => {
                                console.log(dimensions);
                                if (dimensions.length > 0) {
                                    ArntzDatabase.db.resources['materials'].filter((material) =>(material.din == filters3['din'] && material.number == filters3['wnumber'] )).toArray().then((materials) => {
                                        console.log(materials);
                                        if (materials.length > 0) {
                                            ArntzDatabase.db.resources['speeds'].filter((speed) =>{
                                                return ((speed.dimension && speed.dimension.id) ? speed.dimension.id === parseInt(dimensions[0].id) : true) &&
                                                    ((speed.gearing && speed.gearing.id && ress[0].gearing && ress[0].gearing.id) ? speed.gearing.id === ((ress[0].gearing.id === 6)? 7 : parseInt(ress[0].gearing.id)) : true) &&
                                                    ((speed.materialGroup && speed.materialGroup.id && ress[0].MetrialGroup && ress[0].MetrialGroup.id) ? speed.materialGroup.id === parseInt(materials[0].MetrialGroup.id) : true) &&
                                                    ((speed.category && speed.category.id && ress[0].category && ress[0].category.id) ? speed.category.id === (ress[0].category || ress[0].category.id != 3 ? 5 : parseInt(ress[0].category.id)) : true)
                                                // speed['gearing.id'] === (ress[0].gearing.id === 6)? 7 : parseInt(ress[0].gearing.id) &&
                                                // speed['materialGroup.id'] === parseInt(materials[0].MetrialGroup.id) &&
                                                // speed['category.id'] == (ress[0].category || ress[0].category.id != 3 ? 5 : parseInt(ress[0].category.id));
                                            }).toArray().then((speeds) => {
                                                console.log(speeds)
                                                if (speeds.length > 0) {
                                                    let P = function (val, ele) {
                                                        return new Promise(resolve => {
                                                            console.log(ele);
                                                            console.log(val);
                                                            ArntzDatabase.db.resources['inventories'].filter((inventory) =>{
                                                                return ((inventory.gearing && inventory.gearing.id && val.gearing && val.gearing.id) ? inventory.gearing.id === parseInt(val.gearing.id) : true) &&
                                                                    ((inventory.product && inventory.product.id && val.product && val.product.id) ? inventory.product.id === parseInt(val.product.id) : true) &&
                                                                    ((inventory.dimension && inventory.dimension.id && val.dimension && val.dimension.id) ? inventory.dimension.id === parseInt(val.dimension.id) : true)
                                                                // && inventory['product.id'] === parseInt(val.product.id) &&
                                                                // (val.dimension) ? inventory['dimension.id'] === parseInt(val.dimension.id) : true;
                                                            }).toArray().then((inventories) => {
                                                                console.log(inventories)
                                                                resolve(val);
                                                            }).catch((err) => {console.log(err)});
                                                        });
                                                    };
                                                    let results = Promise.all(ress.map(P));
                                                    results.then(arr => {
                                                        setLoading(false);
                                                        console.log(arr);
                                                        if (arr.length > 0) {
                                                            if (target['decision.materialart'].value === 'profile') {
                                                                // arr = (target.bundlingWidth.value <= 1 && target.bundlingHeight.value <= 1) ? arr[0] : arr[1];
                                                                if(target.bundlingWidth.value <= 1 && (target.bundlingHeight.value <= 1 || target.bundlingHeight.value > 1))
                                                                    arr = arr[0];
                                                                else if(arr.length === 1)
                                                                    arr = arr[0];
                                                                else
                                                                    arr = arr[1];

                                                                arr = [arr];
                                                            } else {
                                                                let first = '';
                                                                let second = '';
                                                                let third = '';
                                                                arr.forEach(function (item) {
                                                                    // console.log(item);
                                                                    if (item.category.id === 1) {
                                                                        first = item;
                                                                    } else if (item.category.id === 3) {
                                                                        third = item;
                                                                    } else if (item.category.id === 4) {
                                                                        second = item;
                                                                    }
                                                                })
                                                                arr = [];
                                                                if (first !== '') {
                                                                    arr.push(first);
                                                                }
                                                                if (second !== '') {
                                                                    arr.push(second);
                                                                }
                                                                if (third !== '') {
                                                                    arr.push(third);
                                                                }
                                                            }

                                                            console.log(arr);
                                                            setData(arr);
                                                            let last_search = {
                                                                filters: filters2,
                                                                data: arr
                                                            }
                                                            localStorage.removeItem('last_search');
                                                            localStorage.setItem('last_search', JSON.stringify(last_search));

                                                        } else {
                                                            setData();
                                                            setMsg('Kein Ergebnis - Bitte überprüfen Sie die Eingabe. ');
                                                        }
                                                    });
                                                }else {
                                                    setLoading(false);
                                                    setData();
                                                    setMsg('Kein Ergebnis - Bitte überprüfen Sie die Eingabe. ');
                                                }
                                            }).catch((err) => {console.log(err)});
                                        }
                                        else {
                                            setLoading(false);
                                            setData();
                                            setMsg('Kein Ergebnis - Bitte überprüfen Sie die Eingabe. ');
                                        }
                                    }).catch((err) => {console.log(err)});
                                }
                                else {
                                    setLoading(false);
                                    setData();
                                    setMsg('Kein Ergebnis - Bitte überprüfen Sie die Eingabe. ');
                                }
                            })
                        }
                        else{
                            let P = function (val, ele) {
                                return new Promise(resolve => {
                                    console.log(ele);
                                    console.log(val);
                                    ArntzDatabase.db.resources['inventories'].filter((inventory) =>{
                                        return ((inventory.gearing && inventory.gearing.id && val.gearing && val.gearing.id) ? inventory.gearing.id === parseInt(val.gearing.id) : true) &&
                                            ((inventory.product && inventory.product.id && val.product && val.product.id) ? inventory.product.id === parseInt(val.product.id) : true) &&
                                            ((inventory.dimension && inventory.dimension.id && val.dimension && val.dimension.id) ? inventory.dimension.id === parseInt(val.dimension.id) : true)
                                    }).toArray().then((inventories) => {
                                        console.log(inventories)
                                        resolve(val);
                                    }).catch((err) => {console.log(err)});
                                });
                            };
                            let results = Promise.all(ress.map(P));
                            results.then(arr => {
                                    setLoading(false);
                                    console.log(arr);
                                    if (arr.length > 0) {
                                        if (target['decision.materialart'].value === 'profile') {
                                            // arr = (target.bundlingWidth.value <= 1 && target.bundlingHeight.value <= 1) ? arr[0] : arr[1];
                                            if(target.bundlingWidth.value <= 1 && (target.bundlingHeight.value <= 1 || target.bundlingHeight.value > 1))
                                                arr = arr[0];
                                            else if(arr.length === 1)
                                                arr = arr[0];
                                            else
                                                arr = arr[1];

                                            arr = [arr];
                                        } else {
                                            let first = '';
                                            let second = '';
                                            let third = '';
                                            arr.forEach(function (item) {
                                                // console.log(item);
                                                if (item.category.id === 1) {
                                                    first = item;
                                                } else if (item.category.id === 3) {
                                                    third = item;
                                                } else if (item.category.id === 4) {
                                                    second = item;
                                                }
                                            })
                                            arr = [];
                                            if (first !== '') {
                                                arr.push(first);
                                            }
                                            if (second !== '') {
                                                arr.push(second);
                                            }
                                            if (third !== '') {
                                                arr.push(third);
                                            }
                                        }

                                        console.log(arr);
                                        setData(arr);
                                        let last_search = {
                                            filters: filters2,
                                            data: arr
                                        }
                                        localStorage.removeItem('last_search');
                                        localStorage.setItem('last_search', JSON.stringify(last_search));

                                    }
                                    else {
                                        setData();
                                        setMsg('Kein Ergebnis - Bitte überprüfen Sie die Eingabe. ');
                                    }
                                }
                            );
                        }

                    }
                    else{
                        setLoading(false);
                        setData();
                        setMsg(t('message.noResult'));
                    }
                    // let last_search = {
                    // filters: filters2,
                    // data: ress
                    // }
                    // localStorage.setItem('last_search', JSON.stringify(last_search));
                    // if (ress.length <= 0) {
                    // setData();
                    // setMsg(t('message.noResult'));
                    // } else {
                    // setData([ress[key]]);
                    // }
                })
                .catch((e) => {
                    console.log(e)
                    setLoading(false);
                    setData();
                    setMsg(t('message.noResult'));
                });
        }).catch((err) =>console.log(err));

    }
    const getOffline = () => {
        // (async () => {
        ArntzDatabase.isSync = false;
        // setData();
        setMode('offline');
        setLoading(true);
        var heightt = 27;
        // let saws = await ArntzDatabase.db.resources['saws'].toArray();
        // setManufacturer(saws);
        ArntzDatabase.db.resources['saws'].toArray().then((saws) => {
            setManufacturer(saws);
            if(resback && resback.res) {
                if (["ip", "hea", "heb", "hem"].includes(resback.res.surface)) {
                    setInputVals({
                        ...resback.res.filters.inputVals,
                        steel: true
                    })
                } else {
                    setInputVals({
                        ...resback.res.filters.inputVals,
                        steel: false
                    })
                }
            }
            let selectedManuu = (resback && resback.res && resback.res.filters && resback.res.filters.inputVals ) ? resback.res.filters.inputVals.selectedManu : saws[0].id;
            // selectedModell = resback.res.filters.inputVals.selectedModel;
            ArntzDatabase.db.resources['models'].filter((model) => ((model.saw) ? model.saw.id == selectedManuu : false)).toArray()
            .then((models) => {
                console.log(models);
                models.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
                setModels(models);
                if(resback && resback.res && resback.res.filters.inputVals) {
                    setInputVals({
                        ...resback.res.filters.inputVals,
                        steel: false
                    })
                }
                heightt = (resback && resback.res.measure) ? resback.res.measure.height: heightt;
                ArntzDatabase.db.resources['dimensions'].filter((dimension) => dimension.height == heightt).toArray()
                .then((dimensions) => {
                    console.log(dimensions)
                    if (dimensions.length > 0) {
                        if(heightt == "54") {
                            dimensions = dimensions.slice(0, 2).reverse()
                            setStrengthSelect(dimensions);
                        }else
                            setStrengthSelect([dimensions[0]]);
                        if(resback && resback.res && resback.res.measure) {
                            setInputs({
                                height:(resback.res.measure != null )? resback.res.measure.height : 27,
                                length:(resback.res.measure != null )? resback.res.measure.length : 3150,
                                strength:(resback.res.measure != null ) ? resback.res.measure.strength : 0.9,
                            })
                        }else{
                            setInputs({
                                length: (models.length > 0) ? models[0].length : '',
                                height: (dimensions.length > 0) ? dimensions[0].height : '',
                                strength: (dimensions.length > 0) ? dimensions[0].strength : '',
                            });
                        }
                    }
                })
                .catch((e) => console.log(e));

                // setInputs({
                //     length: (models.length > 0) ? models[0].length : '',
                //     height: (models.length > 0) ? models[0].dimension.height : '',
                //     strength: (models.length > 0) ? models[0].dimension.strength : '',
                // });
            }).catch((e) => console.log(e));

        }).catch((e) => console.log(e));
        // let models = await ArntzDatabase.db.resources['models'].filter((saw) => saw.id === saws[0].id).toArray();
        // let models = await ArntzDatabase.db.resources['models'].toArray();
        // setModels(models);
        // ArntzDatabase.db.resources['models'].toArray().then((models)=>setModels(models)).catch((e)=>console.log(e));
        // let materials = await ArntzDatabase.db.resources['materials'].toArray();
        // setMaterials(materials);
        ArntzDatabase.db.resources['materials'].toArray().then((materials) => {
            setMaterials(materials);
            let l = [];
            materials.sort((a, b) => (a.number +"-"+ a.din).localeCompare((b.number +"-"+ b.din))).map((item,k) => (
                   item.number && item.din &&
                    l.push({
                        key: k,
                        "data-number": item.number,
                        "data-din": item.din,
                        "data-name": item.Name,
                        // value: item.Name,
                        value: item.number +"-"+ item.din ,
                        value_en: item.nameEn,
                        label: item.number + " - " + item.din
                    })
                )
            )
            setOpdin(l);
            $(document).ready(function () {
                $('#materialDb').DataTable(
                    {
                        responsive: true,
                        language: {
                            "sEmptyTable": "Keine Daten in der Tabelle vorhanden",
                            "sInfo": "_START_ bis _END_ von _TOTAL_ Einträgen",
                            "sInfoEmpty": "Keine Daten vorhanden",
                            "sInfoFiltered": "(gefiltert von _MAX_ Einträgen)",
                            "sInfoThousands": ".",
                            "sLengthMenu": "_MENU_ Einträge anzeigen",
                            "sLoadingRecords": "Wird geladen ..",
                            "sProcessing": "Bitte warten ..",
                            "sSearch": "Suchen",
                            "sZeroRecords": "Keine Einträge vorhanden",
                            "oPaginate": {
                                "sFirst": "Erste",
                                "sPrevious": "Zurück",
                                "sNext": "Nächste",
                                "sLast": "Letzte"
                            },
                            "oAria": {
                                "sSortAscending": ": aktivieren, um Spalte aufsteigend zu sortieren",
                                "sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
                            },
                            "select": {
                                "rows": {
                                    "_": "%d Zeilen ausgewählt",
                                    "1": "1 Zeile ausgewählt"
                                }
                            },
                            "buttons": {
                                "print": "Drucken",
                                "colvis": "Spalten",
                                "copy": "Kopieren",
                                "copyTitle": "In Zwischenablage kopieren",
                                "copyKeys": "Taste <i>ctrl</i> oder <i>\u2318</i> + <i>C</i> um Tabelle<br>in Zwischenspeicher zu kopieren.<br><br>Um abzubrechen die Nachricht anklicken oder Escape drücken.",
                                "copySuccess": {
                                    "_": "%d Zeilen kopiert",
                                    "1": "1 Zeile kopiert"
                                },
                                "pageLength": {
                                    "-1": "Zeige alle Zeilen",
                                    "_": "Zeige %d Zeilen"
                                }
                            }
                        },
                    }
                );
            });
        }).catch((e) => console.log(e));
        setLoading(false);
        // })();
    }

    const fetchData = useCallback(async () => {
        // await ArntzDatabase.db.sync('bookings');
        // ArntzDatabase.isSync = true;
        setLoading(true);
        var selectedManuu = null;
        var selectedModell = null;
        var heightt = 27;
        // let resback = localStorage.getItem('result_back');
        // resback = (resback!== null) ? JSON.parse(resback):null;
        // console.log(resback);
        if(resback != null && resback.res != null){
            console.log(resback);
            setData(resback.res.all);
            setInputs({
                height:(resback.res.measure != null )? resback.res.measure.height : 27,
                length:(resback.res.measure != null )? resback.res.measure.length : 3150,
                strength:(resback.res.measure != null ) ? resback.res.measure.strength : 0.9,
            })
            setVal(resback.res.durchmesser);
            setHeight(resback.res.height);
            setWidth(resback.res.width);
            setSpe(resback.res.surface);
            setMaterialTyp(resback.res.materialType);
            setFilter3(resback.res.filters);
            setWallThickness(resback.res.wallThickness);
            setSelectedMat({
                din: (resback.res.filters != null )? resback.res.filters.din: "",
                number: (resback.res.filters != null )?resback.res.filters.wnumber:"",
                name: (resback.res.filters != null )? resback.res.filters.wname:"",
                name_en: (resback.res.filters != null )? resback.res.filters.wname_en:"",
            });
            /*if(resback.res.filters != null ){
                $('#decisionDin').val(resback.res.filters.wnumber+"-"+resback.res.filters.din)
            }*/
            /*setInputVals(resback.res.filters.inputVals)
            if(["ip","hea","heb","hem"].includes(resback.res.surface)){
                setInputVals({
                    ...inputVals,
                    steel: true
                })
            }else{
                setInputVals({
                    ...inputVals,
                    steel: false
                })
            }*/
            if(resback.res.filters && resback.res.filters.inputVals){
                setMasse(resback.res.filters.inputVals.setting===undefined ? false : resback.res.filters.inputVals.setting)
                selectedManuu = resback.res.filters.inputVals.selectedManu;
                selectedModell = resback.res.filters.inputVals.selectedModel;
            }

            setActiveTab(resback.tab);
            if(resback.res && resback.res.measure)
                heightt = parseInt(resback.res.measure.height);
        }
        if (window.navigator.onLine) {
            UserService.get('track_db_changes', "1").then((tdc) =>{
            console.log(tdc);
                if(tdc.data){
                ArntzDatabase.db.resources['track_db_changes'].filter((track) => track.token == tdc.data.token).toArray()
                    .then((tracks) => {
                        console.log(tracks);
                        if(tracks.length <= 0){
                            console.log("selectedManuu ::::: "+selectedManuu)
                            console.log("selectedModell ::::: "+selectedModell)
                            UserService.getAll('saws', {'itemsPerPage': '99999999999999999999999999999999'})
                            .then((saws) => {
                                setManufacturer(saws.data);
                                if(resback != null && resback.res != null){
                                    if(["ip","hea","heb","hem"].includes(resback.res.surface)){
                                        setInputVals({
                                            ...resback.res.filters.inputVals,
                                            steel: true
                                        })
                                    }else{
                                        setInputVals({
                                            ...resback.res.filters.inputVals,
                                            steel: false
                                        })
                                    }

                                    UserService.getAll('models', {
                                        'saw.id': (selectedManuu) ? parseInt(selectedManuu) :saws.data[0].id,
                                        'itemsPerPage': '99999999999999999999999999999999'
                                    })
                                    .then((models) => {
                                        models.data.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
                                        setModels(models.data);
                                        // setInputs({
                                        //     length:models.data[0].length,
                                        //     height:models.data[0].dimension.height,
                                        //     strength:models.data[0].dimension.strength,
                                        // })
                                        /*if(resback != null && resback.res != null){
                                            if(["ip","hea","heb","hem"].includes(resback.res.surface)){
                                                setInputVals({
                                                    ...resback.res.filters.inputVals,
                                                    steel: true
                                                })
                                            }else{
                                                setInputVals({
                                                    ...resback.res.filters.inputVals,
                                                    steel: false
                                                })
                                            }
                                        }*/
                                        // let height = (resback.res.filters && resback.res.filters.dimension.height) ? resback.res.filters.dimension.height: 27;
                                        console.log("heighttttt"+height);
                                        // UserService.getAll('dimensions', {'height': parseInt(height)})
                                        UserService.getAll('dimensions', {
                                            'height': heightt
                                        }).then((dimensions) => {
                                                console.log(dimensions)
                                                if (dimensions.data.length > 0) {
                                                    // setStrengthSelect([dimensions.data[0]]);
                                                    if(heightt == "54") {
                                                        dimensions.data = dimensions.data.slice(0, 2).reverse()
                                                        setStrengthSelect(dimensions.data);
                                                    }else
                                                        setStrengthSelect([dimensions.data[0]]);
                                                    if(resback == null) {
                                                        setInputs({
                                                            length: (models.data.length > 0) ? models.data[0].length : '',
                                                            height: (dimensions.data.length > 0) ? dimensions.data[0].height : '',
                                                            strength: (dimensions.data.length > 0) ? dimensions.data[0].strength : '',
                                                        });
                                                    }else{
                                                        setInputs({
                                                            height:(resback.res.measure != null )? resback.res.measure.height : 27,
                                                            length:(resback.res.measure != null )? resback.res.measure.length : 3150,
                                                            strength:(resback.res.measure != null ) ? resback.res.measure.strength : 0.9,
                                                        })
                                                    }
                                                }
                                                /*if(resback != null && resback.res != null){
                                                    console.log(resback);
                                                    setVal(resback.res.durchmesser);
                                                    setHeight(resback.res.height);
                                                    setWidth(resback.res.width);
                                                    setSpe(resback.res.surface);
                                                    setMaterialTyp(resback.res.materialType);
                                                    setFilter3(resback.res.filters);
                                                    setWallThickness(resback.res.wallThickness);
                                                    setSelectedMat({
                                                        din: (resback.res.filters != null )? resback.res.filters.din: "",
                                                        number: (resback.res.filters != null )?resback.res.filters.wnumber:"",
                                                        name: (resback.res.filters != null )? resback.res.filters.wname:"",
                                                    });
                                                    setInputVals(resback.res.filters.inputVals)

                                                }*/

                                            })
                                            .catch((err) => {
                                                console.log("err")
                                            });

                                        // ArntzDatabase.db.resources['models'].clear().then(() => ArntzDatabase.db.resources['models'].bulkAdd(models.data).then().catch()).catch();
                                    })
                                    .catch((e) => {
                                        setMode('offline');
                                        setLoading(false);
                                        getOffline()
                                    });
                                }
                                console.log("inputVals.selectedManu"+inputVals.selectedManu);


                                ArntzDatabase.db.resources['saws'].clear().then(() =>
                                    ArntzDatabase.db.resources['saws'].bulkAdd(saws.data).then().catch()
                                );

                            })
                            .catch((e) => {
                                setMode('offline');
                                setLoading(false);
                                getOffline()
                                // ArntzDatabase.isSync =false;
                            });

                            UserService.getAll('materials', {'itemsPerPage': '99999999999999999999999999999999'}).then((res) => {
                                setMaterials(res.data); //
                                let l = [];
                                res.data.sort((a, b) => (a.number +"-"+ a.din).localeCompare((b.number +"-"+ b.din))).map((item,k) => (
                                    item.number && item.din &&
                                        l.push({
                                            key: k,
                                            "data-number": item.number,
                                            "data-din": item.din,
                                            "data-name": item.Name,
                                            // value: item.Name,
                                            value: item.number +"-"+ item.din ,
                                            value_en: item.nameEn,
                                            label: item.number + " - " + item.din
                                        })
                                    )
                                )
                                setOpdin(l);
                                console.log('muhu!')
                                ArntzDatabase.db.resources['materials'].clear().then(() => ArntzDatabase.db.resources['materials'].bulkAdd(res.data).then().catch()).catch();
                                $(document).ready(function () {
                                    let table = $('#materialDb').DataTable(
                                        {
                                            responsive: true,
                                            language: {
                                                "sEmptyTable": "Keine Daten in der Tabelle vorhanden",
                                                "sInfo": "_START_ bis _END_ von _TOTAL_ Einträgen",
                                                "sInfoEmpty": "Keine Daten vorhanden",
                                                "sInfoFiltered": "(gefiltert von _MAX_ Einträgen)",
                                                "sInfoThousands": ".",
                                                "sLengthMenu": "_MENU_ Einträge anzeigen",
                                                "sLoadingRecords": "Wird geladen ..",
                                                "sProcessing": "Bitte warten ..",
                                                "sSearch": "Suchen",
                                                "sZeroRecords": "Keine Einträge vorhanden",
                                                "oPaginate": {
                                                    "sFirst": "Erste",
                                                    "sPrevious": "Zurück",
                                                    "sNext": "Nächste",
                                                    "sLast": "Letzte"
                                                },
                                                "oAria": {
                                                    "sSortAscending": ": aktivieren, um Spalte aufsteigend zu sortieren",
                                                    "sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
                                                },
                                                "select": {
                                                    "rows": {
                                                        "_": "%d Zeilen ausgewählt",
                                                        "1": "1 Zeile ausgewählt"
                                                    }
                                                },
                                                "buttons": {
                                                    "print": "Drucken",
                                                    "colvis": "Spalten",
                                                    "copy": "Kopieren",
                                                    "copyTitle": "In Zwischenablage kopieren",
                                                    "copyKeys": "Taste <i>ctrl</i> oder <i>\u2318</i> + <i>C</i> um Tabelle<br>in Zwischenspeicher zu kopieren.<br><br>Um abzubrechen die Nachricht anklicken oder Escape drücken.",
                                                    "copySuccess": {
                                                        "_": "%d Zeilen kopiert",
                                                        "1": "1 Zeile kopiert"
                                                    },
                                                    "pageLength": {
                                                        "-1": "Zeige alle Zeilen",
                                                        "_": "Zeige %d Zeilen"
                                                    }
                                                },
                                                // "oSearch": { "bSmart": false, "bRegex": true }
                                            },
                                            // columnDefs: [
                                            //     {
                                            //         "targets": [1, 2, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
                                            //         "searchable": false
                                            //     }
                                            // ],
                                        }
                                    );
                                });


                            }).catch((e) => {
                                setMode('offline');
                                setLoading(false);
                                getOffline()
                            });

                            setLoadingModel(true);
                            UserService.getAll('models', {
                                'itemsPerPage': '99999999999999999999999999999999'
                            }).then((models) => {
                                setLoadingModel(false);
                                // setModels(models.data);
                                // console.log(models.data)
                                ArntzDatabase.db.resources['models'].clear().then(() => ArntzDatabase.db.resources['models'].bulkAdd(models.data).then().catch()).catch();
                            }).catch((e) => {
                                setMode('offline');
                                setLoading(false);
                                getOffline()
                            });

                            UserService.getAll('dimensions', {'itemsPerPage': '99999999999999999999999999999999'}).then((res) => {
                                ArntzDatabase.db.resources['dimensions'].clear().then(() => ArntzDatabase.db.resources['dimensions'].bulkAdd(res.data).then().catch()).catch();
                            }).catch((e) => {
                                setMode('offline');
                                setLoading(false);
                                getOffline()
                            });
                            UserService.getAll('inventories', {'itemsPerPage': '99999999999999999999999999999999'}).then((res) => {
                                ArntzDatabase.db.resources['inventories'].clear().then(() => ArntzDatabase.db.resources['inventories'].bulkAdd(res.data).then().catch()).catch();
                            }).catch((e) => {
                                setMode('offline');
                                setLoading(false);
                                getOffline()
                            });
                            UserService.getAll('speeds', {'itemsPerPage': '99999999999999999999999999999999'}).then((res) => {
                                ArntzDatabase.db.resources['speeds'].clear().then(() => ArntzDatabase.db.resources['speeds'].bulkAdd(res.data).then().catch()).catch();
                            }).catch((e) => {
                                setMode('offline');
                                setLoading(false);
                                getOffline()
                            });
                            UserService.getAll('track_db_changes', {'itemsPerPage': '1'}).then((res) => {
                                ArntzDatabase.db.resources['track_db_changes'].clear().then(() => ArntzDatabase.db.resources['track_db_changes'].bulkAdd(res.data).then().catch()).catch();
                            }).catch((e) => {
                                setMode('offline');
                                setLoading(false);
                                getOffline()
                            });
                            UserService.getAll('tpms', {'itemsPerPage': '99999999999999999999999999999999'}).then((res) => {
                                ArntzDatabase.db.resources['tpms'].clear().then(() => ArntzDatabase.db.resources['tpms'].bulkAdd(res.data).then().catch()).catch();
                            }).catch((e) => {
                                setMode('offline');
                                setLoading(false);
                                getOffline()
                            });
                            let itemsPerPage = 500;
                            UserService.getAll2('decisions', {'itemsPerPage': itemsPerPage, 'page': 1}).then((res) => {
                                // console.log(res.data)
                                // console.log(res.data['hydra:totalItems'])
                                let lastPage = res.data['hydra:view']['hydra:last'].split('page=')[1];
                                // console.log(lastPage);
                                ArntzDatabase.db.resources['decisions'].clear().then(() => {
                                    ArntzDatabase.db.resources['decisions'].bulkAdd(res.data['hydra:member']).then().catch();
                                    for (let i = 2; i <= lastPage; i++) {
                                        UserService.getAll2('decisions', {'itemsPerPage': itemsPerPage, 'page': i}).then((d) => {
                                            ArntzDatabase.db.resources['decisions'].bulkAdd(d.data['hydra:member']).then().catch();
                                            ArntzDatabase.isSync = true;
                                        }).catch((e) => {
                                            console.log(e)
                                        });
                                    }
                                }).catch();
                                // ArntzDatabase.db.resources['decisions'].clear().then(() => ArntzDatabase.db.resources['decisions'].bulkAdd(res.data['hydra:member']).then().catch()).catch();
                            }).catch((e) => {
                                setMode('offline');
                                setLoading(false);
                                getOffline()
                            });
                    /*if (!ArntzDatabase.isSync) {
                UserService.getAll('models', {
                    'itemsPerPage': '99999999999999999999999999999999'
                }).then((models) => {
                    setLoadingModel(false);
                    // setModels(models.data);
                    // console.log(models.data)
                    ArntzDatabase.db.resources['models'].clear().then(() => ArntzDatabase.db.resources['models'].bulkAdd(models.data).then().catch()).catch();
                }).catch((e) => {
                    setMode('offline');
                    setLoading(false);
                    getOffline()
                });

                UserService.getAll('materials', {'itemsPerPage': '99999999999999999999999999999999'}).then((res) => {
                    setMaterials(res.data); //
                    let l = [];
                    res.data.map((item,k) => (
                            l.push({
                                key: k,
                                "data-number": item.number,
                                "data-din": item.din,
                                "data-name": item.Name,
                                // value: item.Name,
                                value: item.number +"-"+ item.din ,
                                value_en: item.nameEn,
                                label: item.number + " - " + item.din
                            })
                        )
                    )
                    setOpdin(l);
                    console.log('muhu!')
                    ArntzDatabase.db.resources['materials'].clear().then(() => ArntzDatabase.db.resources['materials'].bulkAdd(res.data).then().catch()).catch();
                    $(document).ready(function () {
                        let table = $('#materialDb').DataTable(
                            {
                                responsive: true,
                                language: {
                                    "sEmptyTable": "Keine Daten in der Tabelle vorhanden",
                                    "sInfo": "_START_ bis _END_ von _TOTAL_ Einträgen",
                                    "sInfoEmpty": "Keine Daten vorhanden",
                                    "sInfoFiltered": "(gefiltert von _MAX_ Einträgen)",
                                    "sInfoThousands": ".",
                                    "sLengthMenu": "_MENU_ Einträge anzeigen",
                                    "sLoadingRecords": "Wird geladen ..",
                                    "sProcessing": "Bitte warten ..",
                                    "sSearch": "Suchen",
                                    "sZeroRecords": "Keine Einträge vorhanden",
                                    "oPaginate": {
                                        "sFirst": "Erste",
                                        "sPrevious": "Zurück",
                                        "sNext": "Nächste",
                                        "sLast": "Letzte"
                                    },
                                    "oAria": {
                                        "sSortAscending": ": aktivieren, um Spalte aufsteigend zu sortieren",
                                        "sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
                                    },
                                    "select": {
                                        "rows": {
                                            "_": "%d Zeilen ausgewählt",
                                            "1": "1 Zeile ausgewählt"
                                        }
                                    },
                                    "buttons": {
                                        "print": "Drucken",
                                        "colvis": "Spalten",
                                        "copy": "Kopieren",
                                        "copyTitle": "In Zwischenablage kopieren",
                                        "copyKeys": "Taste <i>ctrl</i> oder <i>\u2318</i> + <i>C</i> um Tabelle<br>in Zwischenspeicher zu kopieren.<br><br>Um abzubrechen die Nachricht anklicken oder Escape drücken.",
                                        "copySuccess": {
                                            "_": "%d Zeilen kopiert",
                                            "1": "1 Zeile kopiert"
                                        },
                                        "pageLength": {
                                            "-1": "Zeige alle Zeilen",
                                            "_": "Zeige %d Zeilen"
                                        }
                                    }
                                },
                                // columnDefs: [
                                //     {
                                //         "targets": [1, 2, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
                                //         "searchable": false
                                //     }
                                // ],
                            }
                        );
                    });


                }).catch((e) => {
                    setMode('offline');
                    setLoading(false);
                    getOffline()
                });

                UserService.getAll('dimensions', {'itemsPerPage': '99999999999999999999999999999999'}).then((res) => {
                    ArntzDatabase.db.resources['dimensions'].clear().then(() => ArntzDatabase.db.resources['dimensions'].bulkAdd(res.data).then().catch()).catch();
                }).catch((e) => {
                    setMode('offline');
                    setLoading(false);
                    getOffline()
                });
                UserService.getAll('inventories', {'itemsPerPage': '99999999999999999999999999999999'}).then((res) => {
                    ArntzDatabase.db.resources['inventories'].clear().then(() => ArntzDatabase.db.resources['inventories'].bulkAdd(res.data).then().catch()).catch();
                }).catch((e) => {
                    setMode('offline');
                    setLoading(false);
                    getOffline()
                });
                UserService.getAll('speeds', {'itemsPerPage': '99999999999999999999999999999999'}).then((res) => {
                    ArntzDatabase.db.resources['speeds'].clear().then(() => ArntzDatabase.db.resources['speeds'].bulkAdd(res.data).then().catch()).catch();
                }).catch((e) => {
                    setMode('offline');
                    setLoading(false);
                    getOffline()
                });
                UserService.getAll('tpms', {'itemsPerPage': '99999999999999999999999999999999'}).then((res) => {
                    ArntzDatabase.db.resources['tpms'].clear().then(() => ArntzDatabase.db.resources['tpms'].bulkAdd(res.data).then().catch()).catch();
                }).catch((e) => {
                    setMode('offline');
                    setLoading(false);
                    getOffline()
                });
                let itemsPerPage = 500;
                UserService.getAll2('decisions', {'itemsPerPage': itemsPerPage, 'page': 1}).then((res) => {
                    // console.log(res.data)
                    // console.log(res.data['hydra:totalItems'])
                    let lastPage = res.data['hydra:view']['hydra:last'].split('page=')[1];
                    // console.log(lastPage);
                    ArntzDatabase.db.resources['decisions'].clear().then(() => {
                        ArntzDatabase.db.resources['decisions'].bulkAdd(res.data['hydra:member']).then().catch();
                        for (let i = 2; i <= lastPage; i++) {
                            UserService.getAll2('decisions', {'itemsPerPage': itemsPerPage, 'page': i}).then((d) => {
                                ArntzDatabase.db.resources['decisions'].bulkAdd(d.data['hydra:member']).then().catch();
                                ArntzDatabase.isSync = true;
                            }).catch((e) => {
                                console.log(e)
                            });
                        }
                    }).catch();
                    // ArntzDatabase.db.resources['decisions'].clear().then(() => ArntzDatabase.db.resources['decisions'].bulkAdd(res.data['hydra:member']).then().catch()).catch();
                }).catch((e) => {
                    setMode('offline');
                    setLoading(false);
                    getOffline()
                });
            }*/

                            }
                        else{
                            setMode('offline');
                            getOffline()
                        }
                    })
                    .catch((e)=>{
                        setMode('offline');
                        getOffline()
                    });
                }
                else{
                    setMode('offline');
                    setLoading(false);
                    getOffline()
                }
                setLoading(false);
            })
            .catch((e) => {
                setMode('offline');
                setLoading(false);
                getOffline()
            });
        }
        else {
            setMode('offline');
            getOffline()
            // ArntzDatabase.isSync =false;
        }


    }, []);
    useEffect(() => {
        fetchData();
    }, []);

    const handleManufacturerChange = (event) => {
        const target = event.target;
        let saw_id = target.options[event.target.selectedIndex].dataset.id;
        setInputVals({
            ...inputVals,
            // selectedManu:target.options[event.target.selectedIndex].value
            selectedManu:saw_id
        })
        if (window.navigator.onLine) {
            setLoading(true);
            UserService.getAll('models', {
                'saw.id': saw_id,
                'itemsPerPage': '99999999999999999999999999999999'
            })
                .then((models) => {
                    setLoading(false);
                    models.data.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
                    setModels(models.data);
                    console.log(models.data);
                    // if (!models.data[0] || [4, 5, 6].includes(models.data[0].dimension.id)) {
                    //     setDimensionHeightMsg("master.dimensionheight")
                    //     setDimensionStrengthMsg("master.dimensionstrength")
                    // } else {
                    //     setDimensionHeightMsg();
                    // }
                    setDimensionHeightMsg();
                    /*UserService.getAll('dimensions', {'height': models.data[0].dimension.height})
                    .then((dimensions) => {
                        console.log(dimensions)
                        if (dimensions.data.length > 0) {
                            // setStrengthSelect([dimensions.data[0]]);
                            if (models.data[0].dimension.height == "54") {
                                dimensions.data = dimensions.data.slice(0, 2).reverse()
                                setStrengthSelect(dimensions.data);
                            } else
                                setStrengthSelect([dimensions.data[0]]);
                            setInputs({
                                length: (models.data.length > 0) ? models.data[0].length : '',
                                height: (dimensions.data.length > 0) ? dimensions.data[0].height : '',
                                strength: (dimensions.data.length > 0) ? dimensions.data[0].strength : '',
                            });
                        }

                    })
                        .catch((err) => {
                            console.log("err")
                        });*/
                    // setInputs({
                    //     length: models.data[0].length,
                    //     height: models.data[0].dimension.height,
                    //     strength: models.data[0].dimension.strength,
                    // });
                    // console.log(models.data)
                    // ArntzDatabase.db.resources['models'].clear().then(() => ArntzDatabase.db.resources['models'].bulkAdd(models.data).then().catch()).catch();
                })
                .catch((e) => {console.log(e) });
        }
        else{
            setMode('offline');
            setLoading(false);
            console.log(saw_id);
            ArntzDatabase.db.resources['models'].filter((model) => {
                return (model.saw) ? model.saw.id == saw_id : false;
            }).toArray().then((models) => {
                models.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
                setModels(models);
                console.log(models);
                setLoading(false);
                // if (!models[0] || [4, 5, 6].includes(models[0].dimension.id)) {
                //     setDimensionHeightMsg("master.dimensionheight")
                //     setDimensionStrengthMsg("master.dimensionstrength")
                // } else {
                //     setDimensionHeightMsg();
                // }

                setDimensionHeightMsg();
                /*ArntzDatabase.db.resources['dimensions'].filter((dimension) => dimension.height === models[0].dimension.height).toArray().then((dimensions) => {
                    console.log(dimensions)
                    if (dimensions.length > 0) {
                        // setStrengthSelect([dimensions.data[0]]);
                        if (models[0].dimension.height == "54") {
                            dimensions = dimensions.slice(0, 2).reverse()
                            setStrengthSelect(dimensions);
                        } else
                            setStrengthSelect([dimensions[0]]);
                        setInputs({
                            length: (models.length > 0) ? models[0].length : '',
                            height: (dimensions.length > 0) ? dimensions[0].height : '',
                            strength: (dimensions.length > 0) ? dimensions[0].strength : '',
                        });
                    }
                }).catch((e) => console.log(e));*/
                // setInputs({
                //     length: (models.length > 0) ? models[0].length : '',
                //     height: (models.length > 0) ? models[0].dimension.height : '',
                //     strength: (models.length > 0) ? models[0].dimension.strength : '',
                // });
            }).catch((e) => console.log(e));
        }
    }
    const handleModelsChange = (event) => {
        const target = event.target;
        let id = target.options[event.target.selectedIndex].dataset.id;
        setInputVals({
            ...inputVals,
            // selectedModel:target.options[event.target.selectedIndex].value
            selectedModel:id
        })
        if(id == "0") return true;
        if (window.navigator.onLine) {
            setLoading(true);
            UserService.get('models', id)
                .then((model) => {
                    console.log(model)
                    setLoading(false);
                    if (!model.data || [4, 5, 6].includes(model.data.dimension.id)) {
                        setDimensionHeightMsg("master.dimensionheight")
                        setDimensionStrengthMsg("master.dimensionstrength")
                    } else {
                        setDimensionHeightMsg();
                    }
                    UserService.getAll('dimensions', {'height': model.data.dimension.height})
                        .then((dimensions) => {
                            console.log(dimensions)
                            if (dimensions.data.length > 0) {
                                // setStrengthSelect([dimensions.data[0]]);
                                if (model.data.dimension.height == "54") {
                                    // dimensions.data = dimensions.data.slice(0, 2).reverse()
                                    // setStrengthSelect(dimensions.data);
                                    setStrengthSelect([model.data.dimension]);
                                } else
                                    setStrengthSelect([dimensions.data[0]]);
                                setInputs({
                                    length: (model.data['length'] > 0) ? model.data['length'] : '',
                                    height: (model.data['dimension']) ? model.data.dimension.height : '',
                                    strength: (model.data['dimension']) ? model.data.dimension.strength : '',
                                    // height: (dimensions.data.length > 0) ? dimensions.data[0].height : '',
                                    // strength: (dimensions.data.length > 0) ? dimensions.data[0].strength : '',
                                });
                            }

                        })
                        .catch((err) => {
                            console.log(err)
                        });

                })
                .catch((e) => console.log(e));
        }
        else{
            setMode('offline');
            setLoading(false);
            ArntzDatabase.db.resources['models'].filter((model) => model.id === parseInt(id)).toArray().then((models) => {
                if (!models[0] || [4, 5, 6].includes(models[0].dimension.id)) {
                    setDimensionHeightMsg("master.dimensionheight")
                    setDimensionStrengthMsg("master.dimensionstrength")
                } else {
                    setDimensionHeightMsg();
                }

                ArntzDatabase.db.resources['dimensions'].filter((dimension) => dimension.height === models[0].dimension.height).toArray().then((dimensions) => {
                    console.log(dimensions)
                    if (dimensions.length > 0) {
                        if (models[0].dimension.height == "54") {
                            // dimensions = dimensions.slice(0, 2).reverse()
                            // setStrengthSelect(dimensions);
                            console.log([models[0].dimension])
                            setStrengthSelect([models[0].dimension]);
                        } else
                            setStrengthSelect([dimensions[0]]);
                        setInputs({
                            length: (models.length > 0) ? models[0].length : '',
                            height: (models[0].dimension) ? models[0].dimension.height : '',
                            strength: (models[0].dimension) ? models[0].dimension.strength : '',
                            // height: (dimensions.length > 0) ? dimensions[0].height : '',
                            // strength: (dimensions.length > 0) ? dimensions[0].strength : '',
                        });
                    }
                }).catch((e) => console.log(e));
            }).catch((e) => console.log(e));
        }

    }
    const handleMaterialClickNew = (select) => {
        console.log(select);
        setSelectedMat({
            din: select["data-din"],
            number: select["data-number"],
            // name: select["value"],
            name: select["data-name"],
            name_en: select["value_en"]
        })
    }
    const handleMaterialClickTb = (event) => {
        const target = event.target;
        const number = target.options[event.target.selectedIndex].dataset.number;
        const din = target.options[event.target.selectedIndex].dataset.din;
        const name = target.options[event.target.selectedIndex].value;

        setSelectedMat({
            din: din,
            number: number,
            name: name
        })
    }
    const clickOne = (event) => {
        let el = document.getElementById('v-pills-home-tab');
        el.click();
    }
    const clickTwo = (event) => {
        let el = document.getElementById('v-pills-profile-tab');
        el.click();
    }
    const clickThree = (event) => {
        let el = document.getElementById('v-pills-messages-tab');
        el.click();
    }
    const clickFour = (event) => {
        let el = document.getElementById('v-pills-settings-tab');
        el.click();
    }
    const clickFive = (event) => {
        let el = document.getElementById('v-pills-costs-tab');
        el.click();
    }
    const handleMaterialClickTb1 = (event) => {
        event.preventDefault();
        const target = event.target;
        const number = target.dataset.number;
        const din = target.dataset.din;
        const name = target.dataset.name;
        const name_en = target.dataset.name_en;
        setSelectedMat({
            din: din,
            number: number,
            name: name,
            name_en: name_en
        })
        $('#materialDb td button').removeClass("button-table");
        target.classList.add('button-table');
        return true;
    }

    const onChangeBand = (e) => {
        let height = e.target.value;
        if (window.navigator.onLine) {
            UserService.getAll('dimensions', {'height': height})
                .then((dimensions) => {
                    if (dimensions.data.length > 0) {
                        if (height == "54") {
                            dimensions.data = dimensions.data.slice(0, 2).reverse()
                            setStrengthSelect(dimensions.data);
                        } else
                            setStrengthSelect([dimensions.data[0]]);

                        setInputs({
                            length: inputs.length,
                            height: height,
                            strength: dimensions.data[0].strength
                        })
                    } else {
                        setStrengthSelect([]);
                        setInputs({
                            length: inputs.length,
                            height: height,
                            strength: inputs.strength
                        })
                    }
                })
                .catch((err) => console.log(err));
        }
        else{
            setMode('offline');
            setLoading(false);
            ArntzDatabase.db.resources['dimensions'].filter((dimension) => dimension.height === parseInt(height)).toArray().then((dimensions) => {
                console.log(dimensions)
                if (dimensions.length > 0) {
                    if (height == "54") {
                        dimensions = dimensions.slice(0, 2).reverse()
                        setStrengthSelect(dimensions);
                    } else
                        setStrengthSelect([dimensions[0]]);

                    setInputs({
                        length: inputs.length,
                        height: height,
                        strength: dimensions[0].strength
                    })
                } else {
                    setStrengthSelect([]);
                    setInputs({
                        length: inputs.length,
                        height: height,
                        strength: inputs.strength
                    })
                }
            }).catch((e) => console.log(e));
        }
    }

    return (
        (state.redirect) ?
            <Redirect to={state.redirect}/>
            :
            (!state.currentUser.masterCalc) ?
                <Redirect to="/home"/>
                :
                <div>
                    {/*{mode === 'offline' &&*/}
                    {/*<div className="alert alert-warning" role="alert">*/}
                    {/*    {mode}*/}
                    {/*</div>*/}
                    {/*}*/}
                    <form onSubmit={submitForm} id='productForm' name='productForm' className="mb-3">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                                     aria-orientation="vertical">
                                    <a className={activeTab ==="v-pills-home"?"nav-link active":"nav-link"} id="v-pills-home-tab" data-toggle="pill"
                                       href="#v-pills-home"
                                       role="tab" aria-controls="v-pills-home"
                                       aria-selected={activeTab ==="v-pills-home"}>{t('master.saw')}</a>
                                    <a className={activeTab ==="v-pills-profile"?"nav-link active":"nav-link"} id="v-pills-profile-tab" data-toggle="pill"
                                       href="#v-pills-profile"
                                       role="tab" aria-controls="v-pills-profile"
                                       aria-selected={activeTab ==="v-pills-profile"}>{t('master.type')}</a>
                                    <a className={activeTab ==="v-pills-messages"?"nav-link active":"nav-link"} id="v-pills-messages-tab" data-toggle="pill"
                                       href="#v-pills-messages"
                                       role="tab" aria-controls="v-pills-messages"
                                       aria-selected={activeTab ==="v-pills-messages"}>{t('master.dimension')}</a>
                                    <a className={activeTab ==="v-pills-settings"?"nav-link active":"nav-link"} id="v-pills-settings-tab" data-toggle="pill"
                                       href="#v-pills-settings"
                                       role="tab" aria-controls="v-pills-settings"
                                       aria-selected={activeTab ==="v-pills-settings"}>{t('master.result')}</a>

                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="tab-content" id="v-pills-tabContent">
                                    <div className={activeTab ==="v-pills-home"?"tab-pane fade show active":"tab-pane fade "}  id="v-pills-home" role="tabpanel"
                                         aria-labelledby="v-pills-home-tab">
                                        <div className="row">
                                            <div className="col-sm group-bg-card">
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label htmlFor="decision">{t('master.manu')}</label>
                                                        <select className="form-control wlb"
                                                                disabled={masse}
                                                                id="decision"
                                                                name="dimension.models.saw.manufacturer"
                                                                placeholder="Sägemaschinen-Hersteller"
                                                                data-trans="master.manu"
                                                                onChange={handleManufacturerChange}
                                                        >
                                                            <option key={1} data-id={"0"} value={"0"}>{t('misc.select')}</option>
                                                            {manufacturer && manufacturer.map((item) => (
                                                                <option key={item.id}
                                                                        data-id={item.id}
                                                                        value={item.manufacturer} selected={item.id == inputVals.selectedManu}>{item.manufacturer}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="decision.models">{t('master.sawtype')}</label>

                                                        <select className="form-control wlb"
                                                                id="decision"
                                                                name="dimension.models.name"
                                                                disabled={masse}
                                                                placeholder="Sägemaschinen-Typ"
                                                                data-trans="master.sawtype"
                                                                onChange={handleModelsChange}>
                                                            <option key={1} data-id={"0"} value={"0"}>{t('misc.select')}</option>
                                                            {models && models.map((item) => (
                                                                <option key={item.id} data-id={item.id}
                                                                        value={item.name} selected={item.id == inputVals.selectedModel}>{item.name}</option>
                                                            ))}
                                                        </select>
                                                        {/*}*/}
                                                    </div>
                                                </div>

                                                {dimensionHeightMsg ?
                                                    <div className="row pl-3 mb-3  text-danger dimension-msg" >{t(dimensionHeightMsg)}</div>
                                                :
                                                    <div>
                                                        <div className="form-check mb-3">
                                                            <input type="checkbox" className="form-check-input" name="check"
                                                                   id="check"
                                                                   checked={inputVals.setting === true}
                                                                   onChange={(e) => {
                                                                       setMasse(e.target.checked);
                                                                       setInputVals({
                                                                           ...inputVals,
                                                                           setting:e.target.checked
                                                                       })
                                                                   }}/>
                                                            <label className="form-check-label"
                                                                   htmlFor="check">{t('master.settings')}</label>
                                                        </div>
                                                        <div className="row">
                                                            <div className="form-group col-sm-3 mb-1">
                                                                <label htmlFor="decision.length">{t('master.length')}</label>
                                                                <input type="number" className="form-control wlb"
                                                                       id="decision"
                                                                       name='dimension.models.length'
                                                                       aria-describedby="dimension.models.length"
                                                                       placeholder={t('master.length')}
                                                                       data-trans="master.length"
                                                                       value={inputs.length}
                                                                       onChange={(e) => {
                                                                           setInputs({
                                                                               length: e.target.value,
                                                                               height: inputs.height,
                                                                               strength: inputs.strength
                                                                           })
                                                                       }}
                                                                       disabled={!masse}
                                                                       onKeyDown={(e)=>{
                                                                           if (e.keyCode == "13") {
                                                                               e.preventDefault();
                                                                               let el = document.getElementById('v-pills-profile-tab');
                                                                               el.click();
                                                                               return false;
                                                                           }
                                                                       }}

                                                                />
                                                            </div>
                                                            <div className="form-group col-sm-3 mb-1">
                                                                <label htmlFor="decision.height">{t('master.width')}</label>
                                                                <select className="form-control wlb"
                                                                        name='dimension.height'
                                                                        id="decision"
                                                                        aria-describedby="dimension.height"
                                                                        placeholder="Sägeband-Breite (mm)"
                                                                        data-trans="master.width"
                                                                        value={inputs.height}
                                                                        onChange={onChangeBand}

                                                                        disabled={!masse}
                                                                >
                                                                    <option key={1} value='0'>{t('misc.select')}</option>
                                                                    {/*<option key={1} value={20}>{20}</option>*/}
                                                                    <option key={2} value={27}>{27}</option>
                                                                    <option key={3} value={34}>{34}</option>
                                                                    <option key={4} value={41}>{41}</option>
                                                                    <option key={5} value={54}>{54}</option>
                                                                    <option key={6} value={67}>{67}</option>
                                                                    <option key={7} value={80}>{80}</option>
                                                                </select>
                                                                {/*{dimensionHeightMsg &&
                                                                <small className="form-text text-danger" >{t(dimensionHeightMsg)}</small>
                                                            }*/}
                                                                {/*<input type="number" className="form-control wlb"
                                                                   name='dimension.height'
                                                                   id="decision"
                                                                   aria-describedby="dimension.height"
                                                                   placeholder="Sägeband-Breite (mm)"
                                                                   value={inputs.height}
                                                                   onChange={(e) => {
                                                                       setInputs({
                                                                           length: inputs.length,
                                                                           height: e.target.value,
                                                                           strength: inputs.strength
                                                                       })
                                                                   }}
                                                                   disabled={!masse}
                                                            />*/}
                                                            </div>
                                                            <div className="form-group col-sm-3 mb-1">
                                                                <label
                                                                    htmlFor="decision.strength">{t('master.thinkness')}</label>
                                                                <select className="form-control wlb"
                                                                        id="decision"
                                                                        name="dimension.strength"
                                                                        placeholder="Sägeband-Dicke (mm)"
                                                                        data-trans='master.thinkness'
                                                                        value={inputs.strength}
                                                                        onChange={(e) => {
                                                                            setInputs({
                                                                                length: inputs.length,
                                                                                height: inputs.height,
                                                                                strength: e.target.value,
                                                                            })
                                                                        }}
                                                                        disabled={!masse}
                                                                >
                                                                    <option key={1} value='0'>{t('misc.select')}</option>
                                                                    {strengthSelect.map((dimen,k) => {
                                                                        return <option key={k} value={dimen.strength} selected={inputs.strength == dimen.strength}>{dimen.strength}</option>
                                                                    }) }

                                                                    {/*<option key={2} value={1.1}>{'1,1'}</option>*/}
                                                                    {/*<option key={3} value={1.3}>{'1,3'}</option>*/}
                                                                    {/*<option key={4} value={1.6}>{'1,6'}</option>*/}
                                                                </select>
                                                                {/*{dimensionStrengthMsg &&
                                                                <small className="form-text text-danger" >{t(dimensionStrengthMsg)}</small>
                                                            }*/}
                                                                {/*<input type="number" className="form-control wlb"
                                                                   id='decision'
                                                                   step="0.01"
                                                                   name="dimension.strength"
                                                                   aria-describedby="dimension.strength"
                                                                   placeholder="Sägeband-Dicke (mm)"
                                                                   value={inputs.strength}
                                                                   onChange={(e) => {
                                                                       setInputs({
                                                                           length: inputs.length,
                                                                           height: inputs.height,
                                                                           strength: e.target.value,
                                                                       })
                                                                   }}
                                                                   disabled={!masse}
                                                            />*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-12">
                                                        <strong>{t('misc.selectedMat')}</strong>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-2 col-md-1 col-xs-2">
                                                                <strong>{t('misc.name')}</strong>
                                                            </div>
                                                            <div className="col-4">
                                                                {(i18n.language.includes("de")) ? selectedMat.name:selectedMat.name_en}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-2 col-md-1 col-xs-2">
                                                                <strong>{t('misc.number')}</strong>
                                                            </div>
                                                            <div className="col-4">
                                                                {selectedMat.number}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-2 col-md-1 col-xs-2">
                                                                <strong>{t('misc.din')}</strong>
                                                            </div>
                                                            <div className="col-4">
                                                                {selectedMat.din}
                                                            </div>
                                                        </div>
                                                            {/*<div className="col-2">
                                                                <strong>{(i18n.language.includes("de")) ? selectedMat.name:selectedMat.name_en}</strong><br/>
                                                                <strong>{selectedMat.number}</strong><br/>
                                                                <strong>{selectedMat.din}</strong>
                                                            </div>*/}

                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="form-group">
                                                    <label htmlFor="decisionDin">{t('master.din')}</label>
                                                    <Select className=""
                                                            id="decisionDin"
                                                            name="materialGroup.materials.din"
                                                            // placeholder={t('master.din')}
                                                            placeholder={t('misc.select')}
                                                            data-trans="master.din"
                                                            defaultValue="ss"
                                                            // value={selectedMat && selectedMat.name}
                                                            onChange={(e) => {
                                                                // handleMaterialClickNew(e);

                                                                console.log(e);
                                                                setSelectedMat({
                                                                    din: e["data-din"],
                                                                    number: e["data-number"],
                                                                    // name: select["value"],
                                                                    name: e["data-name"],
                                                                    name_en: e["value_en"]
                                                                })
                                                            }}
                                                            onKeyDown={(e)=>{
                                                                if (e.keyCode == "13") {
                                                                    e.preventDefault();
                                                                    return false;
                                                                }
                                                            }}
                                                            options={opdin}/>

                                                </div>

                                                <fieldset className="border p-2">
                                                    <legend>{t('misc.materialsearch')}:</legend>

                                                    {/*<div className="form-group">*/}
                                                    {/*    <label htmlFor="decision.strength">{t('misc.after')}</label>*/}
                                                    {/*    <Select options={options} onChange={(e) => {*/}
                                                    {/*        setField(e.label);*/}
                                                    {/*        let arr = materials*/}
                                                    {/*            .map(material => {*/}
                                                    {/*                return {*/}
                                                    {/*                    'value': material[e.label],*/}
                                                    {/*                    'label': material[e.label]*/}
                                                    {/*                }*/}
                                                    {/*            })*/}
                                                    {/*            .filter((thing, index, self) =>*/}
                                                    {/*                index === self.findIndex((t) => (*/}
                                                    {/*                    t.value === thing.value*/}
                                                    {/*                )));*/}
                                                    {/*        setFieldOptions(arr);*/}
                                                    {/*    }}/>*/}
                                                    {/*</div>*/}
                                                    {/*<div className="form-group">*/}
                                                    {/*    <label htmlFor="decision.strength">{t('misc.term')}</label>*/}
                                                    {/*    <Select options={fieldOptions} onChange={(e) => {*/}
                                                    {/*        setFieldRes(materials.filter((material) => material[field] === e.value));*/}
                                                    {/*    }}/>*/}
                                                    {/*</div>*/}
                                                    {/*<div className="form-group">*/}

                                                    {/*    {fieldRes.length > 0 &&*/}
                                                    {/*    <div>*/}
                                                    {/*        <label htmlFor="decision.strength">Suchergebnis</label>*/}
                                                    {/*        <table className="table table-striped table-bordered w-100">*/}
                                                    {/*            <thead className="thead-dark">*/}
                                                    {/*            <tr>*/}
                                                    {/*                <th>id</th>*/}
                                                    {/*                <th>name</th>*/}
                                                    {/*                <th>number</th>*/}
                                                    {/*                <th>din</th>*/}
                                                    {/*            </tr>*/}
                                                    {/*            </thead>*/}
                                                    {/*            <tbody>*/}
                                                    {/*            {fieldRes.map((m) => (*/}
                                                    {/*                <tr key={m.id}>*/}
                                                    {/*                    <td>*/}
                                                    {/*                        <button*/}
                                                    {/*                            onClick={handleMaterialClickTb1}*/}
                                                    {/*                            data-number={m.number}*/}
                                                    {/*                            data-din={m.din}*/}
                                                    {/*                            data-name={m.Name}*/}
                                                    {/*                            className="pl-2 pr-2 mb-2 btn btn-primary"*/}
                                                    {/*                        >{m.id}*/}
                                                    {/*                        </button>*/}
                                                    {/*                    </td>*/}
                                                    {/*                    <td>{m.Name}</td>*/}
                                                    {/*                    <td>{m.number}</td>*/}
                                                    {/*                    <td>{m.din}</td>*/}
                                                    {/*                </tr>*/}


                                                    {/*            ))}*/}
                                                    {/*            </tbody>*/}
                                                    {/*        </table>*/}
                                                    {/*    </div>*/}
                                                    {/*    }*/}
                                                    {/*</div>*/}


                                                    <div className="form-group">
                                                        <input type="text"
                                                               className="form-control wlb"
                                                               id='search'
                                                               name="inputSearch"
                                                               // placeholder="Suche"
                                                               placeholder={t('misc.search')}
                                                               value={searchInput}
                                                               tabIndex="0"
                                                               onKeyDown={(e)=>{
                                                                   if (e.keyCode == "13") {
                                                                       e.preventDefault();
                                                                       // let el = document.getElementById('v-pills-profile-tab');
                                                                       // el.click();
                                                                       return false;
                                                                   }
                                                               }}
                                                               onChange={(e) => {
                                                                   setSearchInput(e.target.value)
                                                                   if (e.target.value !== "") {
                                                                       // $('#materialDb').DataTable().column(field).search(e.target.value).draw();
                                                                       // $('#materialDb').DataTable().search('\\b' +e.target.value + '\\b', true, false).draw();
                                                                       // $('#materialDb').DataTable().search(e.target.value, true, false).draw();
                                                                       let s = e.target.value;
                                                                       if(/\d/.test(s)){ //&& /\s/g.test(s) === false
                                                                           s = s.replace(/\s/g, '');
                                                                           // s = s+"|"+s.replace(/([0-9]+)/g, ' $1');
                                                                           s = s+"|"+s.replace(/([A-Za-z]+)/g, ' $1 ').trim();
                                                                           console.log(s);
                                                                       }
                                                                       $('#materialDb').DataTable().search(s, true, false).draw();
                                                                       $('#divDataTable').show();
                                                                   } else {
                                                                       $('#divDataTable').hide();
                                                                   }
                                                               }}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        {materials && materials.length > 0 &&
                                                        <div className="form-group" id={"divDataTable"}
                                                             style={{'display': 'none', "overflowX": "scroll"}}>
                                                            <table className="table table-striped table-bordered w-100"
                                                                   id="materialDb">
                                                                <thead className="thead-dark">
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Werkstoff</th>
                                                                    <th>Nummer</th>
                                                                    <th>DIN</th>
                                                                    <th>SKILL</th>
                                                                    <th>ASTM / SEA / AISI</th>
                                                                    <th>GOST</th>
                                                                    <th>JIS</th>
                                                                    <th>BS</th>
                                                                    <th>SS</th>
                                                                    <th>EURO Norm</th>
                                                                    <th>AFNOR</th>
                                                                    <th>UNE</th>
                                                                    <th>UNI</th>
                                                                    <th>UNS</th>
                                                                    <th>BÖHLER</th>
                                                                    <th>DILLINGER HÜTTE</th>
                                                                    <th>HKM</th>
                                                                    <th>OUTOKUMPO</th>
                                                                    <th>OVAKO</th>
                                                                    <th>SANDVIK</th>
                                                                    <th>SALZGITTER</th>
                                                                    <th>SPECIAL METALS</th>
                                                                    <th>SSAB</th>
                                                                    <th>THYSSENKRUPP</th>
                                                                    <th>UDDEHOLM</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {materials.map((m) => (
                                                                    <tr key={m.id}
                                                                        // onClick={handleMaterialClickTb1}
                                                                        // data-number={m.number}
                                                                        // data-din={m.din}
                                                                        // data-name={m.Name}
                                                                    >
                                                                        <td>
                                                                            <button onClick={handleMaterialClickTb1}
                                                                                       data-number={m.number}
                                                                                       data-din={m.din}
                                                                                       data-name={m.Name}
                                                                                    // className="pl-2 pr-2 mb-2 btn btn-primary"></button>
                                                                                    className="p-2 btn btn-primary"></button>
                                                                        </td>
                                                                        <td>{m.Name}</td>
                                                                        <td>{m.number}</td>
                                                                        <td>{m.din}</td>
                                                                        <td>{m.skill}</td>
                                                                        <td>{m.astmSaeAisi}</td>
                                                                        <td>{m.gost}</td>
                                                                        <td>{m.jis}</td>
                                                                        <td>{m.bs}</td>
                                                                        <td>{m.ss}</td>
                                                                        <td>{m.euroNorm}</td>
                                                                        <td>{m.afnor}</td>
                                                                        <td>{m.une}</td>
                                                                        <td>{m.uni}</td>
                                                                        <td>{m.uns}</td>
                                                                        <td>{m.boehler}</td>
                                                                        <td>{m.dillingerHuette}</td>
                                                                        <td>{m.hkm}</td>
                                                                        <td>{m.outokumpo}</td>
                                                                        <td>{m.ovako}</td>
                                                                        <td>{m.sandvik}</td>
                                                                        <td>{m.salzgitter}</td>
                                                                        <td>{m.specialMetals}</td>
                                                                        <td>{m.ssab}</td>
                                                                        <td>{m.thyssenKrupp}</td>
                                                                        <td>{m.uddeholm}</td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        }
                                                    </div>
                                                </fieldset>
                                                <div className="clearfix">
                                                    <a onClick={clickTwo}
                                                       className={dimensionHeightMsg ? "pl-2 pr-2 mb-2 btn btn-primary float-right disabled" :"pl-2 pr-2 mb-2 btn btn-primary float-right" } >{t('master.next')}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={activeTab ==="v-pills-profile"?"tab-pane fade show active":"tab-pane fade "} id="v-pills-profile" role="tabpanel"
                                         aria-labelledby="v-pills-profile-tab">
                                        <div className="row">
                                            <div className="group-bg-card col-sm">
                                                <div className="col-sm p-0">
                                                    <fieldset className="border p-2">
                                                        <legend className="w-auto">{t('master.wtype')}</legend>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio"
                                                                   name="decision.materialart"
                                                                   id="decision.materialart"
                                                                   value="fullMaterial"
                                                                   placeholder="VollMaterial"
                                                                   data-trans="master.full"
                                                                   // defaultChecked
                                                                   checked={materialTyp ==="fullMaterial"}
                                                                   onChange={event => {
                                                                       setMaterialTyp(event.target.value);
                                                                       setSpe('ru');
                                                                       setSpecification('Rund');
                                                                       setHolderTyp('');
                                                                       setInputVals({
                                                                           ...inputVals,
                                                                           wallThickness: "",
                                                                           outerDiameter: ""
                                                                       })
                                                                   }}
                                                            />
                                                            <label className="form-check-label" htmlFor="fullMaterial">
                                                                {t('master.full')}
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio"
                                                                   name="decision.materialart"
                                                                   id="decision.materialart"
                                                                   // value="profile"
                                                                   value="profile"
                                                                   placeholder="Profile"
                                                                   data-trans="master.profile"
                                                                   checked={materialTyp ==="profile"}
                                                                   onChange={event => {
                                                                       setMaterialTyp(event.target.value);
                                                                       setSpecification('Täger');
                                                                       setHolderTyp('I-PE');
                                                                       setSpe("ip")
                                                                       setInputVals({
                                                                           ...inputVals,
                                                                           dia: "",
                                                                           side:  "",
                                                                           height:  "",
                                                                           width:  "",
                                                                       })
                                                                   }}
                                                            />
                                                            <label className="form-check-label" htmlFor="profile">
                                                                {t('master.profile')}
                                                            </label>
                                                        </div>

                                                    </fieldset>
                                                </div>
                                                <div className="row mb-4">
                                                    <div className="col-sm" hidden={materialTyp === 'fullMaterial'}>
                                                        <fieldset className="border p-2"
                                                                  hidden={materialTyp === 'fullMaterial'}
                                                                  onClick={(e) => {
                                                                      if ($(e.target).find('input[type="checkbox"]').length > 0) {
                                                                          e.target.value = 'Täger';
                                                                      }
                                                                      setSpecification(e.target.value);
                                                                      if (e.target.value === 'Täger') {
                                                                          setHolderTyp('I-PE');
                                                                          setSpe("ip")
                                                                      } else {
                                                                          setHolderTyp('');
                                                                      }


                                                                  }}>
                                                            <legend className="w-auto">{t('master.form')}
                                                                <br/>({t('master.profile')})
                                                            </legend>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox"
                                                                       name="specification.type" id="holder"
                                                                       value="Täger"
                                                                       checked={(materialTyp === 'profile' && ['I-PE', 'HE-A', 'HE-B', 'HE-M'].includes(holderTyp)) || inputVals.steel ===true}
                                                                       placeholder="Täger"
                                                                       // data-trans="master.steel"
                                                                       onChange={(e) => {
                                                                           setInputVals({
                                                                               ...inputVals,
                                                                               steel:e.target.checked,
                                                                               lsv: "",
                                                                               usv: "",
                                                                               ip:"",
                                                                               hea:"",
                                                                               heb:"",
                                                                               hem:"",
                                                                           })
                                                                       }}
                                                                />
                                                                <label className="form-check-label" htmlFor="holder">
                                                                    {t('master.steel')}
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       name="specification.type"
                                                                       id="uSteel" value="U"
                                                                       placeholder="U-Stahl"
                                                                       data-trans="master.usteel"
                                                                       checked={spe ==="us"}
                                                                       onChange={(e) => {
                                                                           setSpe("us");
                                                                           setInputVals({
                                                                               ...inputVals,
                                                                               steel: false,
                                                                               lsv: "",
                                                                               usv: "",
                                                                               ip:"",
                                                                               hea:"",
                                                                               heb:"",
                                                                               hem:"",
                                                                           })
                                                                           setHolderTyp('')
                                                                           setWallThickness("")

                                                                       }}

                                                                />
                                                                <label className="form-check-label" htmlFor="uSteel">
                                                                    {t('master.usteel')}
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       name="specification.type" id="lSteel" value="L"
                                                                       placeholder="L-Stahl"
                                                                       data-trans="master.lsteel"
                                                                       checked={spe ==="ls"}
                                                                       onChange={(e) => {
                                                                           setSpe("ls");
                                                                           setInputVals({
                                                                               ...inputVals,
                                                                               steel: false,
                                                                               lsv: "",
                                                                               usv: "",
                                                                               ip:"",
                                                                               hea:"",
                                                                               heb:"",
                                                                               hem:"",
                                                                           })
                                                                           setHolderTyp('')
                                                                           setWallThickness("")
                                                                       }}
                                                                />
                                                                <label className="form-check-label" htmlFor="lSteel">
                                                                    {t('master.lsteel')}
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       name="specification.type" id="pipe" value="Rohr"
                                                                       placeholder="Rohr"
                                                                       data-trans="master.round"
                                                                       checked={spe ==="ro"}
                                                                       onChange={(e) => {
                                                                           setSpe("ro");
                                                                           setInputVals({
                                                                               ...inputVals,
                                                                               steel: false,
                                                                               lsv: "",
                                                                               usv: "",
                                                                               ip:"",
                                                                               hea:"",
                                                                               heb:"",
                                                                               hem:"",
                                                                           })
                                                                           setHolderTyp('')
                                                                       }}
                                                                />
                                                                <label className="form-check-label" htmlFor="pipe">
                                                                    {t('master.round')}
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       name="specification.type" id="squirePipe"
                                                                       value="Vierkantrohr"
                                                                       placeholder="Vierkantrohr"
                                                                       data-trans="master.four"
                                                                       checked={spe ==="vi"}
                                                                       onChange={(e) => {
                                                                           setSpe("vi");
                                                                           setInputVals({
                                                                               ...inputVals,
                                                                               steel: false,
                                                                               lsv: "",
                                                                               usv: "",
                                                                               ip:"",
                                                                               hea:"",
                                                                               heb:"",
                                                                               hem:"",
                                                                           })
                                                                           setHolderTyp('')
                                                                       }}
                                                                />
                                                                <label className="form-check-label"
                                                                       htmlFor="squirePipe">
                                                                    {t('master.four')}
                                                                </label>
                                                            </div>
                                                        </fieldset>

                                                    </div>
                                                    <div className="col-sm" hidden={specification !== 'Täger' && !["ip","hea","heb","hem"].includes(spe)}>
                                                        <fieldset className="border p-2"
                                                                  hidden={specification !== 'Täger' && !["ip","hea","heb","hem"].includes(spe)}
                                                                  onChange={(e) => {
                                                                      setHolderTyp(e.target.value);
                                                                      // setSpecification('');
                                                                      setWallThickness("")
                                                                  }}>
                                                            <legend
                                                                className="w-auto">{t('master.steel')}<br/>({t('misc.typ')})
                                                            </legend>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       name="specification.type" id="ipeHolder"
                                                                       value="I-PE"
                                                                       checked={holderTyp === 'I-PE' || spe==="ip"}
                                                                       placeholder="I-PE"
                                                                       // data-trans="misc.typ"
                                                                       data-trans="I-PE"
                                                                       onChange={(e) => {
                                                                           setSpe("ip");
                                                                           setWallThickness("")
                                                                       }}
                                                                />
                                                                <label className="form-check-label" htmlFor="ipeHolder">
                                                                    IP-E
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       name="specification.type" id="heaHolder"
                                                                       value="HE-A"
                                                                       placeholder="HE-A"
                                                                       // data-trans="misc.typ"
                                                                       data-trans="HE-A"
                                                                       checked={spe ==="hea"}
                                                                       onChange={(e) => {
                                                                           setSpe("hea");
                                                                           setWallThickness("")
                                                                       }}
                                                                />
                                                                <label className="form-check-label" htmlFor="heaHolder">
                                                                    HE-A
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       name="specification.type" id="hebHolder"
                                                                       value="HE-B"
                                                                       placeholder="HE-B"
                                                                       // data-trans="misc.typ"
                                                                       data-trans="HE-B"
                                                                       checked={spe ==="heb"}
                                                                       onChange={(e) => {
                                                                           setSpe("heb");
                                                                           setWallThickness("")
                                                                       }}
                                                                />
                                                                <label className="form-check-label" htmlFor="hebHolder">
                                                                    HE-B
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       name="specification.type" id="hemHolder"
                                                                       value="HE-M"
                                                                       placeholder="HE-M"
                                                                       // data-trans="misc.typ"
                                                                       data-trans="HE-M"
                                                                       checked={spe ==="hem"}
                                                                       onChange={(e) => {
                                                                           setSpe("hem");
                                                                           setWallThickness("")
                                                                       }}
                                                                />
                                                                <label className="form-check-label" htmlFor="hemHolder">
                                                                    HE-M
                                                                </label>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                    <div className="col-sm">
                                                        <fieldset className="border p-2"
                                                                  hidden={materialTyp === 'profile'}
                                                                  onChange={(e) => {
                                                                      setSpecification(e.target.value);
                                                                      setHolderTyp('');
                                                                  }}>
                                                            <legend
                                                                className="w-auto">{t('master.form')}<br/> ({t('master.full')})
                                                            </legend>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       name="specification.type" id="decision"
                                                                       value="Rund"
                                                                       checked={materialTyp === 'fullMaterial' && (spe === '' || spe === 'ru')}
                                                                       placeholder="Rund"
                                                                       data-trans="master.round2"
                                                                       onChange={(e) => {
                                                                           setSpe('ru')
                                                                       }}
                                                                />
                                                                <label className="form-check-label" htmlFor="round">
                                                                    {t('master.round2')}
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       name="specification.type" id="decision"
                                                                       value="Quadrat"
                                                                       placeholder="Quadrat"
                                                                       data-trans="master.square"
                                                                       checked={spe ==="q"}
                                                                       onChange={(e) => {
                                                                           setSpe('q')
                                                                       }}
                                                                />
                                                                <label className="form-check-label" htmlFor="square">
                                                                    {t('master.square')}
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       name="specification.type" id="decision"
                                                                       value="Rechteck"
                                                                       placeholder="Rechteck"
                                                                       data-trans="master.tangle"
                                                                       checked={spe ==="re"}
                                                                       onChange={(e) => {
                                                                           setSpe('re')
                                                                       }}

                                                                />
                                                                <label className="form-check-label" htmlFor="rectangle">
                                                                    {t('master.tangle')}
                                                                </label>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className="clearfix">
                                                    <a onClick={clickOne}
                                                       className="pl-2 pr-2 mb-2 btn btn-primary float-left">{t('master.back')}</a>
                                                    <a onClick={clickThree}
                                                       className="pl-2 pr-2 mb-2 btn btn-primary float-right">{t('master.next')}</a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={activeTab ==="v-pills-messages"?"tab-pane fade show active":"tab-pane fade "} id="v-pills-messages" role="tabpanel"
                                         aria-labelledby="v-pills-messages-tab">
                                        <div className="row">
                                            <div className="group-bg-card col-sm">
                                                <fieldset className="border p-2">
                                                    <legend className="w-auto">{t('master.wab')}</legend>
                                                    <div hidden={spe !== "ru"}>
                                                        <div className="form-group row">
                                                            {/*<label htmlFor="sideLength" className="col-sm-2">Durchmesser</label>*/}
                                                            <div className="col-sm-10">
                                                                <label htmlFor="decision">{t('master.dia')+ (unit ==='meter-cm' ?  ' (mm)': ' (inch)')}</label>
                                                                {/*<label htmlFor="decision">{t('misc.dimension')+ (unit ==='meter-cm' ?  ' (mm)': ' (inch)')}</label>*/}
                                                                <input
                                                                        type="text"
                                                                        // type="number"
                                                                        className="form-control wlb"
                                                                       id="decision"
                                                                    // name="specification.diameter"
                                                                       name="specification.thickness"
                                                                       placeholder={t('master.dia')}
                                                                       // placeholder={t('misc.dimension')}
                                                                       data-trans="master.dia"
                                                                       // step="0.0000001"
                                                                       hidden={spe !== "ru"}
                                                                       value={inputVals.dia}
                                                                       onChange={(e) => {
                                                                           setVal(e.target.value);
                                                                           setInputVals({
                                                                               ...inputVals,
                                                                               // dia: (unit ==='meter-cm') ? e.target.value : parseFloat(e.target.value) / 0.00008
                                                                               dia: e.target.value
                                                                           })
                                                                       }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div hidden={spe !== "q" }>
                                                        <div className="form-group row">
                                                            {/*<label htmlFor="sideLength" className="col-sm-2">Seitenlänge</label>*/}
                                                            <div className="col-sm-10">
                                                                {/*<label htmlFor="decision">{t('master.side')+ (unit ==='meter-cm' ?  ' (mm)': ' (inch)')}</label>*/}
                                                                <label htmlFor="decision">{t('misc.dimension')+ (unit ==='meter-cm' ?  ' (mm)': ' (inch)')}</label>
                                                                <input
                                                                       type="text"
                                                                       // type="number"
                                                                       className="form-control wlb"
                                                                       id="decision"
                                                                    // name="specification.sideLength"
                                                                       name="specification.thickness"
                                                                       // placeholder={t('master.side')}
                                                                       placeholder={t('misc.dimension')}
                                                                       data-trans="master.side"
                                                                       // step="0.0000001"
                                                                       hidden={spe !== "q"}
                                                                       value={inputVals.side}
                                                                       onChange={(e) => {
                                                                           setVal(e.target.value);
                                                                           setInputVals({
                                                                               ...inputVals,
                                                                               // side: (unit ==='meter-cm') ? e.target.value : parseFloat(e.target.value) / 0.00008
                                                                               side: e.target.value
                                                                           })
                                                                       }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div hidden={spe !== "re" }>
                                                        <div className="form-group row">
                                                            {/*<label htmlFor="inputEmail3" className="col-sm-2">Höhe</label>*/}
                                                            <div className="col-sm-10">
                                                                <label htmlFor="decision">{t('master.width2')+ (unit ==='meter-cm' ?  ' (mm)': ' (inch)')}</label>
                                                                <input
                                                                    // type="number"
                                                                    type="text"
                                                                    className="form-control wlb"
                                                                       id="decision"
                                                                    // name="specification.height"
                                                                       name="specification.thickness"
                                                                       placeholder={t('master.width2')}
                                                                       data-trans="master.width2"
                                                                       // step="0.0000001"
                                                                       hidden={spe !== "re"}
                                                                       value={inputVals.width}
                                                                       onChange={(e) => {
                                                                           setWidth(e.target.value)
                                                                           setInputVals({
                                                                               ...inputVals,
                                                                               // width: (unit ==='meter-cm') ? e.target.value : parseFloat(e.target.value) / 0.00008
                                                                               width: e.target.value
                                                                           })
                                                                       }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            {/*<label htmlFor="inputEmail3" className="col-sm-2">Breite</label>*/}
                                                            <div className="col-sm-10">
                                                                <label htmlFor="decision">{t('master.hight')+ (unit ==='meter-cm' ?  ' (mm)': ' (inch)')}</label>

                                                                <input
                                                                    // type="number"
                                                                    type="text"
                                                                    className="form-control wlb"
                                                                       id="decision"
                                                                    // name="specification.width"
                                                                    //    name="specification.thickness"
                                                                       placeholder={t('master.hight')}
                                                                       data-trans="master.hight"
                                                                       // step="0.0000001"
                                                                       hidden={spe !== "re"}
                                                                       value={inputVals.height}
                                                                       onChange={(e) => {
                                                                           setHeight(e.target.value)
                                                                           setInputVals({
                                                                               ...inputVals,
                                                                               // height: (unit ==='meter-cm') ? e.target.value : parseFloat(e.target.value) / 0.00008
                                                                               height: e.target.value
                                                                           })
                                                                       }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div hidden={!["us"].includes(spe)}>
                                                        <div className="form-group">
                                                            <label htmlFor="gType" className="col-sm-2">{t('master.dim')+ (unit ==='meter-cm' ?  ' (mm)': ' (inch)')} </label>
                                                            <div className="col-sm-10">
                                                                <select className="form-control wlb"
                                                                        id="decision"
                                                                        name="specification.uGType"
                                                                        placeholder={t('misc.typ')}
                                                                        // data-trans="misc.typ"
                                                                        value={inputVals.usv}
                                                                        data-trans="master.wab"
                                                                        hidden={!["us"].includes(spe)}
                                                                        onChange={(e) => {
                                                                            // setVal(e.target.value.split(' x ')[0]);
                                                                            setVal(e.target.value);
                                                                            setInputVals({
                                                                                ...inputVals,
                                                                                usv:e.target.value,
                                                                                wallThickness: "",
                                                                                outerDiameter: ""
                                                                            })
                                                                            setWallThickness("")
                                                                        }}
                                                                >
                                                                    {
                                                                        [t('misc.select'),"30 x 15","50","40 x 20","40","50 x 25","50","60","65","80","100","120","140","160","180","200","220","240","260","280","300","320","350","380","400"].map((item,i)=>(
                                                                            // <option key={i} value={item} selected={item==inputVals.usv}>{item}</option>
                                                                            <option key={i} value={item}>{item}</option>
                                                                        ))
                                                                    }
                                                                    {/*<option key='1' value=''>{t('master.typea')}</option>*/}
                                                                    {/*<option key='40' value='30 x 15'>30 x 15</option>
                                                                    <option key='41' value='30'>50</option>
                                                                    <option key='42' value='40 x 20'>40 x 20</option>
                                                                    <option key='43' value='40'>40</option>
                                                                    <option key='44' value='50 x 25'>50 x 25</option>
                                                                    <option key='2' value='50'>50</option>
                                                                    <option key='4' value='60'>60</option>
                                                                    <option key='6' value='65'>65</option>
                                                                    <option key='8' value='80'>80</option>
                                                                    <option key='10' value='100'>100</option>
                                                                    <option key='12' value='120'>120</option>
                                                                    <option key='14' value='140'>140</option>
                                                                    <option key='16' value='160'>160</option>
                                                                    <option key='18' value='180'>180</option>
                                                                    <option key='20' value='200'>200</option>
                                                                    <option key='22' value='220'>220</option>
                                                                    <option key='24' value='240'>240</option>
                                                                    <option key='26' value='260'>260</option>
                                                                    <option key='28' value='280'>280</option>
                                                                    <option key='30' value='300'>300</option>
                                                                    <option key='32' value='320'>320</option>
                                                                    <option key='34' value='350'>350</option>
                                                                    <option key='36' value='380'>380</option>
                                                                    <option key='38' value='400'>400</option>*/}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div hidden={!["ls"].includes(spe)}>
                                                        <div className="form-group">
                                                            <label htmlFor="gType" className="col-sm-2">{t('master.dim')+ (unit ==='meter-cm' ?  ' (mm)': ' (inch)')}</label>
                                                            <div className="col-sm-10">
                                                                <select className="form-control wlb"
                                                                        id="decision"
                                                                        name="specification.lGType"
                                                                        placeholder={t('misc.typ')}
                                                                        // data-trans="misc.typ"
                                                                        value={inputVals.lsv}
                                                                        data-trans="master.wab"
                                                                        hidden={!["ls"].includes(spe)}
                                                                        onChange={(e) => {
                                                                            // setVal(e.target.value.split(' x ')[0]);
                                                                            setVal(e.target.value);
                                                                            setInputVals({
                                                                                ...inputVals,
                                                                                lsv:e.target.value,
                                                                                wallThickness: "",
                                                                                outerDiameter: ""
                                                                            })
                                                                            setWallThickness("")
                                                                        }}
                                                                >
                                                                    {
                                                                        [t('misc.select'),"20 x 3","25 x 3","30 x 3","40 x 4","45 x 4.5","50 x 4","60 x 5","65 x 7","70 x 6","75 x 6","80 x 8","90 x 7","100 x 8","120 x 10","130 x 12","150 x 10","160 x 15","180 x 16","200 x 16","250 x 28"].map((item,i)=>(
                                                                            // <option key={i} value={item} selected={item==inputVals.lsv}>{item}</option>
                                                                            <option key={i} value={item}>{item}</option>
                                                                        ))
                                                                    }

                                                                    {/*<option key='1' value=''>{t('master.typea')}</option>*/}
                                                                    {/*<option key='2' value='20 x 3'>20 x 3</option>
                                                                    <option key='3' value='25 x 3'>25 x 3</option>
                                                                    <option key='4' value='30 x 3'>30 x 3</option>
                                                                    <option key='5' value='40 x 4'>40 x 4</option>
                                                                    <option key='6' value='45 x 4.5'>45 x 4.5</option>
                                                                    <option key='7' value='50 x 4'>50 x 4</option>
                                                                    <option key='8' value='60 x 5'>60 x 5</option>
                                                                    <option key='9' value='65 x 7'>65 x 7</option>
                                                                    <option key='10' value='70 x 6'>70 x 6</option>
                                                                    <option key='11' value='75 x 6'>75 x 6</option>
                                                                    <option key='12' value='80 x 8'>80 x 8</option>
                                                                    <option key='13' value='90 x 7'>90 x 7</option>
                                                                    <option key='14' value='100 x 8'>100 x 8</option>
                                                                    <option key='15' value='120 x 10'>120 x 10</option>
                                                                    <option key='16' value='130 x 12'>130 x 12</option>
                                                                    <option key='17' value='150 x 10'>150 x 10</option>
                                                                    <option key='18' value='160 x 15'>160 x 15</option>
                                                                    <option key='19' value='180 x 16'>180 x 16</option>
                                                                    <option key='20' value='200 x 16'>200 x 16</option>
                                                                    <option key='21' value='250 x 28'>250 x 28</option>*/}
                                                                </select>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div hidden={!["ro", "vi"].includes(spe)}>
                                                        <div className="form-group row">
                                                            <div className="col-sm-10">
                                                                {/*<label htmlFor="decision">{t('master.dia')+ (unit ==='meter-cm' ?  ' (mm)': ' (inch)')}</label>*/}
                                                                <label htmlFor="decision">{((spe == "vi")?t('misc.dimension'):t('master.dia'))+ (unit ==='meter-cm' ?  ' (mm)': ' (inch)')}</label>

                                                                <input
                                                                    type="text"
                                                                    // type="number"
                                                                    className="form-control wlb"
                                                                       id="decision"
                                                                       // step="0.0000001"
                                                                       name="specification.outerDiameter"
                                                                       // placeholder={t('master.dia')}
                                                                       // placeholder={t('misc.dimension')}
                                                                       placeholder={(spe == "vi")?t('misc.dimension'):t('master.dia')}
                                                                       data-trans="master.dia"
                                                                       value={inputVals.outerDiameter}
                                                                       hidden={!["ro", "vi"].includes(spe)}
                                                                       onChange={(e) => {
                                                                           setVal(e.target.value);
                                                                           setInputVals({
                                                                               ...inputVals,
                                                                               // outerDiameter: (unit ==='meter-cm') ? e.target.value : parseFloat(e.target.value) / 0.00008
                                                                               outerDiameter: e.target.value
                                                                           })

                                                                       }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-10">
                                                                <label htmlFor="decision">{t('master.walla')+ (unit ==='meter-cm' ?  ' (mm)': ' (inch)')}</label>
                                                                <input
                                                                    type="text"
                                                                    // type="number"
                                                                    className="form-control wlb"
                                                                       id="decision"
                                                                       // step="0.0000001"
                                                                       name="specification.wallThickness"
                                                                       placeholder={t('master.walla')}
                                                                       data-trans="master.walla"
                                                                       hidden={!["ro", "vi"].includes(spe)}
                                                                       value={inputVals.wallThickness}
                                                                       onChange={(e) => {
                                                                           setWallThickness(e.target.value);
                                                                           setInputVals({
                                                                               ...inputVals,
                                                                               // wallThickness: (unit ==='meter-cm') ? e.target.value : parseFloat(e.target.value) / 0.00008
                                                                               wallThickness: e.target.value
                                                                           })
                                                                       }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div hidden={!["ip"].includes(spe)}>
                                                        <div className="form-group">
                                                            <label htmlFor="gType" className="col-sm-2">{t('master.dim')+ (unit ==='meter-cm' ?  ' (mm)': ' (inch)')}</label>
                                                            <div className="col-sm-10">
                                                                <select className="form-control wlb"
                                                                        id="decision"
                                                                        name="specification.hGType"
                                                                        placeholder={t('misc.typ')}
                                                                        // data-trans="misc.typ"
                                                                        value={inputVals.ip}
                                                                        data-trans="master.wab"
                                                                        hidden={!["ip"].includes(spe)}
                                                                        onChange={(e) => {
                                                                            setVal(e.target.value);
                                                                            setInputVals({
                                                                                ...inputVals,
                                                                                ip: e.target.value,
                                                                                wallThickness: "",
                                                                                outerDiameter: ""
                                                                            })
                                                                            setWallThickness("")
                                                                        }}
                                                                >
                                                                    {
                                                                        [t('misc.select'),80,100,120,140,160,180,200,220,240,270,300,330,360,400,450,500,550,600].map((item,i)=>(
                                                                            <option key={i} value={item}>{item}</option>
                                                                        ))
                                                                    }
                                                                    {/*<option key='1' value=''>{t('master.typea')}</option>*/}
                                                                    {/*<option key='2' value='80'>80</option>
                                                                    <option key='3' value='100'>100</option>
                                                                    <option key='4' value='120'>120</option>
                                                                    <option key='5' value='140'>140</option>
                                                                    <option key='6' value='160'>160</option>
                                                                    <option key='7' value='180'>180</option>
                                                                    <option key='8' value='200'>200</option>
                                                                    <option key='19' value='220'>220</option>
                                                                    <option key='9' value='240'>240</option>
                                                                    <option key='10' value='270'>270</option>
                                                                    <option key='11' value='300'>300</option>
                                                                    <option key='12' value='330'>330</option>
                                                                    <option key='13' value='360'>360</option>
                                                                    <option key='14' value='400'>400</option>
                                                                    <option key='15' value='450'>450</option>
                                                                    <option key='16' value='500'>500</option>
                                                                    <option key='17' value='550'>550</option>
                                                                    <option key='18' value='600'>600</option>*/}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div hidden={ !["hea"].includes(spe)}>
                                                        <div className="form-group">
                                                            <label htmlFor="gType" className="col-sm-2">{t('master.dim')+ (unit ==='meter-cm' ?  ' (mm)': ' (inch)')}</label>
                                                            <div className="col-sm-10">
                                                                <select className="form-control wlb"
                                                                        id="decision"
                                                                        name="specification.hGType"
                                                                        placeholder={t('misc.typ')}
                                                                        value={inputVals.hea}
                                                                        // data-trans="misc.typ"
                                                                        data-trans="master.wab"
                                                                        hidden={!["hea"].includes(spe)}
                                                                        onChange={(e) => {
                                                                            setVal(e.target.value);
                                                                            setInputVals({
                                                                                ...inputVals,
                                                                                hea: e.target.value,
                                                                                wallThickness: "",
                                                                                outerDiameter: ""
                                                                            })
                                                                            setWallThickness("")
                                                                        }}
                                                                >
                                                                    {
                                                                        [t('misc.select'),100,120,140,160,180,200,220,240,260,280,300,320,340,360,400,450,500,550,600,650,700,800,900,1000].map((item,i)=>(
                                                                            <option key={i} value={item}>{item}</option>
                                                                        ))
                                                                    }
                                                                    {/*<option key='1' value=''>{t('master.typea')}</option>*/}
                                                                    {/*<option key='3' value='100'>100</option>
                                                                    <option key='4' value='120'>120</option>
                                                                    <option key='5' value='140'>140</option>
                                                                    <option key='6' value='160'>160</option>
                                                                    <option key='7' value='180'>180</option>
                                                                    <option key='8' value='200'>200</option>
                                                                    <option key='26' value='220'>220</option>
                                                                    <option key='9' value='240'>240</option>
                                                                    <option key='10' value='260'>260</option>
                                                                    <option key='11' value='280'>280</option>
                                                                    <option key='12' value='300'>300</option>
                                                                    <option key='13' value='320'>320</option>
                                                                    <option key='14' value='340'>340</option>
                                                                    <option key='15' value='360'>360</option>
                                                                    <option key='16' value='400'>400</option>
                                                                    <option key='17' value='450'>450</option>
                                                                    <option key='18' value='500'>500</option>
                                                                    <option key='19' value='550'>550</option>
                                                                    <option key='20' value='600'>600</option>
                                                                    <option key='21' value='650'>650</option>
                                                                    <option key='22' value='700'>700</option>
                                                                    <option key='23' value='800'>800</option>
                                                                    <option key='24' value='900'>900</option>
                                                                    <option key='25' value='1000'>1000</option>*/}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div hidden={!["heb"].includes(spe)}>
                                                        <div className="form-group ">
                                                            <label htmlFor="gType" className="col-sm-2">{t('master.dim')+ (unit ==='meter-cm' ?  ' (mm)': ' (inch)')}</label>
                                                            <div className="col-sm-10">
                                                                <select className="form-control wlb"
                                                                        id="decision"
                                                                        name="specification.hGType"
                                                                        placeholder={t('misc.typ')}
                                                                        value={inputVals.heb}
                                                                        // data-trans="misc.typ"
                                                                        data-trans="master.wab"
                                                                        hidden={!["heb"].includes(spe)}
                                                                        onChange={(e) => {
                                                                            setVal(e.target.value);
                                                                            setInputVals({
                                                                                ...inputVals,
                                                                                heb: e.target.value,
                                                                                wallThickness: "",
                                                                                outerDiameter: ""
                                                                            })
                                                                            setWallThickness("")
                                                                        }}
                                                                >
                                                                    {
                                                                        [t('misc.select'),100,120,140,160,180,200,220,240,260,280,300,320,340,360,400,450,500,550,600,650,700,800,900,1000].map((item,i)=>(
                                                                            <option key={i} value={item}>{item}</option>
                                                                        ))
                                                                    }
                                                                    {/*<option key='1' value=''>{t('master.typea')}</option>*/}
                                                                    {/*<option key='3' value='100'>100</option>
                                                                    <option key='4' value='120'>120</option>
                                                                    <option key='5' value='140'>140</option>
                                                                    <option key='6' value='160'>160</option>
                                                                    <option key='7' value='180'>180</option>
                                                                    <option key='8' value='200'>200</option>
                                                                    <option key='26' value='220'>220</option>
                                                                    <option key='9' value='240'>240</option>
                                                                    <option key='10' value='260'>260</option>
                                                                    <option key='11' value='280'>280</option>
                                                                    <option key='12' value='300'>300</option>
                                                                    <option key='13' value='320'>320</option>
                                                                    <option key='14' value='340'>340</option>
                                                                    <option key='15' value='360'>360</option>
                                                                    <option key='16' value='400'>400</option>
                                                                    <option key='17' value='450'>450</option>
                                                                    <option key='18' value='500'>500</option>
                                                                    <option key='19' value='550'>550</option>
                                                                    <option key='20' value='600'>600</option>
                                                                    <option key='21' value='650'>650</option>
                                                                    <option key='22' value='700'>700</option>
                                                                    <option key='23' value='800'>800</option>
                                                                    <option key='24' value='900'>900</option>
                                                                    <option key='25' value='1000'>1000</option>*/}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div hidden={!["hem"].includes(spe)}>
                                                        <div className="form-group">
                                                            <label htmlFor="gType" className="col-sm-2">{t('master.dim')+ (unit ==='meter-cm' ?  ' (mm)': ' (inch)')}</label>
                                                            <div className="col-sm-10">
                                                                <select className="form-control wlb"
                                                                        id="decision"
                                                                        name="specification.hGType"
                                                                        placeholder={t('misc.typ')}
                                                                        value={inputVals.hem}
                                                                        // data-trans="misc.typ"
                                                                        data-trans="master.wab"
                                                                        hidden={!["hem"].includes(spe)}
                                                                        onChange={(e) => {
                                                                            // setVal(e.target.value.split(' x ')[0]);
                                                                            setVal(e.target.value);
                                                                            setInputVals({
                                                                                ...inputVals,
                                                                                hem: e.target.value,
                                                                                wallThickness: "",
                                                                                outerDiameter: ""
                                                                            })
                                                                            setWallThickness("")
                                                                        }}
                                                                >
                                                                    {/*<option key='1' value=''>{t('master.typea')}</option>*/}
                                                                    {
                                                                        [t('misc.select'),100,120,140,160,180,200,220,240,260,280,300,320,340,360,400,450,500,550,600,650,700,800,900,1000].map((item,i)=>(
                                                                            <option key={i} value={item} >{item}</option>
                                                                        ))
                                                                    }
                                                                    {/*<option key='3' value='100'>100</option>
                                                                    <option key='4' value='120'>120</option>
                                                                    <option key='5' value='140'>140</option>
                                                                    <option key='6' value='160'>160</option>
                                                                    <option key='7' value='180'>180</option>
                                                                    <option key='8' value='200'>200</option>
                                                                    <option key='26' value='220'>220</option>
                                                                    <option key='9' value='240'>240</option>
                                                                    <option key='10' value='260'>260</option>
                                                                    <option key='11' value='280'>280</option>
                                                                    <option key='12' value='300'>300</option>
                                                                    <option key='13' value='320'>320</option>
                                                                    <option key='14' value='340'>340</option>
                                                                    <option key='15' value='360'>360</option>
                                                                    <option key='16' value='400'>400</option>
                                                                    <option key='17' value='450'>450</option>
                                                                    <option key='18' value='500'>500</option>
                                                                    <option key='19' value='550'>550</option>
                                                                    <option key='20' value='600'>600</option>
                                                                    <option key='21' value='650'>650</option>
                                                                    <option key='22' value='700'>700</option>
                                                                    <option key='23' value='800'>800</option>
                                                                    <option key='24' value='900'>900</option>
                                                                    <option key='25' value='1000'>1000</option>*/}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="col-sm-10">
                                                            <p>{t('misc.bund')}</p>
                                                            <label
                                                                htmlFor="decision.height">{t('master.widths')}</label>
                                                            <input type="number" className="form-control wlb"
                                                                   id="decision"
                                                                   defaultValue='1'
                                                                   name="bundlingWidth"
                                                                   min='1'
                                                                   data-trans="misc.widths"
                                                                   value={inputVals.bundlingWidth}
                                                                   onChange={(e)=>{
                                                                       setInputVals({
                                                                           ...inputVals,
                                                                           bundlingWidth: e.target.value
                                                                       })
                                                                   }}
                                                                // hidden={!["I-PE", "HE-A", "HE-B", "HE-M"].includes(holderTyp) && !["L", "U"].includes(specification)}
                                                            />
                                                            <label
                                                                htmlFor="decision.height">{t('master.heights')}</label>
                                                            <input type="number" className="form-control wlb"
                                                                   id="decision"
                                                                   defaultValue='1'
                                                                   name="bundlingHeight"
                                                                   min='1'
                                                                   data-trans="misc.heights"
                                                                   value={inputVals.bundlingHeight}
                                                                   onChange={(e)=>{
                                                                       setInputVals({
                                                                           ...inputVals,
                                                                           bundlingHeight: e.target.value
                                                                       })
                                                                   }}
                                                                // hidden={!["I-PE", "HE-A", "HE-B", "HE-M"].includes(holderTyp) && !["L", "U"].includes(specification)}
                                                            />
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                <div className="clearfix mt-2">
                                                    <a onClick={clickTwo}
                                                       className="pl-2 pr-2 mb-2 btn btn-primary float-left">{t('master.back')}</a>
                                                    <button type="submit" onClick={clickFour} disabled={loading}
                                                            className="pl-2 pr-2 mb-2 btn btn-primary float-right">
                                                        {!loading ?
                                                            <div>{t('master.result2')} </div> :
                                                            <div>
                                                                 <span className="spinner-border spinner-border-sm"
                                                                       role="status"
                                                                       aria-hidden="true"></span>
                                                                Loading...
                                                            </div>
                                                        }
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className={activeTab ==="v-pills-settings"?"tab-pane fade show active":"tab-pane fade "} id="v-pills-settings" role="tabpanel"
                                         aria-labelledby="v-pills-settings-tab">
                                        <div className="group-bg-card col-sm pb-1">
                                            {/*<h4>{t('master.result2')}</h4>*/}
                                            <h4>{t('misc.resulttab')}</h4>
                                            {/*<h4>{t('misc.edata')}:</h4>*/}
                                            {loading ?
                                                <CircularProgress
                                                    size={50}
                                                    thickness={3}
                                                    color="primary"
                                                />
                                                :
                                                <div>
                                                    {
                                                        data !== undefined ?

                                                            data.map((item) => {
                                                                console.log(item);
                                                                return <div className={classes.root} key={item.id}>
                                                                    <CardContent>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                {/*<h2>{item.category == null ? "Bi-Metall - Sägeband":item.category.name+" - Sägeband"}</h2>*/}
                                                                                <h2>{(item.category== null ?  t("misc.biMetal")+" " :item.category.id == "3" ? t("misc.hardMetal")+" " : item.category.id == "1" ?t("misc.professional") +" " +t("misc.biMetal")+" " : t("misc.highPerformance")+" "+t("misc.biMetal")+" " ) + t('misc.SawBand') }</h2>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="row">
                                                                                    <div className="col-md-5 col-sm-6">
                                                                                        <strong>{t('master.agroup')}:</strong>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-md-5 col-sm-6">{item.product.name}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="row">
                                                                                    <div className="col-md-5 col-sm-6">
                                                                                        <strong>{t('master.adesc')}:</strong>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-md-5 col-sm-6">{item.product.description}</div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="row">
                                                                                    <div className="col-md-5  col-sm-6">
                                                                                        <strong>{t('master.dim')}:</strong>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-md-5 col-sm-6">{inputs.length + ' mm x ' + inputs.height + ' mm x ' + inputs.strength + ' mm'}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="row">
                                                                                    <div className="col-md-5 col-sm-6">
                                                                                        <strong>{t('master.tooth')}:</strong>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-md-5 col-sm-6">{item.gearing.name}</div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="w-100">
                                                                                <Link
                                                                                    className="pl-2 pr-2 mb-2 mt-4 btn btn-primary float-right"
                                                                                    color="primary" to={{
                                                                                    pathname: '/result',
                                                                                    classes: 'btn btn-primary',
                                                                                    aboutProps: {
                                                                                        data:[item],
                                                                                        all: data,
                                                                                        durchmesser: (unit ==='meter-cm') ? val : Math.round(parseFloat(val.toString().replace(",",".")) / 0.039370078740157),
                                                                                        measure: inputs,
                                                                                        height:  (unit ==='meter-cm') ? height : Math.round(parseFloat(height.toString().replace(",",".")) / 0.039370078740157),
                                                                                        width:  (unit ==='meter-cm') ? width : Math.round(parseFloat(width.toString().replace(",",".")) / 0.039370078740157),
                                                                                        surface: spe,
                                                                                        materialType: materialTyp,
                                                                                        filters: filter3,
                                                                                        wallThickness:  (unit ==='meter-cm') ? wallThickness : Math.round(parseFloat(wallThickness) / 0.039370078740157),
                                                                                    }

                                                                                }}>
                                                                                    {t('master.cost')}
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </CardContent>
                                                                </div>
                                                            })

                                                        :
                                                            <div>
                                                                {msg &&
                                                                <div>
                                                                    <div className="alert alert-warning mb-3" role="alert">
                                                                        {/*Kein Ergebnis vorhanden*/}
                                                                        {t(msg)}
                                                                    </div>
                                                                    <a onClick={clickThree}  className="pl-2 pr-2 mb-2 btn btn-primary ">{t('master.back')}</a>
                                                                </div>
                                                                }
                                                            </div>
                                                    }
                                                    {data !== undefined &&
                                                        <CardContent>
                                                            <div className="row">
                                                            <div className="w-100">
                                                                    <a onClick={clickThree}
                                                                       className="pl-2 pr-2 mb-2 btn btn-primary float-left">{t('master.back')}</a>
                                                                <Link
                                                                    className="pl-2 pr-2 mb-2 btn btn-primary float-right"
                                                                    color="primary" to={{
                                                                    pathname: '/result',
                                                                    classes: 'btn btn-primary',
                                                                    aboutProps: {
                                                                        data,
                                                                        all: data,
                                                                        durchmesser:  (unit ==='meter-cm') ? val : Math.round(parseFloat(val.toString().replace(",",".")) / 0.039370078740157),
                                                                        measure: inputs ,
                                                                        height:   (unit ==='meter-cm') ? height : Math.round(parseFloat(height.toString().replace(",",".")) / 0.039370078740157),
                                                                        width:   (unit ==='meter-cm') ? width : Math.round(parseFloat(width.toString().replace(",",".")) / 0.039370078740157),
                                                                        surface: spe,
                                                                        materialType: materialTyp,
                                                                        filters: filter3,
                                                                        wallThickness:   (unit ==='meter-cm') ? wallThickness : Math.round(parseFloat(wallThickness) / 0.039370078740157)
                                                                    }

                                                                }}>
                                                                    {t('master.costs')}
                                                                </Link>
                                                            </div>
                                                            </div>
                                                        </CardContent>
                                                    }
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </form>

                    {/*:*/}
                    {/*<div>*/}
                    {/*    {msg &&*/}
                    {/*    <div className="alert alert-warning" role="alert">*/}
                    {/*        {msg}*/}
                    {/*    </div>*/}
                    {/*    }*/}
                    {/*</div>*/}
                    {/*}*/}
                </div>
    )
}