import axios from "axios";
import utils from "../components/utils";
const API_URL = process.env.REACT_APP_URL;

class AuthService {
  login(email, password) {
    return axios
        .post(`${API_URL}api/login_check`, {
              email,
              password
        })
        .then(response => {
            console.log(response);
              if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data.data));
                localStorage.setItem("token", JSON.stringify(response.data.token));
              }
              return response.data;
        });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    utils.deleteCookie('user');
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
}

export default new AuthService();
