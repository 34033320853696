import React, {Component, useEffect, useState} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {Link, useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faLock} from "@fortawesome/free-solid-svg-icons";

import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import ArntzDatabase from "../services/database";
import utils from "../components/utils";
import {useTranslation} from "react-i18next";

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

export default function Login() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [passwordText, setPasswordText] = useState('');
    const [code, setCode] = useState('');
    const [form, setform] = useState('');
    const [checkBtn, setCheckBtn] = useState('');
    const { t, i18n } = useTranslation();
    let history = useHistory();


    useEffect(() => {
        let pw = new URLSearchParams(window.location.search).get("pw");
        let user = utils.readCookie('user');
        console.log(user);
        if (pw === 1) {
            setPasswordText('Das Passwort wurde erfolgreich zurückgesetzt')
        } else if (user != null) {
            localStorage.setItem('user', user);
            history.push("/home");
            window.location.reload();
        }
    }, []);


    const onChangeUsername = (e) => {
        setUsername(e.target.value)
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleLogin = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        form.validateAll();
       if (checkBtn.context._errors.length === 0) {
            AuthService.login(username, password).then(
                (res) => {
                    console.log(res);
                    let user = localStorage.getItem('user')
                    if (!res.data.active) {
                        setLoading(false);
                        setMessage('Konto ist nicht aktiv');
                        setCode(403)

                    } else {
                        utils.createCookie('user', user, 7);

                        // UserService.getAll('bookings', {}).then((res) => {
                        //     ArntzDatabase.db.resources['bookings'].clear().then(() =>
                        //         ArntzDatabase.db.resources['bookings'].bulkAdd(res.data).then().catch()
                        //     );
                        // }).catch(() => {
                        // });
                        // UserService.getAll('stocks', {storage: res.data.storage}).then((res) => {
                        //     ArntzDatabase.db.resources['products'].clear().then(() =>
                        //         ArntzDatabase.db.resources['products'].bulkAdd(res.data).then(() => {
                        //             history.push("/home");
                        //             window.location.reload();
                        //         }).catch()
                        //     ).catch(() => {
                        //     });
                        // });
                        history.push("/home");
                        window.location.reload();
                    }

                    // history.push("/home");
                    // this.props.history.push("/home");
                    // window.location.reload();
                },
                error => {

                    console.log(error.response);
                    if (error.response &&
                        error.response.data &&
                        error.response.data.message &&
                        error.response.data.code === 401
                    ) {
                        setLoading(false);
                        setMessage(error.response.data.message);
                        setCode(error.response.data.code);
                    } else {
                        let user = utils.readCookie('user');
                        localStorage.setItem('user', user);
                        history.push("/home");
                        window.location.reload();
                    }
                }
            );
       } else {
           setLoading(false);
       }
    }

    return (
        <div className="col-md-12">
            <div className="card card-container">
                <img
                    src="assets/Logo.svg"
                    alt="profile-img"
                    className="img-fluid spacer-b-2"
                />
                {passwordText && (
                    <div className="alert alert-info" role="alert">
                        {passwordText}
                    </div>
                )}

                <Form
                    onSubmit={handleLogin}
                    ref={c => {
                        setform(c);
                    }}
                >
                    <div className="form-group">
                        <label className="sr-only" htmlFor="username">{t('login.mail')}</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faUser}/>
                    </span>
                            </div>
                            <Input
                                type="text"
                                className="form-control wicon"
                                name="username"
                                placeholder="Nutzername/E-Mail"
                                value={username}
                                onChange={onChangeUsername}
                                validations={[required]}
                            />
                        </div>


                    </div>

                    <div className="form-group">
                        <label className="sr-only" htmlFor="password">{t('login.pw')}</label>

                        <div className="input-group">
                            <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faLock}/>
                    </span>
                            </div>
                            <Input
                                type="password"
                                className="form-control "
                                name="password"
                                placeholder="Passwort"
                                value={password}
                                onChange={onChangePassword}
                                validations={[required]}
                            />
                        </div>

                    </div>

                    <button
                        className="btn btn-primary btn-block spacer-b-2"
                        disabled={loading}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Login</span>
                    </button>
                    <div className="form-group text-center"
                    >
                        <Link to='forgot_password'>{t('login.forgot')}</Link>
                    </div>

                    {message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                    <CheckButton
                        style={{display: "none"}}
                       ref={c => {
                           setCheckBtn(c);
                       }}
                    />
                </Form>
            </div>
        </div>
    );

}
