import React, {useEffect, useState} from "react";
import scanProduct from "./scanProduct";
import { createStyles, makeStyles  } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AuthService from "../services/auth.service";
import {Redirect} from "react-router-dom";
import ArntzDatabase from "../services/database";
import UserService from "../services/user.service";
import { CircularProgress } from '@material-ui/core';
import Scanner from './Scanner';
import $ from "jquery";
import Quagga from 'quagga';
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            minWidth: 275,
            display: 'flex',
            flexDirection : 'column',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    }),
);

export default function GetProduct() {
    const classes = useStyles();
    const currentUser = AuthService.getCurrentUser();
    const obj = (currentUser) ?  { currentUser: currentUser, userReady: true } : { redirect: "/login" };
    const [state] = useState(obj);
    const [data, setData] = useState()
    const [mode, setMode] = useState('online');
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState('');
    const [myFormRef, setMyFormRef] = useState('');
    const { t, i18n } = useTranslation();

    const submitForm = (event) =>{
        event.preventDefault();
        setLoading(true);
        setMsg('');
        let target = event.target;
        const ean = target.ean.value;
        const reasonChange = target.reasonChange.value;
        const debitAssignment = target.debitAssignment.value;
        const type = target.type.value;
        const menge = target.menge.value;

        (async() => {
            let filters = {'ean': ean}

            if(window.navigator.onLine) {
                setMode('online')
                UserService.getAll('products', filters).then((res) => {
                    setLoading(false);
                    myFormRef.reset();
                    if(res.data.length <= 0) {
                        setMsg('Keine Resultate gefunden');
                        setData();
                    }else{
                        console.log(res.data[0]);
                        if(res.data[0].stock > 0){
                            setData(res.data[0]);
                            let obj = {
                                'customer': `/api/customers/${res.data[0].customer}`,
                                'product': `/api/products/${res.data[0].product}`,
                                // 'amount': res.data[0].amount + 1,
                                'amount':  parseInt(menge),
                                'reasonChange': reasonChange,
                                'debitAssignment':debitAssignment,
                                'type':type,
                            };
                            // if (res.data[0].bookings.length > 0) {
                            //     UserService.put('bookings', res.data[0].bookingId, obj).then((res) => {
                            //         let id = res.data.id;
                            //         ArntzDatabase.db.resources['bookings'].put(res.data, id);
                            //     }).catch((err) => {})
                            // } else {
                                UserService.post('bookings', obj).then((res) => {
                                    ArntzDatabase.db.resources['bookings'].post(obj);
                                }).catch((err) => {})
                            // }
                        }
                        else{
                            setData();
                            setMsg('kein bestand mehr');
                        }
                    }
                }).catch((err) => {
                    console.log(err);
                    getOffline(ean);
                });
            }else{
                getOffline(ean);
            }
        })();
    };
    const getOffline = (ean)=>{
        (async() => {
            setData();
            setMode('offline');
            let prod = await ArntzDatabase.db.resources['products'].filter((prod)=>prod.ean === ean).toArray();
            if(prod.length <= 0)
                setMsg('Keine Resultate gefunden');
            else{
                if(prod[0].stock > 0){
                    setData(prod[0]);
                    let obj = await ArntzDatabase.db.resources['bookings'].filter((bb)=>bb.ean === prod[0].ean).toArray();
                    if(obj[0] !== undefined){
                        obj[0]['status'] = (obj[0]['status']) ? obj[0]['status']:'UPDATE';
                        obj[0]['addedAmount'] = (obj[0]['addedAmount'])?obj[0]['addedAmount']+1 : 1;
                        obj[0]['amount'] += 1;

                        ArntzDatabase.db.resources['bookings'].put(obj[0],{bookingId:prod[0].id}).then().catch();
                    }else{
                        prod[0]['status'] = 'ADD';
                        prod[0]['addedAmount'] =  1;
                        prod[0]['customer'] =  `/api/customers/${currentUser.id}`;
                        prod[0]['amount'] = 1;
                        delete prod[0]['id'];
                        ArntzDatabase.db.resources['bookings'].add(prod[0]).then().catch();;
                    }
                }else{
                    setMsg('kein bestand mehr');
                }
            }
            setLoading(false);
        })();
    }


    useEffect(() => {

        (async() => {
            if (window.navigator.onLine) {
                await ArntzDatabase.db.sync('bookings');
                ArntzDatabase.isSync = true;
                // await UserService.getAll('bookings', {}).then((res) => {
                //     ArntzDatabase.db.resources['bookings'].clear().then(() =>
                //         ArntzDatabase.db.resources['bookings'].bulkAdd(res.data).then().catch()
                //     );
                // }).catch((e)=> {
                //     setMode('offline');
                //     ArntzDatabase.isSync =false;
                // });
                await UserService.getAll('stocks', {storage: currentUser.storage}).then((res) => {
                    ArntzDatabase.db.resources['products'].clear().then(() => ArntzDatabase.db.resources['products'].bulkAdd(res.data).then().catch()).catch();
                }).catch((e)=> {
                    setMode('offline');
                    ArntzDatabase.isSync = false;
                });
            } else {
                setMode('offline');
                ArntzDatabase.isSync =false;
            }
        })();
    });
    return(
        (state.redirect) ?
            <Redirect to={state.redirect}/>
            :
            <div className="mw-320 m-auto">

                <h2 className="text-center">{t('product.get.register')}</h2>
                {mode === 'offline' &&
                <div className="alert alert-warning" role="alert">
                    {mode}
                </div>
                }
                <Scanner/>
                <form onSubmit={submitForm} id='categoryForm' style={{'marginBottom':'20px'}} ref={(el) => setMyFormRef(el)}>
                    <div className="form-group">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="type" value="Abbuchung" defaultChecked />
                            <label className="form-check-label" >{t('product.get.sub')}</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="type" value="Zubuchung" />
                            <label className="form-check-label" >{t('product.get.add')}</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="menge">{t('product.get.amount')}</label>
                        <input type="text"
                               className="form-control wlb"
                               name='menge'
                               id="menge"
                               aria-describedby="name"
                               placeholder={t('product.get.amount')} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="ean">{t('product.get.ean')}</label>
                        <input type="text"
                               className="form-control wlb"
                               name='ean'
                               id="ean"
                               aria-describedby="name"
                            // onChange={eanOnchange}
                               placeholder={t('stock.scanLabel')} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="ean">{t('product.get.reason')}</label>
                        <select name="reasonChange" id="reasonChange" className="form-control wlb">
                            <option key={1} value=''>{t('product.get.select')}</option>
                            <option key={3} value={'Verschleiß'}>{t('product.get.waste')}</option>
                            <option key={4} value={'Schiefschnitt'}>{t('product.get.error')}</option>
                            <option key={5} value={'Bandbruch'}>{t('product.get.break')}</option>
                            <option key={6} value={'Safety Bandwechsel'}>{t('product.get.safety')}</option>
                            <option key={7} value={'sonstiges'}>{t('product.get.other')}</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="ean">{t('product.get.to')}</label>
                        <input type="text"
                               className="form-control wlb"
                               name='debitAssignment'
                               id="debitAssignment"
                               aria-describedby="debitAssignment"
                               placeholder={t('stock.note')} />
                    </div>
                    <button type="submit" className="btn btn-primary btn-block" id="sub">{t('product.get.send')}</button>
                </form>
                {loading ?
                    <CircularProgress
                        size={50}
                        thickness={3}
                        color="primary"
                    />
                    :
                    <div>
                        {msg &&
                        <div className="alert alert-warning" role="alert">
                            {msg}
                        </div>
                        }
                        {data &&
                        <Card className={classes.root}>
                            <CardContent>

                                <div>
                                    <p>
                                        <strong>Ean:</strong>{" "}
                                        {data.ean}
                                    </p>
                                    <p>
                                        <strong>{t('product.get.number')}:</strong>{" "}
                                        {data.name}
                                    </p>
                                    <p>
                                        <strong>{t('product.get.description')}:</strong>{" "}
                                        {data.description}
                                    </p>
                                </div>

                            </CardContent>
                        </Card>
                        }
                    </div>
                }
            </div>
    )
}