import React, {useState} from "react";
import UserService from "../services/user.service";
import {useTranslation} from "react-i18next";

export default function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();

    const handleInputChange = (event) => {
        setEmail(event.target.value);
    }
    const handleForgotPassword = (event) => {
        event.preventDefault();
        setLoading(true);
        UserService.forgotPassword(email).then((res) => {
            console.log(res);
            setData(res.data);
            setLoading(false);
        }).catch((err) => {
            setMessage(err);
            setLoading(false);
        });
    };

    return (
        <div className="col-md-12">
            <div className="card card-container">
                <img
                    src="assets/Logo.svg"
                    alt="profile-img"
                    className="img-fluid spacer-b-2"
                />
                {data.status === 'check_email' && (
                    <div className="alert alert-info" role="alert">
                        <p>{t('forgot.send.message')}</p>
                        <p>{t('forgot.send.link')} {data.tokenLifetime / 3600} {t('forgot.send.linkP2')}</p>
                    </div>
                )}
                {data.status === 'not_found_email' && (
                    <div className="alert alert-danger" role="alert">
                        {t('forgot.notfound')}
                    </div>
                )}

                <form onSubmit={handleForgotPassword} id='userForm' name='userForm'>
                    <div className="form-group">
                        <label htmlFor="email">{t('forgot.user')}</label>
                        <input type="text"
                               className="form-control wlb"
                               name='email'
                               id="email"
                               aria-describedby="email"
                               placeholder="E-Mail"
                               value={email}
                               onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <button
                            className="btn btn-primary btn-block"
                            disabled={loading}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>{t('forgot.button')}</span>
                        </button>
                    </div>
                    {message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
}
