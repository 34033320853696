import React, {useEffect, useState} from "react";
import AuthService from "../services/auth.service";
import {Redirect} from "react-router-dom";
import UserService from "../services/user.service";
import { CircularProgress } from '@material-ui/core';


export default function SendOrder() {
    const currentUser = AuthService.getCurrentUser();
    const obj = (currentUser) ?  { currentUser: currentUser, userReady: true } : { redirect: "/login" };
    const [state] = useState(obj);
    const [data, setData] = useState()
    const [mode, setMode] = useState('online');
    const [loading, setLoading] = useState(false);
    const [loadingSelect, setLoadingSelect] = useState(false);
    const [msg, setMsg] = useState({alertClass:null,text:null});
    const [product, setProduct] = useState('');
    const [text, setText] = useState('');
    const [user, setUser] = useState('');

    const submitForm = (event) => {
        event.preventDefault();
        setLoading(true);
        let obj = {
            product: `/api/products/${product}`,
            message: text,
            user: `/api/users/${user}`,
            subject: 'Neue Bestellung'
        };
        console.log(obj);
        if(window.navigator.onLine) {
            UserService.post('notifications', obj).then((res) => {
                setMsg({alertClass:'alert alert-success',text:'Nachricht wurde erfolgreich geschickt'});
                setLoading(false);
            }).catch((err) => {
                setMsg({alertClass:'alert alert-danger',text:'etwas schiefgelaufen'});
                setLoading(false);
            })
        }else{
            setMode('offline');
            setMsg({alertClass:'alert alert-warning',text:'Offline'});
            setLoading(false);
        }
    }

    useEffect(() => {
        (async() => {
            setLoadingSelect(true);
            if(window.navigator.onLine) {
                UserService.getAll('products', {}).then((res) => {
                    setData(res.data);
                    setLoadingSelect(false);
                }).catch((err) => {
                    setLoadingSelect(false);
                    console.log(err);
                    setMode('offline');
                    setMsg({alertClass:'alert alert-warning',text:'Offline'});
                });
            }else{
                setLoadingSelect(false);
                setMode('offline');
                setMsg({alertClass:'alert alert-warning',text:'Offline'});
            }
        })();
    }, [])

    const onChangeProduct = (e) => {
        setUser(e.target.options[e.target.selectedIndex].dataset.user);
        setProduct(e.target.value);
    }
    const onChangeText = (e) => {
        setText(e.target.value);
    }
    return(
        (state.redirect) ?
            <Redirect to={state.redirect}/>
        :
            <div className="container mw-320">
                <h2 className="text-center">Bestellung</h2>
                {loading ?
                    <CircularProgress
                        size={50}
                        thickness={3}
                        color="primary"
                    />
                    :
                    <div>
                        {msg.alertClass &&
                        <div className={msg.alertClass} role="alert">
                            {msg.text}
                        </div>
                        }
                    </div>
                }
                <form onSubmit={submitForm}  style={{'marginBottom':'20px'}}>
                    {loadingSelect ?
                            <CircularProgress
                                size={50}
                                thickness={3}
                                color="primary"
                            />
                        :
                        <div className="form-group">
                            <label htmlFor="productSelect">Produkt</label>
                            <select className="form-control wlb" id="productSelect" onChange={onChangeProduct}>
                                <option >Auswählen...</option>
                                {data && data.map((item) => (
                                    <option key={item.id} data-user={item.storageUser} value={item.id}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                    }
                    <div className="form-group">
                        <label htmlFor="messageTextarea">Nachricht</label>
                        <textarea className="form-control wlb" id="messageTextarea" rows="3" value={text} onChange={onChangeText}></textarea>
                    </div>
                    <button type="submit" disabled={mode === 'offline' || loadingSelect} className="btn btn-primary btn-block">Submit</button>
                </form>

            </div>
    )
}