import React, {useEffect, useState} from "react";
import {Redirect, useHistory} from "react-router-dom";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import {CircularProgress} from "@material-ui/core";
import arrowBack from "../assets/images/arrow.svg";
import {useTranslation} from "react-i18next";

export default function Profile() {
    const currentUser = AuthService.getCurrentUser();
    const obj = (currentUser) ? {currentUser: currentUser, userReady: true} : {redirect: "/login"};
    const [state, setState] = useState(obj);
    const [mode, setMode] = useState('online');
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();

    let history = useHistory();


    const submitForm = (event) => {
        event.preventDefault();
        let data = {
            'firstName': state.currentUser.firstName,
            'lastName': state.currentUser.lastName,
        };
        if (event.currentTarget.password.value !== '') {
            data['plainPassword'] = event.currentTarget.password.value;
        }

        (async () => {
            setLoading(true);
            currentUser['firstName'] = state.currentUser.firstName;
            currentUser['lastName'] = state.currentUser.lastName;
            currentUser['name'] = currentUser['firstName'] + ' ' + currentUser['lastName'];
            if (window.navigator.onLine) {
                setMode('online');
                UserService.put('users', currentUser.id, data).then((res) => {
                    localStorage.setItem('user', JSON.stringify(state.currentUser));
                    setLoading(false);
                }).catch((err) => {
                    localStorage.setItem('user', JSON.stringify(state.currentUser));
                    setLoading(false);
                });
            } else {
                setMode('offline');
                localStorage.setItem('user', JSON.stringify(state.currentUser));
                setLoading(false);
            }
        })();
    };
    useEffect(() => {
        if (window.navigator.onLine) {
            setMode('online');
        } else
            setMode('offline');
    }, []);

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let currentUser = state.currentUser;
        currentUser[name] = value;
        setState({
            currentUser: currentUser, userReady: true
        });
    }

    return (
        (state.redirect) ?
            <Redirect to={state.redirect}/>
            :
            <div>

                {loading ?
                    <CircularProgress
                        size={50}
                        thickness={3}
                        color="primary"
                    />
                    :
                    <div className="container mw-320">
                        {mode === 'offline' &&
                        <div className="alert alert-warning" role="alert">
                            {mode}
                        </div>
                        }
                        <h2 className="text-center">{state.currentUser.firstName}{' '}{state.currentUser.lastName}</h2>

                        <form onSubmit={submitForm} id='userForm' name='userForm'>
                            <div className="form-group">
                                <label htmlFor="firstName"> {t('misc.firstname')}</label>
                                <input type="text" className="form-control wlb" name='firstName' id="firstName"
                                       aria-describedby="name" placeholder={t('misc.firstname')}
                                       value={state.currentUser.firstName}
                                       onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastName">{t('misc.lastname')}</label>
                                <input type="text" className="form-control wlb" name='lastName' id="lastName"
                                       aria-describedby="name" placeholder={t('misc.lastname')}
                                       value={state.currentUser.lastName}
                                       onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">{t('login.pw')}</label>
                                <input type="password" className="form-control wlb" name='password' id="password"
                                       aria-describedby="password" placeholder={t('login.pw')}
                                />
                            </div>
                            <button type="submit" disabled={mode === 'offline'}
                                    className="btn btn-primary btn-block">{t('misc.save')}
                            </button>
                        </form>
                    </div>
                }
            </div>
    );
}
