import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import './i18n';

import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import App from "./App";

// import * as serviceWorker from "./serviceWorker";
import swDev from './swDev'

ReactDOM.render(
    <Suspense fallback="loading">
            <BrowserRouter>
                <App/>
            </BrowserRouter>
    </Suspense>
    ,
    document.getElementById("root")
);
swDev();
// serviceWorker.register();
