import axios from 'axios';
import authHeader from './auth-header';
const API_URL = `${process.env.REACT_APP_URL}api/`;


class UserService {
  forgotPassword(email){
    return axios.get(`${process.env.REACT_APP_URL}reset-password?email=${email}`);
  }
  resetPassword(token, data){
    return axios.post(`${process.env.REACT_APP_URL}reset-password/reset/${token}`,data);
  }

  get(resource,id){
    return axios.get(`${API_URL}${resource}/${id}.json`, { headers: authHeader() });
  }

  getAll(resource, filters){
    let url = `${API_URL}${resource}.json`;
    if(filters  && filters  !== 'null' && filters  !== 'undefined') {
      url += '?'+Object.entries(filters).map(([key, val]) => `${key}=${val}`).join('&');
    }
    if(resource == "saws")
      url += "&order%5Bmanufacturer%5D=ASC";
    if(resource == "models")
      url += "&order%5Bname%5D=ASC";
    if(resource == "materials")
      url += "&order%5BName%5D=ASC";
    return axios.get(url, { headers: authHeader() });
  }

  getAll2(resource, filters){
    let url = `${API_URL}${resource}`;
    if(filters  && filters  !== 'null' && filters  !== 'undefined') {
      url += '?'+Object.entries(filters).map(([key, val]) => `${key}=${val}`).join('&');
    }
    return axios.get(url, { headers: authHeader() });
  }

  post(resource, data){
      return axios.post(`${API_URL}${resource}.json`, data,{ headers: authHeader() });
  }

  put(resource,id,data){
    return axios.put(`${API_URL}${resource}/${id}.json`, data,{ headers: authHeader() });
  }

  delete(resource,id){
    return axios.delete(`${API_URL}${resource}/${id}.json`,{ headers: authHeader() });
  }
}

export default new UserService();
