import React, { useState} from "react";
import { createStyles, makeStyles  } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AuthService from "../services/auth.service";
import {Redirect} from "react-router-dom";
import {useTranslation} from "react-i18next";
// import ArntzDatabase from "../services/database";
// import UserService from "../services/user.service";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            minWidth: 275,
            display: 'flex',
            flexDirection : 'column',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    }),
);
export default function LastSearch() {
    const classes = useStyles();
    const currentUser = AuthService.getCurrentUser();
    const obj = (currentUser) ?  { currentUser: currentUser, userReady: true} : { redirect: "/login" };
    const [state] = useState(obj);
    let last_search = localStorage.getItem('last_search');
    last_search = (last_search!== null) ? JSON.parse(last_search):'';
    const [lastSearch] = useState(last_search);
    const [msg] = useState(last_search !== '' && lastSearch.data.length > 0 ? '':'keine Ergebnisse');
    const { t, i18n } = useTranslation();

    const masterObj = i18n.t('master', { returnObjects: true});
    console.log(masterObj);;

    const getKeyByValue =(object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    }
    return(
        (state.redirect) ?
            <Redirect to={state.redirect}/>
            :
            <div>
                {lastSearch &&
                    <div>
                        <div>
                            <h4>{t('misc.search')}</h4>
                            {lastSearch.filters && lastSearch.filters.map((item) => (
                                <div className="row mb-2">
                                    {/*{t(`master.${getKeyByValue(masterObj,item.key)}`)} : {' '} {item.value}*/}
                                    {/*{t(`${item.transKey}`) + ((t(`${item.transKey}`).includes(':'))?'':':')}{' '} {item.value}*/}
                                    {/*{item.key} : {' '} {item.value}*/}
                                    <div className="col-6 col-md-3">{t(`${item.transKey}`) + ((t(`${item.transKey}`).includes(':'))?'':':')}{' '}</div>
                                    <div className="col-6 col-md-3">{item.value}</div>
                                </div>
                            ))}
                        </div>
                        <div>
                            <h4>{t('misc.resultSingle')}</h4>
                            {lastSearch.data &&
                                lastSearch.data.map((item) => (
                                <Card className={classes.root} key={item.product.id}>
                                    <CardContent>
                                        <div>
                                            <p>
                                                <strong>{t('master.agroup')}:</strong>{" "}
                                                {item.product.name}
                                            </p>
                                            <p>
                                                <strong>{t('master.adesc')}:</strong>{" "}
                                                {item.product.description}
                                            </p>
                                            {lastSearch.filters[3].key === "Sägeband-Länge (mm)" &&
                                                <p>
                                                    <strong>{t('misc.mes')}:</strong>{" "}
                                                    {lastSearch.filters[3].value+'mm x '+lastSearch.filters[4].value+'mm x '+lastSearch.filters[5].value+'mm'}
                                                </p>
                                            }
                                            <p>
                                                <strong>{t('misc.tooth')}:</strong>{" "}
                                                {item.gearing.name}
                                            </p>
                                            {item.category &&
                                            <p>
                                                <strong>{t('master.cat')}:</strong>{" "}
                                                {item.category.name}
                                            </p>
                                            }
                                            {item.materialGroup && item.materialGroup.name !== '' &&
                                            <p>

                                                <strong> {t('master.mgroup')}:</strong>{" "}
                                                {item.materialGroup.description}
                                            </p>
                                            }
                                        </div>

                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                }
                {msg &&
                <div className="alert alert-warning" role="alert">
                    {msg}
                </div>
                }
            </div>
    )
}